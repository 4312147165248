import React, { useState } from "react"
import { Pagination } from "@mui/material"
import NFTSelectorCard from "@/components/common/NFTSelectorCard"
import { useTranslation } from "react-i18next"
import { useAsync } from "react-use"
import { AppContext } from "@/AppContext"
import { LoadingWrapper } from "@/components/common/loading/LoadingWrapper"
import { NFTItemDto } from "@/core/api"
import { useParams } from "react-router-dom"
import { ContractTypeEnum } from "@/core/enum/ContractTypeEnum"
import NoDataComponent from "@/components/common/NoDataComponent"

type Props = {
  next: () => void
  setSelectedNFT: React.Dispatch<React.SetStateAction<NFTItemDto | undefined>>
}

export default function PinSelect({ next, setSelectedNFT }: Props) {
  const pageSize = 8
  const { t } = useTranslation()
  const { id } = useParams()
  const [page, setPage] = useState(1)
  const [currentSelectedNFT, setCurrentSelectedNFT] = useState<NFTItemDto>()

  const rawData = useAsync(async () => {
    const res = await AppContext.apiExecutor.getCollections(
      "all",
      1,
      1,
      undefined,
      ContractTypeEnum.NORMAL,
      id
    )
    return res.data.result?.item_list?.[0]
  }, [])

  const rawNFTData = useAsync(async () => {
    if (
      id &&
      AppContext.getAuthAddress() &&
      rawData.value &&
      rawData.value.contract_address
    ) {
      const nftRes = await AppContext.apiExecutor.getNFTs(
        "unpin",
        page,
        pageSize,
        rawData.value.contract_address
      )
      return nftRes.data
    } else {
      return {
        code: "",
        result: {
          item_list: [],
          total_page: 0,
          total_item_size: 0,
          page: 0,
        },
      }
    }
  }, [id, AppContext.getAuthAddress(), page, rawData.value])

  const onSubmit = async () => {
    if (currentSelectedNFT) {
      setSelectedNFT(currentSelectedNFT)
      next()
    }
  }

  return (
    <LoadingWrapper
      state={rawNFTData}
      render={data =>
        data.result.item_list && Number(data.result.item_list.length) > 0 ? (
          <>
            <div className="mb-8 mt-6 grid grid-cols-2 gap-4 sm:grid-cols-2 md:mb-20 md:mt-10 lg:grid-cols-4">
              {data.result.item_list.map(pinData => (
                <NFTSelectorCard
                  selectedNFT={currentSelectedNFT}
                  key={pinData.nft.address + pinData.nft.tokenID}
                  pinData={pinData}
                  onClick={() => setCurrentSelectedNFT(pinData.nft)}
                />
              ))}
            </div>
            <div className="mb-6 mt-8 flex justify-center md:mb-14 md:mt-20">
              <Pagination
                page={page}
                count={rawNFTData.value?.result.total_page}
                variant="outlined"
                shape="rounded"
                onChange={(_, page) => setPage(page)}
              />
            </div>
            <div className="flex justify-center">
              <div
                onClick={onSubmit}
                className={`flex w-[376px] items-center justify-center rounded-lg py-3 text-[20px] ${
                  currentSelectedNFT
                    ? "cursor-pointer bg-black text-white"
                    : "cursor-no-drop bg-gray-200 text-gray-400"
                }`}
              >
                {t("next")}
              </div>
            </div>
          </>
        ) : (
          <NoDataComponent />
        )
      }
    />
  )
}
