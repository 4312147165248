import React, { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { useAccount, useConnect, useDisconnect, useNetwork } from "wagmi"
import AlertContainer from "@/core/store/AlertContainer"
import { useAsync } from "react-use"
import { AppContext } from "@/AppContext"
import { ethers, providers } from "ethers"
import { useTranslation } from "react-i18next"
import { LayoutWrapper } from "@/components/layout/LayoutWrapper"
import { ConnectWalletPopup } from "@/components/common/blockchain/ConnectWalletPopup"
import { SwitchChainPopup } from "@/components/common/blockchain/SwitchChainPopup"
import { useDeviceDetect } from "@/core/hook/useDeviceDetect"
import { goToDiverWalletApp } from "@/core/utils/goToDiverWalletApp"

export const MainLayout: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const { address } = useAccount()
  const { disconnect } = useDisconnect()
  const { connectors, connect } = useConnect()
  const { chain } = useNetwork()
  const { isMobile, isDiverApp } = useDeviceDetect()
  const { pushAlert, openDialog, closeDialog } = AlertContainer.useContainer()
  const allowedChainId = Number(import.meta.env.VITE_CHAIN_ID)
  const location = useLocation()

  useEffect(() => {
    if (isDiverApp) {
      const connector = connectors.find(item => item.name === "DiverWallet")
      connector && connect({ connector })
    } else {
      if (isMobile) {
        goToDiverWalletApp()
      }
    }
  }, [])

  useEffect(() => {
    if (!isDiverApp && !isMobile) {
      if (!address) {
        openDialog({
          children: <ConnectWalletPopup handleClose={closeDialog} />,
          close: () => null,
          sx: {
            "& .MuiPaper-root": {
              backgroundColor: "transparent",
              boxShadow: "none",
              margin: "0",
            },
            "& .MuiBackdrop-root": { backgroundColor: "rgba(0,0,0,.7)" },
          },
        })
      } else if (chain && chain.id !== allowedChainId) {
        openDialog({
          children: (
            <SwitchChainPopup handleClose={closeDialog} chain={chain} />
          ),
          close: () => null,
        })
      } else {
        closeDialog()
      }
    }
  }, [address, chain?.id])

  useAsync(async () => {
    if (!AppContext.getJwtToken() && address) {
      try {
        const res = await AppContext.apiExecutor.getNonce(address)
        const web3provider = new ethers.providers.Web3Provider(
          window.ethereum as providers.ExternalProvider,
          "any"
        )
        const sign = await web3provider
          .getSigner()
          .signMessage(
            `Welcome! Sign this message to login to the site. This doesn't cost you anything and is free of any gas fees. Nonce:${res.data.result?.nonce}`
          )
        const loginRes = await AppContext.apiExecutor.login({ address, sign })
        AppContext.setJwtToken(loginRes.data.result?.jwt as string, address)
      } catch (err: any) {
        console.error(err)
        pushAlert({
          severity: "error",
          message: t("failed_login"),
        })
        if (err.code == 4001 || err.code == -32002) {
          disconnect()
        }
      }
    }
  }, [AppContext.getJwtToken(), address])

  useEffect(() => {
    const walletDisconnect = () => {
      disconnect()
      AppContext.clearJwtToken()
    }

    if (!window.ethereum) return

    window.ethereum.on("accountsChanged", walletDisconnect)
    window.ethereum.on("chainChanged", walletDisconnect)

    return () => {
      window.ethereum.removeListener("accountsChanged", walletDisconnect)
      window.ethereum.removeListener("chainChanged", walletDisconnect)
    }
  }, [])

  useEffect(() => {
    if (AppContext.getJwtToken() && AppContext.getAuthAddress() !== address) {
      AppContext.clearJwtToken()
    }
  }, [address])

  useEffect(() => scrollTo(0, 0), [location.pathname])

  return (
    <LayoutWrapper>
      {chain?.id === allowedChainId && address && <Outlet />}
    </LayoutWrapper>
  )
}
