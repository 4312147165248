import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { PinNFTDto } from "@/core/api"
import { AppContext } from "@/AppContext"
import { UnPinNFTSuccessPopup } from "@/components/account/widget/UnPinNFTSuccessPopup"
import { QrCodePopup } from "@/components/account/widget/QrCodePopup"
import AlertContainer from "@/core/store/AlertContainer"
import { ExamplePopup } from "@/pages/nftDetail/ExamplePopup"

const useDetailActions = () => {
  const [loading, setLoading] = useState(false)
  const { openDialog, closeDialog } = AlertContainer.useContainer()
  const navigate = useNavigate()

  const unPinNFT = async (pinID: string) => {
    setLoading(true)
    try {
      await AppContext.apiExecutor.unPinNFT(pinID)
      showUnPinPopup()
      setLoading(false)
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  const closeUnPinPopup = () => {
    closeDialog()
    navigate("/account")
  }

  const showUnPinPopup = () => {
    openDialog({
      children: <UnPinNFTSuccessPopup close={closeUnPinPopup} />,
      close: closeDialog,
    })
  }

  const showQrCode = (
    pin: PinNFTDto,
    event?: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event && event.stopPropagation()
    openDialog({
      children: <QrCodePopup pin={pin} close={closeDialog} />,
      close: closeDialog,
    })
  }

  const showExamplePopup = (type: "button" | "qrcode") => {
    openDialog({
      children: <ExamplePopup type={type} close={closeDialog} />,
      close: closeDialog,
    })
  }

  return { loading, unPinNFT, showQrCode, showExamplePopup }
}

export default useDetailActions
