import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { pinSequentialStepMenus } from "@/core/constant/menu"
import { PinNFTReviewRequest } from "@/core/types/Request"
import { NFTItemDto } from "@/core/api"
import PinPreview from "./PinPreview"
import PinForm from "./PinForm"
import PinReview from "./PinReview"
import PinSuccess from "./PinSuccess"
import PinFailed from "./PinFailed"
import CommonProgressBar from "@/components/common/CommonProgressBar"

export default function SequentialPin() {
  const [formState, setFormState] = useState<PinNFTReviewRequest | undefined>(
    undefined
  )
  const [selectedNFT, setSelectedNFT] = useState<NFTItemDto | undefined>(
    undefined
  )
  const [success, setSuccess] = useState(false)
  const [step, setStep] = useState(0)
  const { t } = useTranslation()
  const { id } = useParams()

  const nextStep = (step: number) => {
    setStep(step)
    scrollTo(0, 0)
  }

  const renderTitle = () => {
    switch (step) {
      case 0:
        return (
          <>
            <div className="text-center text-[48px] font-bold">
              {t("confirm_nft")}
            </div>
          </>
        )
      case 1:
        return (
          <>
            <div className="text-center text-[48px] font-bold">
              {t("sign_up_site_by_qr")}
            </div>
            <div className="mt-5 text-center text-[18px] text-red-500">
              <span className="form_required_prefix">
                {t("required_field")}
              </span>
            </div>
          </>
        )
      case 2:
        return (
          <div className="text-center text-[48px] font-bold">
            {t("confirm_information")}
          </div>
        )
      case 3:
        return success ? (
          <div className="text-center text-[48px] font-bold">
            {t("thank_you")}
          </div>
        ) : (
          <div className="text-center text-[48px] font-bold">
            {t("creation_failed")}
          </div>
        )
    }
  }

  const renderContent = () => {
    switch (step) {
      case 0:
        return (
          <PinPreview
            next={() => nextStep(1)}
            setSelectedNFT={setSelectedNFT}
          />
        )
      case 1:
        return (
          <PinForm
            next={() => nextStep(2)}
            formState={formState}
            selectedNFT={selectedNFT}
            setFormState={setFormState}
          />
        )
      case 2:
        return (
          <PinReview
            back={() => nextStep(1)}
            next={() => nextStep(3)}
            formState={formState}
            setSuccess={setSuccess}
          />
        )
      case 3:
        return success ? (
          <PinSuccess selectedNFT={selectedNFT} collectionID={id} />
        ) : (
          <PinFailed back={() => nextStep(0)} />
        )
    }
  }

  return (
    <div className="mx-auto w-11/12 py-5 md:py-14 lg:w-4/5 xl:w-[1200px]">
      <div className="mt-6">{renderTitle()}</div>
      <CommonProgressBar step={step} menus={pinSequentialStepMenus} />
      <div className="mt-4">{renderContent()}</div>
    </div>
  )
}
