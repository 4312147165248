import React from "react"
import { useTranslation } from "react-i18next"
import CancelIcon from "@mui/icons-material/Cancel"

type Props = {
  back: () => void
}
export default function PinFailed({ back }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <div className="pt-10 text-center">
        <CancelIcon style={{ color: "#EA4335" }} sx={{ fontSize: 240 }} />
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="mb-8 mt-4 text-2xl leading-6">
          {t("creation_failed_text")}
        </div>
        <div
          onClick={back}
          className="mb-16 flex w-[376px] cursor-pointer items-center justify-center rounded-[6px] bg-black py-3 text-[20px] text-white"
        >
          {t("back")}
        </div>
      </div>
    </>
  )
}
