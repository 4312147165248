import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { LandingBanner } from "@/components/common/LandingBanner"
import { LayoutWrapper } from "@/components/layout/LayoutWrapper"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import AlertContainer from "@/core/store/AlertContainer"

export default function Home() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { closeDialog } = AlertContainer.useContainer()

  useEffect(() => closeDialog(), [])

  return (
    <LayoutWrapper>
      <div className="flex flex-col items-center pb-[120px]">
        <LandingBanner />
        <div className="mt-10 flex w-full md:mt-[120px]">
          <div className="mx-auto max-w-[800px]">
            <video
              className="mb-6"
              height="100%"
              width="auto"
              poster="/images/poster_how-to-use.jpg"
              controls
              playsInline
            >
              <source src="/video/how-to-use.mp4" type="video/mp4" />
            </video>
            <div className="rounded-lg border-2 border-[#1853a1] bg-[#1853a1]/10 px-4 py-6 max-md:mx-5">
              【{t("str_attention")}】<br />
              <p className="text-red-600">{t("str_pin_notes")}</p>
            </div>
          </div>
        </div>
        <div className="mt-10 flex w-full md:mt-[120px]">
          <div className="flex flex-1 justify-center md:justify-end">
            <div className="mx-5 w-full font-medium md:mx-0 md:w-[400px]  md:pr-8">
              <div className="text-[32px] font-semibold">
                {t("what_is_pin")}
              </div>
              <img
                className="my-2 w-full md:my-8 md:w-[300px]"
                src="/images/chainpin-what.png"
                alt="chainpin-what"
              />
              <div className="text-[12px]">{t("pins_description")}</div>
              <div className="text-[12px]">{t("pins_usage")}</div>
              <div className="text-[12px]">{t("pins_usage_location")}</div>
              <div
                className="mx-auto mt-8 w-[180px] cursor-pointer rounded-lg bg-black py-4 text-center text-white transition-colors hover:bg-[#000000d9] md:mx-0"
                onClick={() => navigate("/pin-generate")}
              >
                {t("pin_generate")}
              </div>
            </div>
          </div>
          <Box
            className="hidden flex-1 md:inline"
            sx={{
              backgroundImage: "url('/images/branding-img-2.png')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% auto",
              backgroundPosition: "center",
            }}
          />
        </div>
        <img
          className="mt-10 w-screen md:mt-[120px] md:w-[800px]"
          src="/images/branding-img-3.png"
          alt="chainpin-process"
        />
        <div className="mt-16 font-bold">{t("use_sample")}</div>
        <div
          className="my-8 w-3/4 items-stretch md:flex md:w-[800px]"
          style={{
            border: "1px solid black",
          }}
        >
          <div className="flex flex-1 flex-col items-center px-4 py-4">
            <div
              className="flex h-[30px] w-[30px] items-center justify-center rounded-full"
              style={{
                border: "1px solid black",
              }}
            >
              01
            </div>
            <div className="text-[28px] font-bold">{t("in_event")}</div>
            <img
              className="mt-10 w-[140px]"
              src="/images/img-04.png"
              alt="img-04.png"
            />
          </div>
          <div className=" w-[1px] bg-black"></div>
          <div className="flex flex-1 flex-col items-center px-4 py-4">
            <div
              className="flex h-[30px] w-[30px] items-center justify-center rounded-full"
              style={{
                border: "1px solid black",
              }}
            >
              02
            </div>
            <div className="text-[28px] font-bold">{t("in_shop")}</div>
            <img
              className="mt-10 w-[140px]"
              src="/images/img-05.png"
              alt="img-05.png"
            />
          </div>
          <div className=" w-[1px] bg-black"></div>
          <div className="flex flex-1 flex-col items-center px-4 py-4">
            <div
              className="flex h-[30px] w-[30px] items-center justify-center rounded-full"
              style={{
                border: "1px solid black",
              }}
            >
              03
            </div>
            <div className="text-[28px] font-bold">{t("in_office")}</div>
            <img
              className="mt-10 w-[140px]"
              src="/images/img-06.png"
              alt="img-06.png"
            />
          </div>
        </div>
        <div
          className="flex cursor-pointer items-center justify-center rounded-lg bg-black px-10 py-4 text-white transition-all hover:bg-[#000000d9]"
          onClick={() => navigate("/pin-generate")}
        >
          {t("get_start_now")}
        </div>
      </div>
    </LayoutWrapper>
  )
}
