import React, { useState, useContext, useEffect } from "react"
import { OverviewContext } from "@/core/store/OverviewContext"
import { SearchContext } from "@/core/store/SearchContext"
import { useNavigate } from "react-router-dom"
import { useTheme } from "@mui/material"
import { useAsync, useSearchParam } from "react-use"
import { useAccount } from "wagmi"
import { AppContext } from "@/AppContext"
import { ContractTypeEnum } from "@/core/enum/ContractTypeEnum"
import CommonTabs from "@/components/common/CommonTabs"
import { accountMenus } from "@/core/constant/menu"
import CollectionContent from "../account/CollectionContent"
import SequentialContent from "../account/SequentialContent"
import { useTranslation } from "react-i18next"

export default function PinGenerate() {
  const [collectPage, setCollectPage] = useState(1)
  const [sequentialPage, setSequentialPage] = useState(1)
  const [tab, setTab] = useState(0)
  const pageSize = 16
  const { search } = useContext(SearchContext)
  const paramTab = useSearchParam("tab")
  const { address } = useAccount()
  const { breakpoints } = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const rawCollectData = useAsync(async () => {
    if (address) {
      const res = await AppContext.apiExecutor.getCollections(
        "all",
        collectPage,
        pageSize,
        search || undefined,
        ContractTypeEnum.NORMAL
      )
      return res.data
    } else {
      return {
        code: "",
        result: {
          item_list: [],
          total_page: 0,
          total_item_size: 0,
          page: 0,
        },
      }
    }
  }, [collectPage, sequentialPage, address, search])

  const rawSequentialData = useAsync(async () => {
    if (address) {
      const res = await AppContext.apiExecutor.getCollections(
        "unpin",
        sequentialPage,
        pageSize,
        search || undefined,
        ContractTypeEnum.SEQUENTIAL
      )
      return res.data
    } else {
      return {
        code: "",
        result: {
          item_list: [],
          total_page: 0,
          total_item_size: 0,
          page: 0,
        },
      }
    }
  }, [collectPage, sequentialPage, address, search])

  useEffect(() => {
    if (
      paramTab &&
      ["COLLECTION", "SEQUENTIAL_COLLECTION"].includes(paramTab)
    ) {
      setTab(paramTab === "COLLECTION" ? 0 : 1)
    }
  }, [paramTab])

  return (
    <OverviewContext.Provider
      value={{
        sequentialPage,
        setSequentialPage,
        collectPage,
        setCollectPage,
        rawCollectData,
        rawSequentialData,
        pageSize,
      }}
    >
      <div className="px-8 pb-1">
        <div className="mt-10 text-[32px] font-bold leading-[38px] lg:mt-20">
          {t("pin_generate")}
        </div>
        <CommonTabs
          value={tab}
          changeTab={newValue => {
            setTab(newValue)
            navigate(
              `?tab=${newValue === 0 ? "COLLECTION" : "SEQUENTIAL_COLLECTION"}`,
              {
                replace: true,
              }
            )
          }}
          sx={{
            margin: "48px 0 24px",
            [breakpoints.down("lg")]: {
              margin: "20px 0",
            },
          }}
          options={accountMenus}
        />
        {tab === 0 ? <CollectionContent /> : <SequentialContent />}
      </div>
    </OverviewContext.Provider>
  )
}
