import React, { useEffect } from "react"
import { LayoutWrapper } from "@/components/layout/LayoutWrapper"
import AlertContainer from "@/core/store/AlertContainer"

import { PageHeader } from "@/components/common/PageHeader"

export default function PrivacyPolicy() {
  const { closeDialog } = AlertContainer.useContainer()

  useEffect(() => closeDialog(), [])

  return (
    <LayoutWrapper>
      <PageHeader>プライバシーポリシー</PageHeader>
      <div className="doc mx-auto max-w-[1000px] px-4 pb-32">
        <div className="mb-8 py-4 text-right">最終更新日:2023.11.20</div>
        <p>
          株式会社ワールドスキャンプロジェクト（以下
          、「当社」）は、お客様のプライバシーに関する権利を尊重します。本プライバシーポリシーは、当社のプラットフォーム、開発者ツール、ウェブサイトおよびその他のサービス（以下、総称して「サービス」）を通じて当社が収集する個人情報に適用され、データの収集および使用について規定するものです。
          <br />
          本プライバシーポリシーにおいて、「ユーザー」、「お客様」、「あなた」とは、本サービスのユーザーであるあなたを意味します。
        </p>
        <p>
          株式会社ワールドスキャンプロジェクトの詳細については、当社のウェブサイト
          <a href="https://world-scan-project.com" className="text-sky-500">
            https://world-scan-project.com
          </a>
          （以下、「ウェブサイト」）をご覧ください。
        </p>
        <p>
          当社は、お客様の同意（明示的または黙示的である場合があります）または許可された法律により要求された場合に、お客様の個人情報を収集、使用および開示します。お客様の個人情報を当社に提供することにより、お客様は、本プライバシーポリシーに従った当社の個人情報の収集、使用および開示に同意したものとみなされます。
        </p>
        <h2>個人情報の収集</h2>
        <p>
          サービスを通じて当社が収集する可能性のあるお客様の個人情報は、大まかに以下のカテゴリーに分類されます。
        </p>
        <p>
          お客様が任意で提供する情報:
          <br />
          例えば、当社へのアカウント登録、サービスの利用開始、当社からのマーケティング通信の購読や当社へのお問い合わせの送信のために、お客様の連絡先情報の提供をお願いする場合があります。お客様に提供をお願いする個人情報は、個人情報の提供をお願いする時点で明確にします。
        </p>
        <p>
          当社が自動的に収集する情報:
          <br />
          お客様が当社のサービスを利用する際、当社はお客様のデバイスから特定の情報を自動的に収集することがあります。これらの情報は、適用されるデータ保護法の下で個人情報とみなされる場合があります。具体的には、当社が自動的に収集する情報には、お客様のIPアドレス、デバイスの種類、固有のデバイス識別番号、ブラウザの種類、広範な地理的位置（国または都市レベルの位置など）、サードパーティーウォレットのウォレットID、ブロックチェーンアドレスおよびその他の技術情報などの情報が含まれる場合があります。また、当社は、利用されたサービスやアクセスされたウェブサイトのページなど、お客様のデバイスが当社のサービスとどのように相互作用したかに関する情報を収集することがあります。このような情報を収集することで、当社ウェブサイトを訪れる訪問者、その訪問者がどこから来たのか、当社ウェブサイトのどのコンテンツに関心があるのかをよりよく理解することができます。この情報の一部は、以下の「Cookieおよび類似のトラッキング技術」の見出しで詳しく説明するように、Cookieおよび類似のトラッキング技術を使用して収集される場合があります。
        </p>
        <p>
          第三者から取得する情報:
          <br />
          ただし、これらの第三者がお客様の同意を得ていること、またはお客様の個人情報を当社に開示することが法的に許可されていること、もしくは義務付けられていることを当社が確認した場合に限ります。
        </p>
        <h2>個人情報の利用</h2>
        <p>当社は個人データを以下の目的で使用することがあります。</p>
        <ul className="mb-4 list-disc pl-[1.2em]">
          <li>お客様が要求されたサービスを提供するため。</li>
          <li>ピアツーピアコミュニケーションを可能にするため。</li>
          <li>
            カスタマー・サービス、アカウント・メンテナンス、テクニカル・サポートに関するご要望にお応えするため。
          </li>
          <li>
            当社が実施する世論調査、アンケート、その他同様のフィードバック収集活動を通じて、本サービスの質を向上させるため。
          </li>
          <li>
            サービスの更新、新製品、またはキャンペーンをお知らせするため。
          </li>
          <li>匿名化された集計情報の作成、レビュー、分析、共有のため。</li>
          <li>購入および利用情報を追跡するため。</li>
          <li>本プライバシーポリシーまたは当社の利用規約を実施するため。</li>
          <li>
            お客様、当社、当社のユーザー、および公衆の権利、財産、または個人の安全を守るため。
          </li>
          <li>詐欺行為または潜在的な違法行為を防止するため。</li>
          <li>
            適用法を遵守するために必要な場合、または適用法によって許可された場合。
          </li>
          <li>情報が収集されたその他の目的の遂行。</li>
          <li>お客様の同意またはお客様の指示によるその他の目的の遂行。</li>
        </ul>
        <p>
          本プライバシーポリシーに記載されている目的のために、当社は、本サービスを通じて収集した情報を、オンラインおよびオフラインの他の情報源から受け取った情報と結合し、本プライバシーポリシーに従って当該結合情報を使用することがあります。
        </p>
        <h2>個人情報の保持</h2>
        <p>
          当社は、お客様から収集した個人情報を、継続的に正当な業務上の必要性がある場合（例えば、お客様が要求したサービスを提供するため、または適用される法律、税務、会計上の要件を遵守するため）に保持します。
        </p>
        <h2>個人情報の移転</h2>
        <p>
          個人データを含むお客様の情報は、当社の事業所および処理に関与する関係者が所在するその他の場所で処理されます。これは、データ保護法がお客様の管轄区域とは異なる可能性のある、お客様の市区町村、県、国、またはその他の政府管轄区域外にあるコンピュータに、この情報が転送され、そこで管理される可能性があることを意味します。
        </p>
        <p>
          本プライバシーポリシーに同意し、かかる情報を提出することは、かかる情報の移転に同意することを意味します。
        </p>
        <p>
          当社は、お客様のデータが本プライバシーポリシーに従って安全に取り扱われるよう、合理的に必要なあらゆる措置を講じるものとし、お客様のデータおよびその他の個人情報のセキュリティを含む適切な管理が行われている限り、基本的にお客様の個人データが組織または国に移転されることはありません。
        </p>
        <h2>Cookieポリシー</h2>
        <p>
          当社は、お客様に可能な限り最高のコンテンツと体験を提供するために、Cookieおよび同様の技術を使用しています。Cookie
          は、お客様を記憶し、お客様が本サービスをどのように利用したかに関する情報を収集するために使用されます。お客様が本サービスのアカウントをお持ちの場合、当社はこの利用データを他の情報とリンクさせることがあります。お客様は、これらのCookieを受け入れるか拒否するかを選択することができます。拒否を選択した場合、本サービスの一部をご利用いただけない場合があります。
        </p>
        <h2>セキュリティ</h2>
        <p>
          当社は、お客様の個人データが誤って紛失したり、不正な方法で使用またはアクセスされたり、改ざんされたり、開示されたりしないよう、適切なセキュリティ対策を講じています。また、お客様の個人データへのアクセスは、業務上知る必要のある従業員、代理人、請負業者、その他の第三者に限定しています。これらの第三者は、当社の指示に基づいてのみお客様の個人データを処理し、守秘義務を負います。ただし、インターネットを介した送信方法や電子的な保存方法は、100%安全ではないことをご留意ください。当社は、お客様の個人データを保護するために商業的に許容される手段を使用するよう努めますが、その絶対的な安全性を保証することはできません。
        </p>
        <h2>プライバシーポリシーの更新</h2>
        <p>
          当社は、法的、技術的またはビジネス上の進展の変化に応じて、本プライバシーポリシーを随時更新することがあります。当社がプライバシーポリシーを更新する場合、当社は、当社が行う変更の重要性に応じて、お客様に通知するための適切な措置を講じます。当社は、適用されるデータ保護法により要求される場合、およびそのような場合には、重大なプライバシーポリシーの変更についてお客様の同意を得るものとします。本プライバシーポリシーの最終更新日は、本プライバシーポリシーの上部に表示される「最終更新日」を確認することで確認できます。
        </p>
        <h2>お問い合わせ方法</h2>
        <p>
          お問い合わせ方法
          <br />
          お客様の個人情報の使用に関してご質問やご不明な点がございましたら、下記までご連絡ください：
        </p>
        <a href="https://lin.ee/3iPsqHs" className="text-sky-500">
          ＜お問い合わせ窓口＞
        </a>
      </div>
    </LayoutWrapper>
  )
}
