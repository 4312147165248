import React from "react"

export type GlobalContextProps = {
  search: string
  debounce: string
  setDebounce: React.Dispatch<React.SetStateAction<string>>
}

export const SearchContext = React.createContext<GlobalContextProps>({
  search: "",
  debounce: "",
  setDebounce: () => null,
})
