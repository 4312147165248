import React from "react"

export type LinkComponentProps = {
  value: string
}

export const LinkComponent: React.FunctionComponent<LinkComponentProps> = ({
  value,
}) =>
  value ? (
    <a
      className="underline transition-colors hover:opacity-80"
      href={value}
      target="_blank"
      rel="noreferrer noopener"
    >
      {value}
    </a>
  ) : (
    "--"
  )
