import { Chain } from "@wagmi/core"
import { mainnet } from "wagmi"
import { bsc, localhost, polygon } from "viem/chains"

export const diverChain = {
  id: 5566,
  name: "DIVER Chain",
  network: "diverchain",
  nativeCurrency: {
    decimals: 18,
    name: "DIVER",
    symbol: "DIV",
  },
  rpcUrls: {
    public: { http: ["https://diver-rpc.com"] },
    default: { http: ["https://diver-rpc.com"] },
  },
  blockExplorers: {
    default: { name: "DIVER Scan", url: "https://scan.diver.io" },
  },
} as Chain

export const chainLogoMapper = {
  [diverChain.id]: "/chains/diver.png",
  [mainnet.id]: "/chains/ethereum.png",
  [polygon.id]: "/chains/polygon.png",
  [localhost.id]: "/chains/ganache.png",
  [bsc.id]: "/chains/bnb.png",
} as Record<number, string>
