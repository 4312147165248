import React from "react"
import { AppContext } from "@/AppContext"
import { useAsync } from "react-use"
import { useParams } from "react-router-dom"
import { LoadingWrapper } from "@/components/common/loading/LoadingWrapper"
import { ContractTypeEnum } from "@/core/enum/ContractTypeEnum"
import { SequentialCollectionContainer } from "@/components/collection/SequentialCollectionContainer"
import { getBalanceOf } from "@/core/api/balanceOf"

export default function SequentialCollectionDetail() {
  const { id } = useParams()

  const rawData = useAsync(async () => {
    const res = await AppContext.apiExecutor.getCollections(
      "all",
      1,
      1,
      undefined,
      ContractTypeEnum.SEQUENTIAL,
      id
    )

    return res.data.result?.item_list?.[0]
  }, [])

  const rawNFTData = useAsync(async () => {
    if (
      id &&
      AppContext.getAuthAddress() &&
      rawData.value &&
      rawData.value.contract_address
    ) {
      const nftRes = await AppContext.apiExecutor.getNFTs(
        "all",
        1,
        1,
        rawData.value.contract_address
      )
      return nftRes.data
    } else {
      return {
        code: "",
        result: {
          item_list: [],
          total_page: 0,
          total_item_size: 0,
          page: 0,
        },
      }
    }
  }, [AppContext.getAuthAddress, rawData.value, id])

  const rawBalanceOf = useAsync(async () => {
    const res = await getBalanceOf(
      AppContext.getAuthAddress(),
      rawData.value?.contract_address as string
    )
    return res
  }, [rawData])

  return (
    <LoadingWrapper
      state={rawData}
      keepLoading={data => !data}
      render={data => (
        <SequentialCollectionContainer
          collectionData={data}
          rawNFTData={rawNFTData}
          rawBalanceOf={rawBalanceOf}
        />
      )}
    />
  )
}
