import React, { FC, PropsWithChildren } from "react"
import { useNavigate } from "react-router-dom"
import { CollectionItemDto, PinNFTDto } from "@/core/api"
import { Box } from "@mui/material"
import { SingleNFTIcon } from "./CommonIcon"
import { useSearchParam } from "react-use"
import { ContractTypeEnum } from "@/core/enum/ContractTypeEnum"
import { useLocation } from "react-router-dom"
import { CommonButton } from "./CommonButton"
import QrCodeIcon from "@mui/icons-material/QrCode"
import { useTranslation } from "react-i18next"
import useDetailActions from "@/core/hook/useDetailActions"

type Props = {
  collection: CollectionItemDto
}

const CollectionCard: FC<PropsWithChildren<Props>> = ({ collection }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const paramTab = useSearchParam("tab")
  const location = useLocation()
  const isAccountPage = location.pathname.includes("account")
  const isSequential = collection.contract_type === ContractTypeEnum.SEQUENTIAL
  const { showQrCode } = useDetailActions()

  const handleNavigate = () => {
    if (isAccountPage) {
      isSequential
        ? navigate(`/sequential_collection/${collection.collectionID}`)
        : navigate(`/collection/${collection.collectionID}`)
    } else {
      isSequential
        ? navigate(`/sequential-pin-generate/${collection.collectionID}`)
        : navigate(`/pin-generate/${collection.collectionID}`)
    }
  }

  const renderText = () => {
    if (isAccountPage) {
      return isSequential ? "view_detail" : "view_nft"
    } else {
      return isSequential ? "pin_sequential_collection" : "pin_nft"
    }
  }

  return (
    <div
      className="w-full cursor-pointer overflow-hidden rounded-2xl border-2 border-[#D1D1D1] p-3 transition-all hover:border-black"
      onClick={handleNavigate}
    >
      <Box className="relative w-full">
        <img
          src={collection.feature_img_url}
          className="mx-auto aspect-video w-full rounded-tl-lg rounded-tr-lg object-cover"
          alt={collection.name}
        />
        {Number(collection.pin_counts) > 0 &&
          (paramTab !== "SEQUENTIAL_COLLECTION" && isAccountPage ? (
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                position: "absolute",
                top: 8,
                left: 8,
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                borderRadius: 2,
                color: "white",
                padding: 1,
                fontSize: 14,
              }}
            >
              <SingleNFTIcon fontSize="small" sx={{ marginRight: 0.5 }} />
              {t("single_nft")}
            </Box>
          ) : (
            paramTab === "SEQUENTIAL_COLLECTION" && (
              <Box
                className="absolute right-2 top-2 rounded border border-[#F3F3F3] bg-white p-1 transition-colors hover:border-black"
                onClick={event =>
                  showQrCode(collection.pin as PinNFTDto, event)
                }
              >
                <QrCodeIcon sx={{ fontSize: 32 }} />
              </Box>
            )
          ))}
      </Box>
      <Box
        className="h-fit overflow-hidden py-4 text-lg font-semibold"
        sx={{ maxHeight: 96, wordBreak: "break-word" }}
      >
        <div className="line-clamp-2 h-full w-full">{collection.name}</div>
      </Box>
      <CommonButton
        variant="outlined"
        sx={{
          color: "#000",
          borderColor: "#000",
          borderRadius: 2,
          padding: "6px 12px",
          textWrap: "nowrap",
          width: "100%",
          "&:hover": {
            borderColor: "#000",
            backgroundColor: "#00000009",
          },
        }}
      >
        {t(renderText())}
      </CommonButton>
    </div>
  )
}

export default CollectionCard
