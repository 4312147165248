import React from "react"
import { styled } from "@mui/material/styles"
import { Radio } from "@mui/material"
import { RadioProps } from "@mui/material/Radio/Radio"

export const CommonRadio = styled((props: RadioProps) => <Radio {...props} />)(
  () => ({
    color: "#AEAEAE",
    padding: 0.5,
    "&.Mui-checked": {
      color: "#000",
    },
    "& .MuiSvgIcon-root": {
      fontSize: 28,
    },
  })
)
