import React from "react"
import SvgIcon from "@mui/material/SvgIcon"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CircleIcon from "@mui/icons-material/Circle"
import { useTranslation } from "react-i18next"

type Props = {
  menus: string[]
  step: number
}

export default function CommonProgressBar({ menus, step }: Props) {
  const { t } = useTranslation()

  return (
    <ul className="progressbar border-b border-black py-10">
      {menus.map((key, index) => (
        <li className={`${step >= index ? "active" : ""}`} key={index}>
          <div className="relative inline-block">
            <div className="absolute left-[6px] top-[6px] z-0 aspect-square h-5 w-5 rounded-full bg-white" />
            <SvgIcon
              className="relative z-0"
              sx={{ fontSize: 30 }}
              component={step >= index ? CheckCircleIcon : CircleIcon}
            />
          </div>
          <div className="mt-2">{t(key)}</div>
        </li>
      ))}
    </ul>
  )
}
