import React from "react"
import { WalletImageMapper } from "@/core/constant/WalletMapper"
import { Connector, useConnect } from "wagmi"

export type OtherInjectConnectorProps = { handleClose: () => void }

export const OtherInjectConnector: React.FunctionComponent<
  OtherInjectConnectorProps
> = ({ handleClose }) => {
  const { connect, connectors } = useConnect()
  const connectWallet = (connector: Connector) => {
    connect({ connector })
    handleClose()
  }

  return (
    <div className="h-[300px] w-[20vw] min-w-[300px] rounded-xl bg-white px-8 py-5">
      {connectors
        .filter(connector => connector.ready)
        .map(connector => (
          <div
            className="hover:border-primary-main mb-2 flex w-full cursor-pointer items-center rounded-lg border border-gray-300 px-3 py-2 last:mb-0 hover:bg-blue-100"
            key={connector.id}
            onClick={() => connectWallet(connector)}
          >
            <img
              className="mr-3 aspect-square w-8"
              src={WalletImageMapper[connector.name]}
              alt={connector.name}
            />
            <span className="font-semibold">{connector.name}</span>
          </div>
        ))}
    </div>
  )
}
