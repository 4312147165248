import React, { FC, PropsWithChildren, useEffect } from "react"
import { configureChains, createConfig, mainnet, WagmiConfig } from "wagmi"
import { bsc, localhost, polygon } from "viem/chains"
import { InjectedConnector } from "wagmi/connectors/injected"
import { MetaMaskConnector } from "wagmi/connectors/metaMask"
import { publicProvider } from "wagmi/providers/public"
import { diverChain } from "@/core/constant/chain"
import AlertContainer from "@/core/store/AlertContainer"
import { AlertModal } from "@/components/common/AlertModal"
import { AlertMessage } from "@/components/common/AlertMessage"
import { useSearchParams } from "react-router-dom"

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const [searchParams] = useSearchParams()
  const isMobileDiverWallet = searchParams.get("is_diver_wallet") === "true"
  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [diverChain, mainnet, polygon, localhost, bsc],
    [publicProvider()]
  )
  const testModel = sessionStorage.getItem("metamask_connect")

  useEffect(() => {
    if (isMobileDiverWallet && window.ethereum) {
      window.ethereum.isDiverWallet = true
      localStorage.setItem("is_diver_wallet", "true")
    }
  }, [isMobileDiverWallet])

  const config = createConfig({
    autoConnect: true,
    publicClient,
    webSocketPublicClient,
    connectors: () => {
      const connectors = [
        new InjectedConnector({
          chains,
          options: {
            name: "DiverWallet",
            getProvider: () =>
              window.ethereum?.isDiverWallet || isMobileDiverWallet
                ? window.ethereum
                : undefined,
            shimDisconnect: true,
          },
        }),
      ]
      if (testModel === "true") {
        connectors.push(
          new MetaMaskConnector({
            chains,
            options: {
              shimDisconnect: true,
            },
          })
        )
      }
      return connectors
    },
  })

  return (
    <WagmiConfig config={config}>
      <AlertContainer.Provider>
        <main>{children}</main>
        <AlertModal />
        <AlertMessage />
      </AlertContainer.Provider>
    </WagmiConfig>
  )
}

export default Layout
