import React from "react"
import { Box } from "@mui/material"
export type ListComponentProps = {
  title: string
  value: string | number | JSX.Element | undefined
}

export const ListComponent: React.FunctionComponent<ListComponentProps> = ({
  title,
  value,
}) => {
  return (
    <Box
      className="flex justify-between border-t p-6 first:border-t-0"
      sx={{ borderColor: "#00000033" }}
    >
      <div className="flex-0 font-medium">{title}</div>
      <div className="break-words text-right text-sm">
        {value ? value : "--"}
      </div>
    </Box>
  )
}
