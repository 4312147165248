import React from "react"
import { format, fromUnixTime } from "date-fns"
import { useTranslation } from "react-i18next"
import { TimePeriodEnum } from "@/core/enum/TimePeriodEnum"
import { PinNFTDto } from "@/core/api"

type Props = {
  pin: PinNFTDto
}

export default function TimePeriodComponent({ pin }: Props) {
  const { t } = useTranslation()

  const renderFixedTimePeriod = () => {
    let availableTime = new Array<string>()
    for (const dateTime of pin.available_time) {
      const startTime = format(fromUnixTime(dateTime.from), "HH:mm")
      const endTime = format(fromUnixTime(dateTime.to), "HH:mm")
      availableTime.push(`${startTime} ~ ${endTime}`)
    }
    availableTime = Array.from(new Set(availableTime))
    return (
      <div className="mb-2 flex">
        <span className="min-w-[50px]">{`${t("period")}：`}</span>
        <div>
          <span>{`${format(
            fromUnixTime(pin.start_date_timestamp),
            "yyyy-MM-dd"
          )}`}</span>
          <span className="mx-2">~</span>
          <span className="me-2">{`${format(
            fromUnixTime(pin.end_date_timestamp),
            "yyyy-MM-dd"
          )}`}</span>
          <div>
            {availableTime.map((time, index) => (
              <span
                key={index}
                className={`${
                  index + 1 !== availableTime.length && availableTime.length > 1
                    ? "me-2 border-e-2  border-gray-400 pe-2 "
                    : ""
                }`}
              >
                <span>{time}</span>
              </span>
            ))}
          </div>
        </div>
      </div>
    )
  }
  const renderCustomTimePeriod = () => {
    const weekMap = new Map()
    for (const dateTime of pin.available_time) {
      const week = format(
        fromUnixTime(dateTime.from),
        "EEEE"
      ).toLocaleLowerCase()
      const startTime = format(fromUnixTime(dateTime.from), "HH:mm")
      const endTime = format(fromUnixTime(dateTime.to), "HH:mm")

      if (weekMap.has(week)) {
        weekMap.set(week, [
          ...new Set([...weekMap.get(week), `${startTime} ~ ${endTime}`]),
        ])
      } else {
        weekMap.set(week, [`${startTime} ~ ${endTime}`])
      }
    }
    return (
      <div className="mb-2 flex">
        <span className="min-w-[50px]">{`${t("period")}：`}</span>
        <div className="w-full flex-1">
          <span>{`${format(
            fromUnixTime(pin.start_date_timestamp),
            "yyyy-MM-dd"
          )}`}</span>
          <span className="mx-2">~</span>
          <span className="me-2">{`${format(
            fromUnixTime(pin.end_date_timestamp),
            "yyyy-MM-dd"
          )}`}</span>
          <div>
            {Array.from(weekMap.entries()).map(([key, value]) => (
              <div key={key} className="py-1">
                <span className="me-2">{t(`option_${key}`)} :</span>
                <div className="my-1 ms-[10%]">
                  {value.map((time: string, index: number) => (
                    <span
                      key={index}
                      className={`${
                        index + 1 !== value.length && value.length > 1
                          ? "me-2 border-e-2  border-gray-400 pe-2 "
                          : ""
                      }`}
                    >
                      <span>{time}</span>
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  const renderDefaultTimePeriod = () => {
    return (
      <div className="mb-2">
        <span>{`${t("period")}：`}</span>
        {pin.end_date_timestamp === 4102415999 ? (
          t("option_no_limit")
        ) : (
          <div className="inline-block">
            <span>{`${format(
              fromUnixTime(pin.start_date_timestamp),
              "yyyy-MM-dd"
            )}`}</span>
            <span className="mx-2">~</span>
            <span>{`${format(
              fromUnixTime(pin.end_date_timestamp),
              "yyyy-MM-dd"
            )}`}</span>
          </div>
        )}
      </div>
    )
  }

  switch (pin.timePeriod) {
    case TimePeriodEnum.FIXED_TIME_PEROID:
      return renderFixedTimePeriod()
    case TimePeriodEnum.CUSTOM_TIME_PEROID:
      return renderCustomTimePeriod()
    default:
      return renderDefaultTimePeriod()
  }
}
