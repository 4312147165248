import React, { useState } from "react"
import { Box, Pagination, useTheme } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useTranslation } from "react-i18next"
import { useAsync } from "react-use"
import { AppContext } from "@/AppContext"
import { useAccount } from "wagmi"
import { LoadingWrapper } from "@/components/common/loading/LoadingWrapper"
import NFTSelectorCard from "@/components/common/NFTSelectorCard"
import { NFTItemDto } from "@/core/api"
import { ContractTypeEnum } from "@/core/enum/ContractTypeEnum"

export type NFTReplacePopupProps = {
  close: () => void
  setSelectedNFT: React.Dispatch<React.SetStateAction<NFTItemDto | undefined>>
  companyHash: string | undefined
}

export const NFTReplacePopup: React.FunctionComponent<NFTReplacePopupProps> = ({
  close,
  setSelectedNFT,
  companyHash,
}) => {
  const [page, setPage] = useState(1)
  const [currentSelectedNFT, setCurrentSelectedNFT] = useState<NFTItemDto>()
  const pageSize = 9
  const { address } = useAccount()
  const { breakpoints } = useTheme()
  const { t } = useTranslation()
  const rawNFTData = useAsync(async () => {
    if (address) {
      const res = await AppContext.apiExecutor.getNFTs(
        "unpin",
        page,
        pageSize,
        undefined,
        undefined,
        undefined,
        companyHash,
        ContractTypeEnum.NORMAL
      )

      return res.data
    }
  }, [page, address])

  const handleConfirm = async () => {
    if (currentSelectedNFT) {
      setSelectedNFT(currentSelectedNFT)
      close()
    }
  }

  return (
    <Box
      className="pb-14 pt-20"
      sx={{
        width: "95%",
        margin: "auto",
        [breakpoints.up("md")]: { width: 1200 },
      }}
    >
      <CloseIcon
        className="absolute right-4 top-4 cursor-pointer hover:text-gray-400"
        fontSize="large"
        onClick={close}
      />
      <div className="mx-auto flex flex-col justify-center md:w-5/6">
        <div className="border-b border-black pb-6 text-center">
          <div className="text-5xl font-bold leading-[56px]">
            {t("nft_set_up_by_qr")}
          </div>
          <div className="mt-4">
            <span className="form_required_prefix text-[18px]">
              {t("select_nft")}
            </span>
          </div>
        </div>
        <LoadingWrapper
          state={rawNFTData}
          render={data => (
            <div className="mt-9 grid grid-cols-2 gap-4 sm:grid-cols-2 lg:grid-cols-3">
              {data?.result.item_list?.map(pinData => (
                <NFTSelectorCard
                  key={pinData.nft.address + pinData.nft.tokenID}
                  selectedNFT={currentSelectedNFT}
                  pinData={pinData}
                  onClick={() => setCurrentSelectedNFT(pinData.nft)}
                />
              ))}
            </div>
          )}
        />
        {rawNFTData.value && (
          <div className="mb-8 mt-12 flex justify-center">
            <Pagination
              page={page}
              count={rawNFTData.value.result.total_page}
              variant="outlined"
              shape="rounded"
              onChange={(_, page) => setPage(page)}
            />
          </div>
        )}

        <div className="flex justify-center">
          <div
            onClick={handleConfirm}
            className={`flex w-[376px] items-center justify-center rounded-[6px] py-3 text-[20px] ${
              currentSelectedNFT
                ? "cursor-pointer bg-black text-white"
                : "cursor-no-drop bg-gray-200 text-gray-400"
            }`}
          >
            {t("confirm")}
          </div>
        </div>
      </div>
    </Box>
  )
}
