import { TimePeriodEnum } from "@/core/enum/TimePeriodEnum"
import { WeekEnum } from "@/core/enum/WeekEnum"
import { DateRangeEnum } from "@/core/enum/DateRangeEnum"
import { Option } from "@/core/types/Options"
import { TFunction } from "i18next"

export function getTimePeriodOptions(t: TFunction): Array<Option<string>> {
  return [
    {
      label: t("option_no_time_limit"),
      value: TimePeriodEnum.NO_TIME_LIMIT,
    },
    {
      label: t("option_fixed_time_peroid"),
      value: TimePeriodEnum.FIXED_TIME_PEROID,
    },
    {
      label: t("option_custom_time_peroid"),
      value: TimePeriodEnum.CUSTOM_TIME_PEROID,
    },
  ]
}

export function getWeekOptions(t: TFunction): Array<Option<string>> {
  return [
    {
      label: t("option_monday"),
      value: WeekEnum.MONDAY,
    },
    {
      label: t("option_tuesday"),
      value: WeekEnum.TUESDAY,
    },
    {
      label: t("option_wednesday"),
      value: WeekEnum.WEDNESDAY,
    },
    {
      label: t("option_thursday"),
      value: WeekEnum.THURSDAY,
    },
    {
      label: t("option_friday"),
      value: WeekEnum.FRIDAY,
    },
    {
      label: t("option_saturday"),
      value: WeekEnum.SATURDAY,
    },
    {
      label: t("option_sunday"),
      value: WeekEnum.SUNDAY,
    },
  ]
}

export function getRangeOptions(t: TFunction): Array<Option<string>> {
  return [
    {
      label: t("option_no_limit"),
      value: DateRangeEnum.NO_LIMIT,
    },
    {
      label: t("option_date_range"),
      value: DateRangeEnum.THREE_MONTHS,
    },
  ]
}

export function getTermsOptions(t: TFunction): Array<Option<string>> {
  return [
    {
      label: t("str_terms_own_risk"),
      value: "risk",
    },
    {
      label: t("str_terms_responsible"),
      value: "responsible",
    },
    {
      label: t("str_terms_agree"),
      value: "agree",
    },
  ]
}
