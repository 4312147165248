import React from "react"
import { useTranslation } from "react-i18next"
import { CommonButton } from "./CommonButton"
import { TokenData } from "@/core/types/Response"

export type TokenRowProps = {
  nft: TokenData
}

export const TokenRow: React.FunctionComponent<TokenRowProps> = ({ nft }) => {
  const { t } = useTranslation()

  return (
    <div
      className={`flex items-center justify-between rounded-lg px-3 py-2 ${
        nft.isTransferred ? "bg-[#F0F0F0]" : "bg-[#F4F8FB]"
      }`}
    >
      <div className="flex items-center">
        {nft.isTransferred && (
          <div className="rounded bg-[#6DBAE7] px-1.5 py-1 text-sm leading-tight text-[#EDF6F9]">
            {t("transferred")}
          </div>
        )}
        <div className="ml-3 text-lg font-bold leading-5">
          {t("nft_token_id", { tokenID: nft.tokenID })}
        </div>
      </div>
      <CommonButton
        variant="outlined"
        sx={{
          borderRadius: 2,
          paddingY: 1,
          paddingX: 2,
          lineHeight: "18px",
          whiteSpace: "nowrap",
          borderColor: "#000",
          color: "#000",
          "&:hover": {
            borderColor: "#000",
            bgcolor: "#00000009",
          },
        }}
        onClick={() =>
          window.open(
            `${import.meta.env.VITE_SCAN_URL}/nft/${nft.address}/${
              nft.tokenID
            }`,
            "_blank"
          )
        }
      >
        {t("view_on_diver_scan")}
      </CommonButton>
    </div>
  )
}
