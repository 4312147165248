import React, { useState } from "react"
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft"
import CampaignIcon from "@mui/icons-material/Campaign"
import ErrorIcon from "@mui/icons-material/Error"
import LinkOffIcon from "@mui/icons-material/LinkOff"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { useTranslation } from "react-i18next"
import { Tooltip, Zoom, useMediaQuery, useTheme } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useAsync } from "react-use"
import { AppContext } from "@/AppContext"
import { LoadingWrapper } from "@/components/common/loading/LoadingWrapper"
import { NFTDetailBasic } from "@/pages/nftDetail/NFTDetailBasic"
import QrCodeIcon from "@mui/icons-material/QrCode"
import { useCopyToClipboard } from "react-use"
import { truncateEthAddress } from "@/core/utils/truncateEthAddress"
import { CommonButton } from "@/components/common/CommonButton"
import { getProperty, getDateRange } from "@/core/utils/detailUtils"
import { SectionComponent } from "@/components/common/metadata/SectionComponent"
import { ListComponent } from "@/components/common/metadata/ListComponent"
import { LinkComponent } from "@/components/common/metadata/LinkComponent"
import useDetailActions from "@/core/hook/useDetailActions"

export default function NFTDetail() {
  const [, copyToClipboard] = useCopyToClipboard()
  const [copySuccess, setCopySuccess] = useState(false)
  const { palette, breakpoints } = useTheme()
  const { t } = useTranslation()
  const { id, contract } = useParams()
  const { loading, unPinNFT, showQrCode } = useDetailActions()
  const isMobile = useMediaQuery(breakpoints.down("md"))
  const navigate = useNavigate()

  const rawData = useAsync(async () => {
    if (contract) {
      const res = await AppContext.apiExecutor.getNFTs(
        "all",
        1,
        1,
        contract,
        id
      )
      return res.data.result.item_list?.[0]
    }
    return undefined
  }, [contract])

  return (
    <div className="mx-auto w-11/12 py-8 sm:py-14 lg:w-4/5 xl:w-[1200px]">
      <LoadingWrapper
        state={rawData}
        keepLoading={data => !data}
        render={data => (
          <>
            {data && data.pin && Object.keys(data.pin).length > 0 && (
              <div className="flex items-center justify-center gap-3">
                <div
                  className="flex w-60 cursor-pointer items-center justify-center rounded-lg bg-black py-[13px] text-white transition-colors hover:bg-black/80"
                  onClick={() =>
                    navigate(`/edit/${data.nft.address}/${data.nft.tokenID}`)
                  }
                >
                  <QrCodeIcon fontSize="small" />
                  <span className="pl-2 font-medium">
                    {t("str_edit_qrcode")}
                  </span>
                </div>
                <div
                  className="flex w-60 cursor-pointer items-center justify-center rounded-lg border-2 border-black bg-white py-[11px] text-black transition-colors hover:bg-black/10"
                  onClick={() => showQrCode(data.pin)}
                >
                  <QrCodeIcon fontSize="small" />
                  <span className="pl-2 font-medium">{t("preview")}</span>
                </div>
              </div>
            )}
            {data && (
              <>
                <NFTDetailBasic data={data} />
                <div className="mx-auto md:w-4/5">
                  <SectionComponent
                    title="description"
                    icon={FormatAlignLeftIcon}
                  >
                    <div className="whitespace-pre-wrap p-6 leading-7">
                      {getProperty(
                        data.nft,
                        "details" || "クーポン内容の詳細説明"
                      )}
                    </div>
                  </SectionComponent>
                  <SectionComponent
                    title="information"
                    icon={CampaignIcon}
                    className="mt-12"
                  >
                    <>
                      <ListComponent
                        title={t("owner")}
                        value={
                          isMobile && data.nft
                            ? truncateEthAddress(data.nft.owner)
                            : data.nft.owner
                        }
                      />
                      <ListComponent
                        title={t("contract_address")}
                        value={
                          <>
                            <span className="pr-2">
                              {isMobile && data.nft
                                ? truncateEthAddress(data.nft.address)
                                : data.nft.address}
                            </span>
                            <Tooltip
                              TransitionComponent={Zoom}
                              placement="top"
                              disableFocusListener
                              title={
                                copySuccess
                                  ? t("copy_successfully")
                                  : t("copy_contract_address")
                              }
                              PopperProps={{
                                sx: {
                                  "& .MuiTooltip-tooltip": {
                                    backgroundColor: copySuccess
                                      ? palette.success.main
                                      : "",
                                  },
                                },
                              }}
                            >
                              <ContentCopyIcon
                                className="hover:text-primary cursor-pointer"
                                fontSize="small"
                                onClick={() => {
                                  copyToClipboard(data.nft.address)
                                  setCopySuccess(true)
                                }}
                                onMouseLeave={() =>
                                  setTimeout(() => setCopySuccess(false), 300)
                                }
                              />
                            </Tooltip>
                          </>
                        }
                      />
                      <ListComponent
                        title={t("token_id")}
                        value={data.nft.tokenID}
                      />
                      <ListComponent
                        title={t("token_standard")}
                        value={data.nft.standard}
                      />
                      <ListComponent
                        title={t("blockchain")}
                        value={data.nft.blockchain}
                      />
                    </>
                  </SectionComponent>
                  <SectionComponent
                    title="detail"
                    icon={ErrorIcon}
                    className="mt-12"
                  >
                    <>
                      <ListComponent
                        title={t("issue_quantity")}
                        value={data.nft.supply}
                      />
                      <ListComponent
                        title={t("address")}
                        value={getProperty(
                          data.nft,
                          "spot" || "クーポン利用店舗名"
                        )}
                      />
                      <ListComponent
                        title={t("terms_and_conditions")}
                        value={getProperty(
                          data.nft,
                          "rules" || "クーポン提示条件"
                        )}
                      />
                      <ListComponent
                        title={t("daterange")}
                        value={
                          getDateRange(
                            getProperty(data.nft, "startDate"),
                            getProperty(data.nft, "endDate"),
                            t
                          ) || getProperty(data.nft, "クーポン有効期限")
                        }
                      />
                      <ListComponent
                        title={t("remarks")}
                        value={getProperty(data.nft, "remarks" || "その他特典")}
                      />
                      <ListComponent
                        title={t("storename")}
                        value={getProperty(data.nft, "storeName" || "店舗名")}
                      />
                      <ListComponent
                        title={t("phone")}
                        value={getProperty(data.nft, "phone" || "店舗電話番号")}
                      />
                      <ListComponent
                        title={t("website")}
                        value={
                          <LinkComponent
                            value={getProperty(
                              data.nft,
                              "website" || "店舗ホームページ"
                            )}
                          />
                        }
                      />
                      <ListComponent
                        title={t("SNS")}
                        value={
                          <LinkComponent
                            value={getProperty(
                              data.nft,
                              "social_media" || "店舗SNS"
                            )}
                          />
                        }
                      />
                    </>
                  </SectionComponent>
                </div>
              </>
            )}
            {data && data.pin && Object.keys(data.pin).length > 0 && (
              <div className="mt-8 flex justify-center md:mb-16">
                <CommonButton
                  variant="outlined"
                  sx={{
                    width: "240px",
                    color: "#000",
                    borderColor: "#000",
                    borderRadius: 2,
                    paddingY: "10px",
                    borderWidth: 2,
                    "&:hover": {
                      borderColor: "#000",
                      borderWidth: 2,
                      backgroundColor: "#00000009",
                    },
                  }}
                  onClick={() => unPinNFT(data.pin._id)}
                  disabled={loading}
                >
                  <LinkOffIcon
                    sx={{
                      marginRight: "16px",
                    }}
                  />
                  {loading ? t("loading") : t("unlink")}
                </CommonButton>
              </div>
            )}
          </>
        )}
      />
    </div>
  )
}
