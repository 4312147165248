import React, { useEffect, useContext } from "react"
import CollectionCard from "@/components/common/CollectionCard"
import { Pagination } from "@mui/material"
import { OverviewContext } from "@/core/store/OverviewContext"
import { LoadingWrapper } from "@/components/common/loading/LoadingWrapper"
import NoDataComponent from "@/components/common/NoDataComponent"

export default function CollectionContent() {
  const { rawCollectData, setCollectPage, active } = useContext(OverviewContext)

  useEffect(() => {
    scrollTo(0, 0)
  }, [rawCollectData])

  return (
    <LoadingWrapper
      state={rawCollectData}
      render={collectData => (
        <>
          {collectData.result.total_item_size === 0 ? (
            <NoDataComponent active={active} />
          ) : (
            <>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {collectData.result?.item_list?.map(item => (
                  <CollectionCard key={item.collectionID} collection={item} />
                ))}
              </div>
              <div className="my-16 flex justify-center">
                <Pagination
                  page={collectData.result.page}
                  onChange={(_, page) => setCollectPage(page)}
                  count={collectData.result.total_page}
                  variant="outlined"
                  shape="rounded"
                />
              </div>
            </>
          )}
        </>
      )}
    />
  )
}
