import React, { useEffect } from "react"
import { LayoutWrapper } from "@/components/layout/LayoutWrapper"
import AlertContainer from "@/core/store/AlertContainer"
import { useNavigate } from "react-router-dom"

import { PageHeader } from "@/components/common/PageHeader"

export default function TermsOfService() {
  const navigate = useNavigate()
  const { closeDialog } = AlertContainer.useContainer()

  useEffect(() => closeDialog(), [])

  return (
    <LayoutWrapper>
      <PageHeader>利用規約</PageHeader>
      <div className="doc mx-auto max-w-[1000px] px-4 pb-32">
        <div className="mb-8 py-4 text-right">最終更新日:2023.11.20</div>
        <p>
          株式会社ワールドスキャンプロジェクト（以下
          、「当社」）が運営するCHAINPIN（以下「サービス」）をご利用いただく前に、本利用規約（以下「本規約」、「利用規約」）をよくお読みください。
        </p>
        <p>
          本利用規約において、「ユーザー」、「お客様」、「あなた」とは、本サービスのユーザーであるあなたを意味します。
        </p>
        <h2>適用</h2>
        <p>
          ユーザーは、本サービスにアクセスし、これを利用することにより、本利用規約に同意したものとみなされます。本利用規約に含まれる、または参照により組み込まれるすべての条件に同意しない場合は、いかなる方法でも本サービスにアクセスまたは使用をしないでください。お客様が会社またはその他の法人を代表して本利用規約に同意する場合、お客様は当該法人を代表して本利用規約に同意する法的権限を有することを表明するものとし、この場合、「お客様」とは当該法人を意味するものとします。この場合、「お客様」とはその法人を意味します。お客様がそのような権限を有しない場合は、いかなる方法によっても本サービスにアクセスまたは使用をしないでください。
        </p>
        <h2>定義</h2>
        <p>本規約において使用する用語の意義は、以下に定める通りとします。</p>
        <ol className="mb-4 list-decimal pl-[1.2em]">
          <li>
            「当社」とは、株式会社ワールドスキャンプロジェクトをいいます。
          </li>
          <li>「本規約」とは、本サービス利用規約をいいます。</li>
          <li>
            「トークン」とは、コンテンツをブロックチェーン上に記録されるトークンをいいます。
          </li>
          <li>
            「NFT」とは、非代替性のトークン（Non-Fungible Token）をいいます。
          </li>
          <li>
            「MINT」とは、ブロックチェーン上にNFTを発行することをいいます。
          </li>
          <li>
            「コンテンツ」とは、動画、静止画、音楽等、ユーザーが体験を得るための目的となるデジタルコンテンツをいい、本サービス上で視聴、閲覧又は再生その他の利用ができるものをいう。
          </li>
          <li>
            「ウォレット」とは、サードパーティのデジタル暗号通貨ウォレットをいいます。
          </li>
          <li>
            「DIVER
            Chain」とは、DIVERプロジェクトによって運営されているブロックチェーンをいいます。
          </li>
        </ol>
        <h2>個人情報の取扱い</h2>
        <p>
          当社による利用者の個人情報の取扱いについては、当社が別に定める「プライバシーポリシー」
          <span
            className="cursor-pointer text-sky-500"
            onClick={() => navigate("/privacy-policy")}
          >
            （https://chainpin.link/privacy-policy）
          </span>
          によるものとし、利用者は当該プライバシーポリシーに同意するものとします。
        </p>
        <h2>サービス概要</h2>
        <p>
          ウォレットについて：
          <br />
          ウォレットは、CHAINPINによって運営、管理されているものではなく、また、当社と提携しているものでもありません。CHAINPINは、ユーザーのウォレットの内容を保管または管理することはなく、その内容を取得または転送することはできません。当社は、お客様によるウォレットの使用に関連して、ユーザーに対していかなる責任も負わず、また、本サービスが特定のウォレットでどのように動作するかについていかなる表明も保証も行いません。ユーザーは、ご自身のウォレットを安全に管理することに単独で責任を負い、ウォレットの認証情報またはリカバリーフレーズを決して他人と共有してはなりません。ウォレットに関する問題を発見した場合は、ウォレットプロバイダーに連絡してください。同様に、ユーザーは自身のアカウントおよび関連するウォレットについて単独で責任を負うものとし、当社は、ユーザーのアカウントに関連する、またはユーザーのアカウントもしくはウォレットが侵害された結果としてのあなたの作為もしくは不作為について責任を負いません。
        </p>
        <p>
          利用料金と支払手段：
          <br />
          ユーザーは、本サービスで行われる取引に関連するすべてのサービス料金、および本規約で規定されるその他の適用料金を支払うことに同意するものとします。
          <br />
          利用料金の詳細は、本サービスが提供する決済画面に定めるものとします。利用料金に関する変更があった場合には、運営者は変更後の規約を決済画面に表示するものとし、ユーザーは変更後も取引を継続することにより、変更後の規約に同意したものとみなされます。
        </p>
        <p>
          お支払い方法は以下の通りです：
          <br />
        </p>
        <ul className="mb-4 list-disc pl-[1.2em]">
          <li>クレジットカード決済</li>
          <li>銀行振込</li>
        </ul>
        <p>
          ※カードおよびStripeによるすべての支払いは、日本円で払い戻し処理されます。最初の支払いと、その後の払い戻しが発行される時点との間の、他の国の通貨または暗号通貨間の為替レートの変動については、利用者のみが責任を負うものとします。
        </p>
        <h2>サービスへのアクセス</h2>
        <p>
          本サービスにアクセスするには、サポートされているウォレットを本サービスに接続する必要があります。お客様がウォレットに接続すると、お客様のウォレットアドレスおよびユーザー名が本サービス上に公開される場合があり、お客様はかかる公開に同意するものとします。
        </p>
        <h2>禁止事項</h2>
        <p>
          当社は、ユーザーが本サービスを利用するにあたり、以下の行為を行うことを禁止します。ユーザーがこの禁止事項に違反した場合、当社は、本サービスの利用をお断りする等、当社が必要と判断する措置をとることができるものとします。
        </p>
        <ol className="mb-4 list-decimal pl-[1.2em]">
          <li>法令に違反し、または公序良俗もしくは本規約に違反する行為。</li>
          <li>
            犯罪行為もしくは犯罪に結びつく行為、またはその恐れのある行為。またはこれを助長する行為。
          </li>
          <li>弊社または第三者の知的財産権を侵害する行為。</li>
          <li>
            本サービスまたは本サービスの内容を改変、破壊、逆アセンブル、逆コンパイル、リバースエンジニアリングする行為。
          </li>
          <li>
            不正の手段により本サービスのコンテンツを取得し、または再配布する行為。不正に再配布すること。
          </li>
          <li>
            当社もしくは第三者を不当に差別もしくは誹謗中傷し、またはその名誉もしくは信用を毀損する行為。当社もしくは本サービスの名誉もしくは信用を毀損する行為。
          </li>
          <li>
            第三者もしくは当社の財産もしくはプライバシー等を侵害する行為、または侵害する恐れのある行為。
          </li>
          <li>当社または第三者に経済的損害を与える行為。</li>
          <li>当社または第三者を脅迫する行為。</li>
          <li>本サービスの運営を妨害する行為。</li>
          <li>
            本NFTの購入が禁止されている国または地域の国民、居住者または旅行者が、本NFTを購入する行為。
          </li>
          <li>
            法令等により本NFTの購入が禁止、制限されている国または地域の国民、居住者または訪問者が、本NFTを購入する行為。
          </li>
          <li>
            コンピュータウィルス等有害なプログラムを使用し、またはその使用を推奨する行為。
          </li>
          <li>本サービス用設備に過度の負担をかけること。</li>
          <li>当サイトのサーバー、システム、セキュリティ等を攻撃する行為。</li>
          <li>
            当社が提供するインターフェイス以外のインターフェイスを使用して本サービスにアクセスしようとすること。
          </li>
          <li>本サービスにより知り得た情報を漏洩する行為。</li>
          <li>その他、当社が不適切と判断する行為。</li>
        </ol>
        <h2>反社会的勢⼒の排除</h2>
        <p>
          ユーザーは、当社に対し、自己（利用者が法人である場合にはその法人の役員等を含みます。）が、反社会的勢力等に該当しないこと、及び次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約します。
        </p>
        <ol className="mb-4 list-decimal pl-[1.2em]">
          <li>
            反社会的勢力等が経営を支配していると認められる関係を有すること
          </li>
          <li>
            反社会的勢力等が経営に実質的に関与していると認められる関係を有すること
          </li>
          <li>
            自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に反社会的勢力等を利用していると認められる関係を有すること
          </li>
          <li>
            反社会的勢力等に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること
          </li>
          <li>
            利用者が法人である場合には、役員又は経営に実質的に関与している者が反社会的勢力等と社会的に非難されるべき関係を有すること
          </li>
        </ol>
        <p>
          ユーザーは、当社に対し、自ら又は第三者を利用して次の各号に該当する行為を行わないことを確約します。
        </p>
        <ol className="mb-4 list-decimal pl-[1.2em]">
          <li>暴力的な要求行為</li>
          <li>法的な責任を超えた不当な要求行為</li>
          <li>取引に関して、脅迫的な言動をし、又は暴力を用いる行為</li>
          <li>
            風説を流布し、偽計を用い又は威力を用いて当社の信用を毀損し、又は当社の業務を妨害する行為
          </li>
          <li>その他前各号に準ずる行為</li>
        </ol>
        <h2>ブロックチェーン技術特有のリスク</h2>
        <p>
          現在、NFTに関連する明確な税務上の枠組みはありません。お客様は、当社サービスを利用する際の取引に適用される可能性のある税金を決定し、支払うことについて、単独で責任を負うものとします。
        </p>
        <ol className="mb-4 list-decimal pl-[1.2em]">
          <li>
            ブロックチェーン技術に関する規制体制は依然として不透明であり、近い将来ブロックチェーン技術を規制する新たな法律や規制が施行される可能性があるため、当社サービスの利用やNFTの価格または有用性に悪影響を及ぼす可能性があります。
          </li>
          <li>
            ユーザーは、NFTの売買、譲渡その他の利用もしくは他の利用者との交流の前に、十分な調査を行わなければなりません。
          </li>
          <li>
            ピアツーピアの取引には、物品の偽造、他者の権利侵害、メタデータの破損、バグの可能性があるスマートコントラクトを介した譲渡、譲渡不可能となる可能性など、物品の売買に関連するリスクがあります。当社は、お客様が当社のサービスを利用して購入するアイテムが、上記の問題またはその他の不利な問題の対象とならないことを保証しません。
          </li>
          <li>
            当社は、お客様がこれらのパブリックブロックチェーン上で取引を完了するために使用する必要がある特定のスマートコントラクトおよびプロトコルを管理していません。ブロックチェーン取引は本質的に不可逆であり、当社はブロックチェーン上の取引を取り消すことはできません。ブロックチェーン取引は本質的に不可逆的であり、当社はブロックチェーン上のいかなる取引も取り消すことはできません。これはまた、お客様が契約を受け入れ、送金が完了した後は、返品、キャンセル、交換の選択肢がないことを意味します。インターネットとブロックチェーン技術、またはブロックチェーンベースの製品を使用することにはリスクがあります。
          </li>
          <li>
            当社は、悪意のあるソフトウェアが侵入した場合、または無許可の第三者がお客様のアカウントまたはウォレットにアクセスした場合、ハードウェア、ソフトウェア、またはインターネット接続に関連するいかなる問題に対しても責任を負いません。
          </li>
          <li>
            当社サービスは、第三者のプラットフォームおよびサプライヤーに依存する場合があります。これらの第三者のプラットフォームおよびサプライヤーに関する問題は、お客様の本サービスの利用に影響を与える可能性があります。
          </li>
        </ol>
        <h2>免責事項</h2>
        <p>
          当社は、明示または黙示を問わず、本サービスが利用者の特定の目的に適合すること、本サービスが期待される機能、商品的価値、正確性、有用性を有すること、NFT自体の価値、安定性、適法性、利用者による本サービスの利用が利用者に適用される法令または業界団体の内部規則等に適合すること、本サービスが継続して利用できること、不具合が生じないこと等について、一切保証しないものとします。
        </p>
        <p>
          当社は、当社の故意または重過失によらない限り、本サービスに関する利用者に対する責任を免れるものとします。
        </p>
        <p>
          当社は、本サービス（本サービスを通じて作成されたNFTの本サービス外での展示、交換、売買等を含みますが、これらに限られません）に関連して、ユーザーと他のユーザーその他の第三者との間で生じた損害、紛争等について、一切責任を負いません。
        </p>
        <h2>本サービスの変更・終了</h2>
        <p>
          当社は本サービスの全部または一部を、ユーザーに対して事前に通知することなく随時変更することができるものとします。
          <br />
          当社はユーザーに対して本サービスの全部または一部の提供を事前に当社所定の方法により告知の上、終了または一時的に中断することができるものとします。ただし、やむを得ない場合または緊急の場合には、事前に告知をせず、終了または中断することができるものとします。
          <br />
          当社は、本サービスの変更等によりユーザーに生じたいかなる損害等についても、一切責任を負うものではありません。
        </p>
        <h2>準拠法・裁判管轄</h2>
        <p>
          本規約等は日本法に基づき解釈されるものとし、本規約等に関し訴訟の必要が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とする。
        </p>
        <h2>本規約の変更</h2>
        <p>
          当社は、本規約を改定する場合、その影響及び本サービスの運営状況などに照らし、当社ウェブサイトへの掲載その他当社所定の方法により利用者に告知するものとし、改定後の規約は、当社所定の一定の予告期間が経過したとき、又は利用者が当該告知後に本サービスを利用したときのいずれか早い時期に、その効力を生じるものとする。
        </p>
        <p>
          お問い合わせ方法
          <br />
          お客様の個人情報の使用に関してご質問やご不明な点がございましたら、下記までご連絡ください：
        </p>
        <a href="https://lin.ee/3iPsqHs" className="text-sky-500">
          ＜お問い合わせ窓口＞
        </a>
      </div>
    </LayoutWrapper>
  )
}
