import React from "react"
import { AsyncState } from "react-use/lib/useAsyncFn"
import { BaseResponseGetCollectionsDto } from "@/core/api"
import { SortByEnum } from "@/core/enum/SortByEnum"

type OverviewContextType = {
  sequentialPage: number
  setSequentialPage: React.Dispatch<React.SetStateAction<number>>
  collectPage: number
  setCollectPage: React.Dispatch<React.SetStateAction<number>>
  rawCollectData: AsyncState<BaseResponseGetCollectionsDto>
  rawSequentialData: AsyncState<BaseResponseGetCollectionsDto>
  pageSize: number
  collectSortBy?: string
  active?: string
}

export const OverviewContext = React.createContext<OverviewContextType>({
  sequentialPage: 0,
  setSequentialPage: () => null,
  collectPage: 0,
  setCollectPage: () => null,
  rawCollectData: { loading: false, value: undefined, error: undefined },
  rawSequentialData: { loading: false, value: undefined, error: undefined },
  pageSize: 0,
  collectSortBy: SortByEnum.NEWEST,
  active: "all",
})
