import { SvgIcon, SvgIconTypeMap } from "@mui/material"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import React from "react"
import { useTranslation } from "react-i18next"

export type SectionComponentProps = {
  title: string
  icon: OverridableComponent<SvgIconTypeMap>
  children: JSX.Element
  className?: string
}
export const SectionComponent: React.FunctionComponent<
  SectionComponentProps
> = ({ title, icon, children, className }) => {
  const { t } = useTranslation()

  return (
    <div
      className={`overflow-hidden rounded-2xl border border-[#D1D1D1] ${className}`}
    >
      <div className="flex items-center bg-[#f3f3f3] px-6 py-3 font-bold">
        <SvgIcon component={icon} fontSize="small" sx={{ marginRight: 1 }} />
        {t(title)}
      </div>
      {children}
    </div>
  )
}
