import { Alert, Slide, Snackbar } from "@mui/material"
import React from "react"
import AlertContainer from "@/core/store/AlertContainer"
import CheckIcon from "@mui/icons-material/Check"

export const AlertMessage: React.FunctionComponent = () => {
  const {
    state: { display, severity, message, icon },
  } = AlertContainer.useContainer()

  return (
    <Snackbar
      open={display}
      autoHideDuration={9999999999}
      TransitionComponent={props =>
        severity === "success" ? (
          <Slide {...props} direction="left" />
        ) : (
          <Slide {...props} direction="down" />
        )
      }
      anchorOrigin={
        severity === "success"
          ? { vertical: "bottom", horizontal: "right" }
          : { vertical: "top", horizontal: "center" }
      }
      sx={{ "& .MuiPaper-root": { color: "white" } }}
      onClose={() => null}
    >
      <Alert
        icon={icon}
        iconMapping={{
          success: <CheckIcon fontSize="inherit" />,
        }}
        variant="filled"
        severity={severity}
        elevation={6}
      >
        <span className="whitespace-pre-line">{message}</span>
      </Alert>
    </Snackbar>
  )
}
