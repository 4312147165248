import { TFunction } from "react-i18next"
import * as yup from "yup"
import { isValid } from "date-fns"
import { TimePeriodEnum } from "@/core/enum/TimePeriodEnum"

export function getPinNFTFormSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    dateRange: yup
      .array()
      .of(
        yup
          .string()
          .required(t("str_please_fill_out_this_field"))
          .typeError(t("str_please_fill_out_this_field"))
      )
      .min(2, t("str_please_fill_out_this_field"))
      .test(
        "required",
        t("str_please_fill_out_this_field"),
        value => !!(value && value[0] && value[1])
      )
      .test("typeError", t("str_date_format_error"), value => {
        if (value && value[0] && value[1]) {
          const from = isValid(new Date(value[0]))
          const to = isValid(new Date(value[1]))
          return from && to
        }
        return false
      }),
    address: yup.string().required(t("str_please_fill_out_this_field")),
    lat: yup
      .number()
      .test(
        "set-location",
        t("str_please_fill_out_this_field"),
        value => value !== 0
      )
      .required(t("str_please_fill_out_this_field")),
    lng: yup
      .number()
      .test(
        "set-location",
        t("str_please_fill_out_this_field"),
        value => value !== 0
      )
      .required(t("str_please_fill_out_this_field")),
    distance: yup
      .number()
      .nullable()
      .typeError(t("str_please_enter_digit"))
      .required(t("str_please_fill_out_this_field"))
      .test(
        "distance",
        t("str_distance_min_valid"),
        value => !(value && value > 0 && value < 100)
      )
      .min(0, t("str_input_positive_integer"))
      .max(3000, t("str_distance_max_valid")),
    timePeroid: yup.string(),
    weeks: yup.array().when("timePeroid", {
      is: (timePeroid: TimePeriodEnum) =>
        timePeroid === TimePeriodEnum.CUSTOM_TIME_PEROID,
      then: yup.array().min(1, t("str_please_select_custom_time_period")),
    }),
    slogan: yup
      .string()
      .test(
        "slogan",
        t("str_please_enter_four_to_sixteen"),
        value =>
          value === undefined ||
          value === "" ||
          (value.length >= 4 && value.length <= 16)
      ),
  })
}
