import { isMobileOrTablet } from "@/core/utils/isMobileOrTablet"

type DeviceDetectType = {
  isIOS: boolean
  isAndroid: boolean
  isMobile: boolean
  isDiverApp: boolean
}

export function useDeviceDetect(): DeviceDetectType {
  return {
    isIOS: Boolean(navigator.userAgent.match(/iPhone|iPad|iPod/i)),
    isAndroid: Boolean(
      navigator.userAgent.match(
        /Android|webOS|BlackBerry|IEMobile|Opera Mini|Mobile|Tablet/i
      )
    ),
    isMobile: isMobileOrTablet(navigator.userAgent),
    isDiverApp: localStorage.getItem("is_diver_wallet") === "true",
  }
}
