import React, { useEffect } from "react"
import { Box, Pagination } from "@mui/material"
import { BaseResponseGetNFTsDto, CollectionItemDto } from "@/core/api"
import { AsyncState } from "react-use/lib/useAsyncFn"
import { LoadingWrapper } from "@/components/common/loading/LoadingWrapper"
import { NFTCard } from "@/components/collection/NFTCard"
import { useTranslation } from "react-i18next"
import { CommonButton } from "@/components/common/CommonButton"
import CheckIcon from "@mui/icons-material/Check"
import { accountFilterMenus } from "@/core/constant/menu"
import useCheckHeight from "@/core/hook/useCheckHeight"
import NoDataComponent from "../common/NoDataComponent"

export type CollectionContainerProps = {
  collectionData: CollectionItemDto | undefined
  rawNFTData: AsyncState<BaseResponseGetNFTsDto>
  page: number
  setPage: (page: number) => void
  collectionNFTPageSize: number
  active: string
  setActive: (active: string) => void
}

export const CollectionContainer: React.FunctionComponent<
  CollectionContainerProps
> = ({ collectionData, rawNFTData, page, setPage, active, setActive }) => {
  const { t } = useTranslation()
  const {
    isExpanded,
    setIsExpanded,
    showMoreButton,
    setShowMoreButton,
    textRef,
  } = useCheckHeight()

  useEffect(() => {
    const checkHeight = () => {
      const element = textRef.current
      if (element) {
        const lineHeight = 24
        setShowMoreButton(element.scrollHeight > lineHeight)
      }
    }
    checkHeight()

    window.addEventListener("resize", checkHeight)
    return () => {
      window.removeEventListener("resize", checkHeight)
    }
  }, [])

  useEffect(() => {
    scrollTo(0, 0)
  }, [page])

  return (
    <>
      <Box
        className="aspect-[4/1] h-fit w-full"
        sx={{
          backgroundImage: `url(${collectionData?.banner_img_url})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: "400px",
        }}
      >
        <div className="flex h-full w-full flex-col items-center justify-end bg-gradient-to-t from-[#00000066]">
          <div className="mx-auto flex w-full max-w-[1000px] gap-x-7 px-4 py-6">
            <img
              src={collectionData?.logo_img_url}
              className="aspect-square h-20 w-20 rounded-full object-cover sm:h-[90px] sm:w-[90px]"
              alt="avatar"
            />
            <div className="w-full">
              <div className="mb-4 line-clamp-2 break-all text-2xl font-bold text-white sm:text-3xl">
                {collectionData?.name}
              </div>
              <div
                ref={textRef}
                className={`scrollbar-thumb-rounded-[10px] whitespace-pre-wrap leading-6 text-white scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#ffffff80] ${
                  !isExpanded ? "line-clamp-1" : "overflow-y-auto"
                } max-h-24 break-all`}
              >
                {collectionData?.description}
                {isExpanded && (
                  <span
                    className="cursor-pointer font-bold underline transition-opacity hover:opacity-80"
                    onClick={() => {
                      setIsExpanded(false)
                      if (textRef.current) {
                        textRef.current.scrollTop = 0
                      }
                    }}
                  >
                    <br />
                    {t("less")}
                  </span>
                )}
              </div>
              {showMoreButton && !isExpanded && (
                <span
                  className="cursor-pointer font-bold text-white underline transition-opacity hover:opacity-80"
                  onClick={() => setIsExpanded(true)}
                >
                  {t("more")}
                </span>
              )}
            </div>
          </div>
        </div>
      </Box>
      <div className="w-full bg-white pb-20 pt-16">
        <div className="px-8">
          <div className="flex flex-col justify-between border-b border-black pb-4 md:flex-row md:items-center">
            <div className="flex items-center gap-x-2">
              {accountFilterMenus.map(item => (
                <CommonButton
                  key={item}
                  variant="outlined"
                  onClick={() => setActive(item)}
                  sx={{
                    color: "#000",
                    borderColor: "#000",
                    borderRadius: 1,
                    padding: "6px 12px",
                    textWrap: "nowrap",
                    "&:hover": {
                      borderColor: "#000",
                      backgroundColor: "#00000009",
                    },
                    "&.MuiButton-root": {
                      borderColor: active === item ? "#000" : "#D1D1D1",
                      color: active === item ? "#000" : "#D1D1D1",
                    },
                  }}
                >
                  {item === active && (
                    <CheckIcon
                      fontSize="small"
                      sx={{
                        marginRight: 1,
                      }}
                    />
                  )}
                  {t(item)}
                </CommonButton>
              ))}
            </div>
            <div className="justify-start pt-5 font-medium leading-5 md:pt-0">
              {t("str_items_quantity", {
                quantity: rawNFTData.value?.result.total_item_size,
              })}
            </div>
          </div>
        </div>
        <LoadingWrapper
          state={rawNFTData}
          render={pinNFTData =>
            Number(pinNFTData.result?.item_list?.length) > 0 ? (
              <div className="grid gap-4 px-8 pt-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {pinNFTData.result?.item_list?.map(pinNFT => (
                  <NFTCard
                    nft={pinNFT.nft}
                    pin={pinNFT.pin}
                    key={pinNFT.nft._id}
                  />
                ))}
              </div>
            ) : (
              <NoDataComponent active={active} />
            )
          }
        />
        {rawNFTData.value &&
          Number(rawNFTData.value.result.total_item_size) > 0 && (
            <div className="flex justify-center py-10">
              <Pagination
                page={page}
                onChange={(_, page) => setPage(page)}
                count={rawNFTData.value.result?.total_page}
                variant="outlined"
                shape="rounded"
              />
            </div>
          )}
      </div>
    </>
  )
}
