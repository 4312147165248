import React, { useContext, useEffect } from "react"
import { LoadingWrapper } from "@/components/common/loading/LoadingWrapper"
import CollectionCard from "@/components/common/CollectionCard"
import { OverviewContext } from "@/core/store/OverviewContext"
import { Pagination } from "@mui/material"
import NoDataComponent from "@/components/common/NoDataComponent"

export default function SequentialContent() {
  const { rawSequentialData, setSequentialPage, active } =
    useContext(OverviewContext)

  useEffect(() => {
    scrollTo(0, 0)
  }, [rawSequentialData])

  return (
    <>
      <LoadingWrapper
        state={rawSequentialData}
        render={collectData => (
          <>
            {collectData.result.total_item_size === 0 ? (
              <NoDataComponent active={active} />
            ) : (
              <>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {collectData.result?.item_list?.map(item => (
                    <CollectionCard key={item.collectionID} collection={item} />
                  ))}
                </div>
                <div className="my-16 flex justify-center">
                  <Pagination
                    page={collectData.result.page}
                    onChange={(_, page) => setSequentialPage(page)}
                    count={collectData.result.total_page}
                    variant="outlined"
                    shape="rounded"
                  />
                </div>
              </>
            )}
          </>
        )}
      />
    </>
  )
}
