import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Autocomplete, GoogleMap, LoadScript } from "@react-google-maps/api"
import { Box, FormHelperText } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { getPinNFTFormSchema } from "@/core/constant/formSchema"
import { PinSetLocationFormRequest } from "@/core/types/Request"
import { initialPinSetLocationForm } from "@/core/constant/initial"
import { CommonDateRangePicker } from "@/components/common/CommonDateRangePicker"
import { CustomTextField } from "@/components/common/form/widget/CustomTextField"
import { NFTItemDto } from "@/core/api"
import { Library } from "@googlemaps/js-api-loader/src"
import { useAccount } from "wagmi"
import {
  getUnixTime,
  startOfDay,
  endOfDay,
  eachWeekOfInterval,
  format,
  isAfter,
  isEqual,
  fromUnixTime,
  eachDayOfInterval,
  add,
  isBefore,
  sub,
  startOfToday,
} from "date-fns"
import {
  getRangeOptions,
  getTimePeriodOptions,
  getWeekOptions,
} from "@/core/constant/options"
import { TimePeriodEnum } from "@/core/enum/TimePeriodEnum"
import { CommonTimeRangePicker } from "@/components/common/CommonTimeRangePicker"
import AddBoxIcon from "@mui/icons-material/AddBox"
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox"
import { CommonCheckbox } from "@/components/common/form/CommonCheckbox"
import { CommonTextField } from "@/components/common/form/CommonTextField"
import { CommonRadioGroup } from "@/components/common/form/CommonRadioGroup"
import { WeekEnum } from "@/core/enum/WeekEnum"
import { CommonButton } from "@/components/common/CommonButton"
import { DateRangeEnum } from "@/core/enum/DateRangeEnum"
import { PinNFTReviewRequest } from "@/core/types/Request"
import { scrollToError } from "@/core/utils/scrollToError"

type Props = {
  next: () => void
  selectedNFT: NFTItemDto | undefined
  formState: PinNFTReviewRequest | undefined
  setFormState: React.Dispatch<
    React.SetStateAction<PinNFTReviewRequest | undefined>
  >
}
const libraries: Array<Library> = ["places"]

export default function PinForm({
  next,
  selectedNFT,
  formState,
  setFormState,
}: Props) {
  const { t, i18n } = useTranslation()
  const [location, setLocation] = useState("")
  const [loading, setLoading] = useState(false)
  const { address } = useAccount()
  const [defaultDateRange, setDefaultDateRange] = useState<Date[]>([])

  const {
    watch,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
    trigger,
  } = useForm<PinSetLocationFormRequest>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(getPinNFTFormSchema(t)),
    defaultValues: initialPinSetLocationForm,
  })

  //若從 review page 返回，則將 formState 填入表單
  useEffect(() => {
    if (formState) {
      setLocation(formState.geo.address)
      setValue("distance", formState.geo.distance)
      setValue("lat", formState.geo.lat)
      setValue("lng", formState.geo.lon)
      setValue("address", formState.geo.address)
      setValue("slogan", formState.slogan)
      setValue("timePeroid", formState.timePeroid as TimePeriodEnum)
      setValue("timeRange", formState.timeRange)
      setValue("dateRange", [
        fromUnixTime(formState.start_date),
        fromUnixTime(formState.end_date),
      ])
      setValue("fixedTimeArray", formState.fixedTimeArray)
      setValue("weeks", formState.weeks)
      setValue("mon", formState.mon)
      setValue("tue", formState.tue)
      setValue("wed", formState.wed)
      setValue("thu", formState.thu)
      setValue("fri", formState.fri)
      setValue("sat", formState.sat)
      setValue("sun", formState.sun)
    }
  }, [formState, setValue])

  useEffect(() => {
    const properties = selectedNFT?.metadata.version
      ? selectedNFT?.metadata.sections?.[0].elements
      : selectedNFT?.metadata.properties
    const startDate = Number(
      properties?.find(propertie => propertie.title === "startDate")?.content
    )
    const endDate = Number(
      properties?.find(propertie => propertie.title === "endDate")?.content
    )

    if (startDate && endDate && startDate != -1 && endDate != -1) {
      const startDateToDate = fromUnixTime(startDate)
      const endDateToDate = fromUnixTime(endDate)
      setDefaultDateRange([startDateToDate, endDateToDate])
    }
  }, [selectedNFT])

  const onPlaceLoad = (autocomplete: google.maps.places.Autocomplete) => {
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace()
      place.formatted_address && setLocation(place.formatted_address)

      if (place.geometry && place.geometry.location) {
        setValue("lat", place.geometry.location.lat())
        setValue("lng", place.geometry.location.lng())
        setValue("address", place.formatted_address || "")
        clearErrors(["lat", "lng", "address"])
      }
    })
  }

  useEffect(() => {
    if (location === formState?.geo.address) {
      setLocation(formState?.geo.address)
      setValue("lat", formState?.geo.lat)
      setValue("lng", formState?.geo.lon)
      setValue("address", formState?.geo.address)
    }
    if (watch().lat && watch().lng && location !== watch().address) {
      setValue("lat", 0)
      setValue("lng", 0)
      setValue("address", "")
    }
  }, [location])

  const getWeekTime = (dateRange: Array<string>, week: WeekEnum) => {
    const returnWeekNum = (week: WeekEnum) => {
      const weekObj = {
        [WeekEnum.MONDAY]: 1,
        [WeekEnum.TUESDAY]: 2,
        [WeekEnum.WEDNESDAY]: 3,
        [WeekEnum.THURSDAY]: 4,
        [WeekEnum.FRIDAY]: 5,
        [WeekEnum.SATURDAY]: 6,
        [WeekEnum.SUNDAY]: 0,
      }

      return weekObj[week]
    }
    const start = new Date(dateRange[0] as string)
    const end = new Date(dateRange[1] as string)
    const weekStartsOn = returnWeekNum(week) as 0 | 1 | 2 | 3 | 4 | 5 | 6
    const dates = eachWeekOfInterval(
      {
        start,
        end,
      },
      { weekStartsOn: weekStartsOn }
    )
    return dates.filter(
      date =>
        isAfter(date, new Date(dateRange[0])) ||
        isEqual(
          new Date(date.getFullYear(), date.getMonth(), date.getDate()),
          new Date(start.getFullYear(), start.getMonth(), start.getDate())
        )
    )
  }
  const formatFromToTime = (
    start_date: string | Date,
    end_date: string | Date,
    start_time: Date,
    end_time: Date
  ) => {
    const from = getUnixTime(
      new Date(
        `${format(new Date(start_date), "yyyy-MM-dd")} ${format(
          start_time,
          "HH:mm"
        )}`
      )
    )

    const to = getUnixTime(
      new Date(
        `${format(new Date(end_date), "yyyy-MM-dd")} ${format(
          end_time,
          "HH:mm"
        )}`
      )
    )

    return { from, to }
  }
  const handleReview = async (form: PinSetLocationFormRequest) => {
    const available_time: { from: number; to: number }[] = []
    const start_date = form.dateRange[0] as string
    const end_date = form.dateRange[1] as string
    switch (form.timePeroid) {
      case TimePeriodEnum.NO_TIME_LIMIT: {
        available_time.push(
          formatFromToTime(
            start_date,
            end_date,
            startOfDay(new Date(start_date)),
            endOfDay(new Date(end_date))
          )
        )
        break
      }

      case TimePeriodEnum.FIXED_TIME_PEROID: {
        const dates = eachDayOfInterval({
          start: new Date(start_date),
          end: new Date(end_date),
        })
        for (const timeArray of form.fixedTimeArray) {
          for (const date of dates) {
            available_time.push(
              formatFromToTime(date, date, timeArray[0], timeArray[1])
            )
          }
        }
        break
      }
      case TimePeriodEnum.CUSTOM_TIME_PEROID: {
        for (const week of form.weeks) {
          const weekTimeList = getWeekTime(
            form.dateRange as Array<string>,
            week
          )

          for (const weekTime of weekTimeList) {
            for (const timeArray of form[
              week.toLocaleLowerCase() as
                | "mon"
                | "tue"
                | "wed"
                | "thu"
                | "fri"
                | "sat"
                | "sun"
            ]) {
              available_time.push(
                formatFromToTime(weekTime, weekTime, timeArray[0], timeArray[1])
              )
            }
          }
        }
        break
      }
    }
    if (selectedNFT && !loading) {
      try {
        setLoading(true)
        const request = {
          nftID: selectedNFT._id,
          start_date: form.dateRange[0]
            ? getUnixTime(startOfDay(new Date(form.dateRange[0])))
            : getUnixTime(startOfDay(new Date())),
          end_date: form.dateRange[1]
            ? getUnixTime(endOfDay(new Date(form.dateRange[1])))
            : getUnixTime(endOfDay(new Date())),
          geo: {
            lat: form.lat,
            lon: form.lng,
            distance: form.distance || 0,
            address: form.address,
          },
          user_address: address || "",
          available_time,
          timePeroid: form.timePeroid,
          timeRange: form.timeRange,
          slogan: form.slogan,
          fixedTimeArray: form.fixedTimeArray,
          weeks: form.weeks,
          mon: form.mon,
          tue: form.tue,
          wed: form.wed,
          thu: form.thu,
          fri: form.fri,
          sat: form.sat,
          sun: form.sun,
        }
        setFormState(request)
        next()
      } catch (err) {
        setLoading(false)
        console.error(err)
      }
    }
  }
  const setFixedTimeArray = (action: "add" | "remove") => {
    if (action === "add") {
      setValue("fixedTimeArray", [
        ...watch("fixedTimeArray"),
        [startOfDay(new Date()), endOfDay(new Date())],
      ])
    } else {
      const newTimeArray = watch("fixedTimeArray").slice(
        0,
        watch("fixedTimeArray").length - 1
      )
      setValue("fixedTimeArray", newTimeArray)
    }
  }

  const setWeekTimeArray = (
    week: "mon" | "tue" | "wed" | "fri" | "sat" | "sun",
    action: "add" | "remove"
  ) => {
    if (action === "add") {
      setValue(week, [
        ...watch(week),
        [startOfDay(new Date()), endOfDay(new Date())],
      ])
    } else {
      const newTimeArray = watch(week).slice(0, watch(week).length - 1)
      setValue(week, newTimeArray)
    }
  }
  const renderWeekTime = () => {
    return getWeekOptions(t).map(item => {
      const weekKey = item.value?.toLocaleLowerCase() as
        | "mon"
        | "tue"
        | "wed"
        | "fri"
        | "sat"
        | "sun"
      const timeArray = watch(weekKey)
      return {
        ...item,
        selectedRender: (
          <div className="ms-6 flex">
            <div className=" flex flex-col md:flex-row">
              {timeArray?.map((_: any, index: number) => (
                <div
                  key={index}
                  className={`my-2 md:my-4  ${
                    index + 1 !== timeArray.length && timeArray.length > 1
                      ? "border-e-0 border-gray-300 pe-0 md:me-3.5 md:border-e-2 md:pe-3.5"
                      : " "
                  }`}
                >
                  <CommonTimeRangePicker
                    control={control}
                    name={weekKey}
                    index={index}
                    errors={errors}
                  ></CommonTimeRangePicker>
                </div>
              ))}
            </div>
            <Box className="ms-1 flex flex-col justify-center text-[24px] text-black">
              <AddBoxIcon
                onClick={() => setWeekTimeArray(weekKey, "add")}
                className={`cursor-pointer ${
                  timeArray?.length < 3 ? "" : "!hidden"
                }`}
              />
              <IndeterminateCheckBoxIcon
                onClick={() => setWeekTimeArray(weekKey, "remove")}
                className={`cursor-pointer ${
                  timeArray?.length > 1 ? "" : "!hidden"
                }`}
              />
            </Box>
          </div>
        ),
      }
    })
  }

  const renderRangePicker = (timeRange: DateRangeEnum) => {
    if (timeRange === DateRangeEnum.THREE_MONTHS) {
      return (
        <CommonDateRangePicker
          disabled={watch("timeRange") === "noLimit"}
          control={control}
          errors={errors}
          name="dateRange"
          {...(dateRangeMaxDate && {
            maxDate: dateRangeMaxDate,
          })}
        />
      )
    }
  }

  const renderTimePeroid = (timePeroid: TimePeriodEnum) => {
    switch (timePeroid) {
      case TimePeriodEnum.FIXED_TIME_PEROID: {
        const timeArray = watch("fixedTimeArray")
        return (
          <div className="flex">
            <div className="my-3 flex flex-col md:flex-row">
              {timeArray.map((_: any, index: number) => (
                <div
                  key={index}
                  className={`mb-2 md:mb-0 ${
                    index + 1 !== timeArray.length && timeArray.length > 1
                      ? "border-e-0 border-gray-300 pe-0 md:me-3.5 md:border-e-2 md:pe-3.5"
                      : " "
                  }`}
                >
                  <CommonTimeRangePicker
                    control={control}
                    name="fixedTimeArray"
                    index={index}
                    errors={errors}
                  ></CommonTimeRangePicker>
                </div>
              ))}
            </div>
            <Box className="ms-1 flex flex-col justify-center text-[24px] text-black">
              <AddBoxIcon
                onClick={() => setFixedTimeArray("add")}
                className={`cursor-pointer ${
                  timeArray.length < 3 ? "" : "!hidden"
                }`}
              />
              <IndeterminateCheckBoxIcon
                onClick={() => setFixedTimeArray("remove")}
                className={`cursor-pointer ${
                  timeArray.length > 1 ? "" : "!hidden"
                }`}
              />
            </Box>
          </div>
        )
      }

      case TimePeriodEnum.CUSTOM_TIME_PEROID:
        return (
          <div className="ms-5 flex">
            <div className="flex flex-col md:flex-row">
              <CommonCheckbox
                name="weeks"
                control={control}
                checkboxOptions={renderWeekTime()}
                errors={errors}
              />
            </div>
          </div>
        )
    }
  }

  const isExpired = useMemo(() => {
    return (
      defaultDateRange.length > 0 &&
      isBefore(defaultDateRange[1], startOfToday())
    )
  }, [defaultDateRange])

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === "timePeroid") {
        setValue("fixedTimeArray", [
          [startOfDay(new Date()), endOfDay(new Date())],
        ])
        clearErrors(["fixedTimeArray", "weeks"])
        setValue("weeks", [])
        for (const week of getWeekOptions(t)) {
          const value = week.value?.toLocaleLowerCase() as
            | "mon"
            | "tue"
            | "wed"
            | "thu"
            | "fri"
            | "sat"
            | "sun"
          setValue(value, [[startOfDay(new Date()), endOfDay(new Date())]])
          clearErrors([value])
        }
      } else if (name === "dateRange") {
        trigger(["dateRange"])
        if (!values.dateRange || defaultDateRange.length !== 0) return
        const [startDate, endDate] = values.dateRange
        if (
          startDate &&
          endDate &&
          isAfter(new Date(endDate), defaultDateRange[1])
        ) {
          setValue("dateRange", [startDate, null])
        }
      } else if (name === "timeRange") {
        if (
          values.timeRange !== "noLimit" &&
          values.timeRange !== "threeMonths"
        )
          return
        if (values.timeRange === "noLimit") {
          const dateRangeValue =
            isExpired && defaultDateRange.length > 0
              ? [defaultDateRange[0], fromUnixTime(4102415999)]
              : [startOfToday(), fromUnixTime(4102415999)]
          setValue("dateRange", dateRangeValue)
          setValue("timePeroid", TimePeriodEnum.NO_TIME_LIMIT)
          return
        }
        if (values.timeRange === "threeMonths") {
          setValue("dateRange", [null, null])
          return
        }
      }
    })

    return () => subscription.unsubscribe()
  }, [watch, setValue])

  // 載入完成後，若為無限制，則設定終止日為 2099/12/31 23:59:59
  // 若在 TRACOU 有預設日期，且無過期起始日為今天，反之過期的話起始日是 NFT 的開始日
  useEffect(() => {
    if (watch("timeRange") !== "noLimit") return
    setValue("timePeroid", TimePeriodEnum.NO_TIME_LIMIT)

    const startDate =
      defaultDateRange.length > 0 && isExpired
        ? defaultDateRange[0]
        : startOfToday()
    const endDate = fromUnixTime(4102415999)
    setValue("dateRange", [startDate, endDate])
  }, [selectedNFT, watch, defaultDateRange, isExpired])

  const dateRangeMaxDate = useMemo(() => {
    const [startDate] = watch("dateRange")
    const maxDate = defaultDateRange.length > 0 ? defaultDateRange[1] : null
    if (startDate) {
      let result = add(new Date(startDate), {
        months: 3,
      })
      result = sub(result, {
        days: 1,
      })
      return maxDate ? (isBefore(maxDate, result) ? maxDate : result) : result
    }
    return maxDate
  }, [watch("dateRange"), defaultDateRange])

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToError()
    }
  }, [errors])

  return (
    <>
      <Box className="min-h-[400px] border-b border-dashed border-black px-2 py-6 md:px-[50px] md:py-12">
        <div className="font-bold">
          <div className="form_required_suffix mb-2 text-2xl leading-6">
            {t("address")}
          </div>
          <div className="text-sm text-[#757575]">
            {t("str_location_prompt")}
          </div>
        </div>
        <LoadScript
          googleMapsApiKey={import.meta.env.VITE_GOOGLE_API_KEY}
          libraries={libraries}
          language={i18n.language}
        >
          <div className="mb-9 mt-4">
            <Autocomplete onLoad={onPlaceLoad}>
              <CustomTextField
                type="text"
                placeholder={t("str_location_placeholder")}
                value={location}
                onBlur={() => trigger(["lat", "lng"])}
                onChange={e => setLocation(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root > fieldset": {
                    borderColor: errors.lat || errors.lng ? "red" : "black",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: errors.lat || errors.lng ? "red" : "black",
                    },
                  },
                }}
              />
            </Autocomplete>
            {(errors.lat || errors.lng) && (
              <FormHelperText error sx={{ margin: "4px 14px" }}>
                {location
                  ? t("str_please_select_address_from_list")
                  : t("str_please_fill_out_this_field")}
              </FormHelperText>
            )}
          </div>
          <GoogleMap
            mapContainerStyle={{
              height: "400px",
              width: "100%",
            }}
            center={{
              lat: watch().lat || 35.6585805,
              lng: watch().lng || 139.7454329,
            }}
            clickableIcons={false}
            zoom={18}
          />
        </LoadScript>
      </Box>
      <div className="min-h-[200px] border-b border-dashed border-black px-2 py-6 md:px-[50px] md:py-12">
        <div className="font-bold">
          <div className="form_required_suffix mb-2 text-2xl leading-6">
            {t("range")}（{t("distance_receive_nft")}）
          </div>
          <div className="text-sm text-[#757575]">
            {t("distance_limit_description")}
          </div>
        </div>
        <CommonTextField
          type="tel"
          control={control}
          errors={errors}
          variant="outlined"
          name="distance"
          placeholder={t("str_distance_placeholder")}
          sx={{
            marginTop: 2,
            "& .MuiOutlinedInput-root.Mui-focused": {
              "& > fieldset": {
                borderColor: "black",
              },
            },
          }}
        />
      </div>
      <div className="min-h-[200px] border-b border-dashed border-black px-2 py-6 md:px-[50px] md:py-12">
        <div className="form_required_suffix mb-2 text-2xl font-bold leading-6">
          {t("start_day_end_day")}
        </div>
        <Box
          className={`mb-2 flex items-center text-sm font-medium text-red-500
         ${watch("timeRange") === "noLimit" ? "hidden" : ""}`}
        >
          <div className="mr-2 aspect-square w-[8px] rounded-full bg-red-500"></div>
          <span>{t("str_pin_range_three_month_desc")}</span>
        </Box>
        {defaultDateRange.length > 0 && (
          <Box
            className={`mb-4 flex items-center text-sm font-medium text-red-500 ${
              watch("timeRange") === "noLimit" ? "hidden" : ""
            }`}
          >
            <div className="mr-2 aspect-square w-[8px] rounded-full bg-red-500"></div>
            <span>
              {t("str_date_range_meta_required", {
                startYear: format(defaultDateRange[0], "yyyy"),
                startMonth: format(defaultDateRange[0], "MM"),
                startDate: format(defaultDateRange[0], "dd"),
                endYear: format(defaultDateRange[1], "yyyy"),
                endMonth: format(defaultDateRange[1], "MM"),
                endDate: format(defaultDateRange[1], "dd"),
              })}
            </span>
          </Box>
        )}
        <div className="mb-4">
          <CommonRadioGroup
            name="timeRange"
            radioOptions={getRangeOptions(t)}
            control={control}
            errors={errors}
            selectedRender={renderRangePicker(watch("timeRange"))}
          />
        </div>
      </div>
      <div className="min-h-[200px] border-b border-dashed border-black px-2 py-6 md:px-[50px] md:py-12">
        <div className="form_required_suffix mb-4 text-2xl font-bold leading-6">
          {t("time_period_title")}
        </div>
        <CommonRadioGroup
          name="timePeroid"
          disabled={watch("timeRange") === "noLimit"}
          radioOptions={getTimePeriodOptions(t)}
          control={control}
          errors={errors}
          selectedRender={renderTimePeroid(watch("timePeroid"))}
        />
      </div>
      <div className="min-h-[200px] px-2 py-6 md:px-[50px] md:py-12">
        <div className="font-bold">
          <div className="mb-2 text-2xl leading-6">{t("slogan")}</div>
          <div className="text-sm text-[#757575]">
            {t("str_slogan_description")}
          </div>
        </div>
        <CommonTextField
          type="text"
          control={control}
          errors={errors}
          variant="outlined"
          name="slogan"
          placeholder={t("str_slogan_placeholder")}
          sx={{
            marginTop: 2,
            "& .MuiOutlinedInput-root.Mui-focused": {
              "& > fieldset": {
                borderColor: "black",
              },
            },
          }}
        />
      </div>
      <div className="mb-16 mt-8 flex justify-center">
        <CommonButton
          variant="contained"
          sx={{
            width: "376px",
            borderRadius: 2,
            paddingY: 2,
            fontSize: "20px",
            backgroundColor: "#000",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#000000d9",
            },
          }}
          disabled={loading}
          onClick={handleSubmit(handleReview)}
        >
          {loading ? t("loading") : t("next")}
        </CommonButton>
      </div>
    </>
  )
}
