import React, { FC, PropsWithChildren } from "react"
import { Box, SxProps, Tab, Tabs } from "@mui/material"
import { useTranslation } from "react-i18next"

type Props = {
  options: Array<string>
  changeTab: (newValue: number) => void
  value: number
  sx: SxProps
}

const CommonTabs: FC<PropsWithChildren<Props>> = ({
  sx,
  options,
  value,
  changeTab,
  children,
}) => {
  const { t } = useTranslation()

  return (
    <Box
      className="flex flex-col items-center justify-between sm:flex-row"
      sx={{ borderBottom: 1, borderColor: "divider", ...sx }}
    >
      <Tabs
        value={value}
        TabIndicatorProps={{
          style: {
            backgroundColor: "black",
          },
        }}
        onChange={(_, tab) => changeTab(tab)}
      >
        {options.map(item => (
          <Tab
            key={item}
            label={t(item)}
            sx={{
              "&.Mui-selected": {
                color: "black",
                fontWeight: 700,
              },
            }}
          />
        ))}
      </Tabs>
      {children}
    </Box>
  )
}

export default CommonTabs
