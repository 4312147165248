import React, { FC, PropsWithChildren } from "react"
import { BaseResponseGetNFTItemsDto, NFTItemDto } from "@/core/api"
import { CommonRadio } from "./CommonRadio"

type Props = {
  selectedNFT: NFTItemDto | undefined
  pinData: BaseResponseGetNFTItemsDto
  onClick: () => void
}

const NFTSelectorCard: FC<PropsWithChildren<Props>> = ({
  selectedNFT,
  pinData,
  onClick,
}) => {
  const isSelected =
    pinData.nft.address === selectedNFT?.address &&
    pinData.nft.tokenID === selectedNFT?.tokenID

  return (
    <div
      className={`cursor-pointer rounded-2xl border-2 border-[#AEAEAE] px-4 py-3 transition-all hover:border-black ${
        isSelected ? "border-black" : ""
      }`}
      onClick={onClick}
    >
      <div className="relative aspect-square w-full rounded-md">
        <img
          src={pinData.nft.image}
          className="pointer-events-none aspect-square w-full rounded-t-lg object-cover"
          alt={pinData.nft.name}
        />
        <div className="absolute left-1 top-1">
          <CommonRadio checked={isSelected} />
        </div>
      </div>
      <div className="my-2 text-sm leading-4">
        <div className="mb-1 line-clamp-1 break-words ">
          {pinData.collection.name}
        </div>
        <div className="line-clamp-1 break-words font-semibold">
          {pinData.nft.name}
        </div>
      </div>
    </div>
  )
}

export default NFTSelectorCard
