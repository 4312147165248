import React, { useContext, useEffect, useState } from "react"
import { useTheme } from "@mui/material"
import CommonTabs from "@/components/common/CommonTabs"
import { CommonButton } from "@/components/common/CommonButton"
import SequentialContent from "./SequentialContent"
import CollectionContent from "./CollectionContent"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useAsync, useSearchParam } from "react-use"
import { AppContext } from "@/AppContext"
import { useAccount } from "wagmi"
import { SearchContext } from "@/core/store/SearchContext"
import { OverviewContext } from "@/core/store/OverviewContext"
import { accountMenus } from "@/core/constant/menu"
import { SortByEnum } from "@/core/enum/SortByEnum"
import { ContractTypeEnum } from "@/core/enum/ContractTypeEnum"
import { CommonSelect } from "@/components/common/CommonSelect"
import { accountFilterMenus } from "@/core/constant/menu"
import CheckIcon from "@mui/icons-material/Check"

export default function Account() {
  const [collectPage, setCollectPage] = useState(1)
  const [sequentialPage, setSequentialPage] = useState(1)
  const [collectSortBy, setCollectSortBy] = useState<string>(SortByEnum.NEWEST)
  const [active, setActive] = useState<string>("all")
  const [tab, setTab] = useState(0)
  const { breakpoints } = useTheme()
  const pageSize = 16
  const { search } = useContext(SearchContext)
  const paramTab = useSearchParam("tab")
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { address } = useAccount()

  const rawSequentialData = useAsync(async () => {
    if (address) {
      const order_by = "create_at"
      let order: "asc" | "desc" = "desc"
      switch (collectSortBy) {
        case SortByEnum.OLDEST:
          order = "asc"
          break
        default:
          break
      }
      const res = await AppContext.apiExecutor.getCollections(
        active,
        sequentialPage,
        pageSize,
        search || undefined,
        ContractTypeEnum.SEQUENTIAL,
        undefined,
        order_by,
        order
      )
      return res.data
    } else {
      return {
        code: "",
        result: {
          item_list: [],
          total_page: 0,
          total_item_size: 0,
          page: 0,
        },
      }
    }
  }, [collectPage, sequentialPage, address, search, collectSortBy, active])

  const rawCollectData = useAsync(async () => {
    if (address) {
      const order_by = "create_at"
      let order: "asc" | "desc" = "desc"
      switch (collectSortBy) {
        case SortByEnum.OLDEST:
          order = "asc"
          break
        default:
          break
      }
      const res = await AppContext.apiExecutor.getCollections(
        active,
        collectPage,
        pageSize,
        search || undefined,
        ContractTypeEnum.NORMAL,
        undefined,
        order_by,
        order
      )
      return res.data
    } else {
      return {
        code: "",
        result: {
          item_list: [],
          total_page: 0,
          total_item_size: 0,
          page: 0,
        },
      }
    }
  }, [collectPage, sequentialPage, address, search, collectSortBy, active])

  useEffect(() => {
    if (
      paramTab &&
      ["COLLECTION", "SEQUENTIAL_COLLECTION"].includes(paramTab)
    ) {
      setTab(paramTab === "COLLECTION" ? 0 : 1)
      setActive("all")
    }
  }, [paramTab])

  return (
    <OverviewContext.Provider
      value={{
        sequentialPage,
        setSequentialPage,
        collectPage,
        setCollectPage,
        rawCollectData,
        rawSequentialData,
        pageSize,
        collectSortBy,
        active,
      }}
    >
      <div className="px-8 pb-1">
        <div className="mt-10 text-[32px] font-bold leading-[38px] lg:mt-20">
          {t("account")}
        </div>
        <CommonTabs
          value={tab}
          changeTab={newValue => {
            setTab(newValue)
            navigate(
              `?tab=${newValue === 0 ? "COLLECTION" : "SEQUENTIAL_COLLECTION"}`,
              {
                replace: true,
              }
            )
          }}
          sx={{
            margin: "48px 0 24px",
            [breakpoints.down("lg")]: {
              margin: "20px 0",
            },
          }}
          options={accountMenus}
        />
        <div className="mb-6 flex flex-col justify-between md:mb-10 md:flex-row md:items-center">
          <div className="flex items-center gap-x-2">
            {accountFilterMenus.map(item => (
              <CommonButton
                key={item}
                variant="outlined"
                color="primary"
                onClick={() => {
                  setActive(item)
                  setCollectPage(1)
                  setSequentialPage(1)
                }}
                sx={{
                  borderRadius: 1,
                  padding: "6px 12px",
                  "&.MuiButton-root": {
                    borderColor: active === item ? "#333" : "#D1D1D1",
                    color: active === item ? "#333" : "#D1D1D1",
                  },
                }}
              >
                {active === item && (
                  <CheckIcon
                    fontSize="small"
                    sx={{
                      marginRight: 1,
                    }}
                  />
                )}
                {t(item)}
              </CommonButton>
            ))}
          </div>
          <div className="inline-flex w-full items-center justify-between pr-5 pt-5 md:w-fit md:p-0">
            <div className="inline-flex items-center">
              <div className="pr-2 font-semibold">{t("sort_order")}</div>
              <CommonSelect
                className="w-32 font-semibold"
                value={collectSortBy}
                onChange={event => {
                  setCollectSortBy(event.target.value)
                  setCollectPage(1)
                  setSequentialPage(1)
                }}
              >
                <option value={SortByEnum.NEWEST}>{t("sort_by_newest")}</option>
                <option value={SortByEnum.OLDEST}>{t("sort_by_oldest")}</option>
              </CommonSelect>
            </div>
          </div>
        </div>
        {tab === 0 ? <CollectionContent /> : <SequentialContent />}
      </div>
    </OverviewContext.Provider>
  )
}
