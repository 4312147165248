import React, { useState } from "react"
import { CommonSelect } from "@/components/common/CommonSelect"
import DownloadIcon from "@mui/icons-material/Download"
import { PinNFTDto } from "@/core/api"
import { useTranslation } from "react-i18next"
import { QRCodeCanvas } from "qrcode.react"
import QRCode from "react-qr-code"

export type QRCodeDownloadWrapperProps = { pin: PinNFTDto; fileName: string }

export const QRCodeDownloadWrapper: React.FunctionComponent<
  QRCodeDownloadWrapperProps
> = ({ pin, fileName }) => {
  const { t } = useTranslation()
  const [downloadFileType, setDownloadFileType] = useState("JPG")
  const downloadQrCode = () => {
    const canvas = document.getElementById("download-qr-code")
    if (canvas && downloadFileType) {
      let imageUrl = ""
      if (downloadFileType === "SVG") {
        const base64doc = btoa(unescape(encodeURIComponent(canvas.outerHTML)))
        imageUrl = `data:text/html;base64,${base64doc}`
      } else {
        const fileType = `image/${downloadFileType.toLowerCase()}`
        imageUrl = (canvas as HTMLCanvasElement)
          .toDataURL(fileType)
          .replace(fileType, "image/octet-stream")
      }
      const downloadLink = document.createElement("a")
      downloadLink.href = imageUrl
      downloadLink.download = `${fileName}.${downloadFileType.toLowerCase()}`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }
  }

  return (
    <div className="flex w-full flex-col items-center justify-center py-10 md:w-3/4">
      <CommonSelect
        wrapperClass="w-3/4 md:w-full"
        className="h-10 w-full !rounded-lg bg-[#F6F6F6] xl:!h-12"
        value={downloadFileType}
        onChange={event => setDownloadFileType(event.target.value)}
      >
        <option value="" className="hidden" disabled>
          {t("str_file_format")}
        </option>
        {["JPG", "JPEG", "PNG", "SVG"].map(type => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </CommonSelect>
      <div
        className="mt-8 flex w-56 flex-0 cursor-pointer items-center justify-center rounded-lg bg-black py-3 font-medium text-white transition-colors hover:bg-black/90 md:w-64 xl:h-12"
        onClick={downloadQrCode}
      >
        <DownloadIcon fontSize="small" className="self-end" />
        <span className="pl-2">{t("str_qrcode_download")}</span>
      </div>
      {downloadFileType === "SVG" ? (
        <QRCode
          className="absolute -z-10 hidden"
          id="download-qr-code"
          size={256}
          style={{ margin: "auto" }}
          viewBox="0 0 256 256"
          value={pin.pin_code}
        />
      ) : (
        <QRCodeCanvas
          className="absolute -z-10 hidden"
          id="download-qr-code"
          size={256}
          value={pin.pin_code}
        />
      )}
    </div>
  )
}
