import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material"
import { useNavigate, useLocation } from "react-router-dom"
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded"
import { NFTItemDto } from "@/core/api"

type Props = {
  selectedNFT: NFTItemDto | undefined
  collectionID?: string
}
export default function PinSuccess({ selectedNFT, collectionID }: Props) {
  const { breakpoints } = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const isSequential = location.pathname.includes("sequential")

  const handleNavigate = () => {
    isSequential
      ? navigate(`/sequential_collection/${collectionID}`)
      : navigate(`/nft/${selectedNFT?.address}/${selectedNFT?.tokenID}`)
  }

  useEffect(() => {
    scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="pt-6 text-center md:pt-10">
        <CheckCircleRoundedIcon
          style={{ color: "#21BF4E" }}
          sx={{
            fontSize: 240,
            [breakpoints.down("md")]: {
              fontSize: 160,
            },
          }}
        />
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="my-4 text-2xl leading-6">{t("apply_finish")}</div>
        <div className="mb-12 text-[20px] leading-6 text-[#757575] md:mb-16">
          {t("check_my_page")}
        </div>
        <div
          onClick={handleNavigate}
          className="mb-16 flex w-[376px] cursor-pointer items-center justify-center rounded-lg bg-black py-3 text-[20px] text-white"
        >
          {t("my_nft")}
        </div>
      </div>
    </>
  )
}
