import React from "react"
import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"

export const LandingBanner: React.FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <Box
      className="relative w-full"
      sx={{
        backgroundImage: "url('/images/branding-img.jpg')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
        backgroundPosition: "center",
        aspectRatio: "28 / 10",
      }}
    >
      <div className="absolute right-[5%] top-[20%] mb-2  px-3 text-sm  font-bold tracking-wider md:top-[40%]  lg:right-[10%] lg:text-4xl 2xl:right-[25%]">
        <span className="mb-4 inline-block bg-white p-1 md:p-2">
          {t("str_landing_banner_1")}
        </span>
        <div className="bg-white p-1 md:p-2">{t("str_landing_banner_2")}</div>
      </div>
    </Box>
  )
}
