import React from "react"
import { Box, useTheme } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded"
import { useTranslation } from "react-i18next"

export type UnPinNFTSuccessPopupProps = { close: () => void }

export const UnPinNFTSuccessPopup: React.FunctionComponent<
  UnPinNFTSuccessPopupProps
> = ({ close }) => {
  const { t } = useTranslation()
  const { breakpoints } = useTheme()

  return (
    <Box
      className="pb-14 pt-20"
      sx={{
        width: "95%",
        margin: "auto",
        [breakpoints.up("md")]: { width: 512 },
      }}
    >
      <CloseIcon
        className="absolute right-4 top-4 cursor-pointer transition-all hover:opacity-80"
        fontSize="large"
        onClick={close}
      />
      <div className="mx-10 text-center md:mx-auto md:w-2/3">
        <CheckCircleRoundedIcon
          style={{ color: "#21BF4E" }}
          sx={{ fontSize: 96 }}
        />
        <div className="pb-8 pt-4 text-2xl font-medium">
          {t("un_pin_successfully")}
        </div>
        <div
          onClick={close}
          className="mx-auto flex cursor-pointer items-center justify-center rounded-lg bg-black py-3 text-[18px] font-medium text-white transition-all hover:bg-[#000000d9] md:w-[240px]"
        >
          {t("ok")}
        </div>
      </div>
    </Box>
  )
}
