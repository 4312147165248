import React from "react"
import { Box, useTheme } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useTranslation } from "react-i18next"

export type ExamplePopupProps = { type: "qrcode" | "button"; close: () => void }

export const ExamplePopup: React.FunctionComponent<ExamplePopupProps> = ({
  type,
  close,
}) => {
  const { breakpoints } = useTheme()
  const { t } = useTranslation()

  return (
    <Box
      className="px-8 pb-16 pt-6"
      sx={{
        width: "95%",
        margin: "auto",
        [breakpoints.up("md")]: { width: 582 },
      }}
    >
      <CloseIcon
        fontSize="large"
        className="absolute right-5 top-4 cursor-pointer hover:text-gray-400"
        onClick={close}
      />
      <div className="mx-auto">
        <div className="mb-5 pt-4 text-center">
          <div className="ml-1 text-2xl font-bold leading-7">
            {t("usage_example")}
          </div>
        </div>
        <img
          src={
            type === "button"
              ? "/images/button-example.png"
              : "/images/qrcode-example.png"
          }
          alt="qrcode-example"
          className={`${
            type === "button" ? "mx-[70px]" : "mx-auto"
          } w-7/12 object-contain`}
        />
        <div className="mx-auto mt-14 w-5/6 text-sm">
          {type === "button"
            ? t("str_button_example")
            : t("str_qrcode_example")}
        </div>
      </div>
    </Box>
  )
}
