import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material"
import React, { InputHTMLAttributes } from "react"
import { Option } from "@/core/types/Options"
import { useTranslation } from "react-i18next"

export type CommonCheckboxGroupProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "value"
> & {
  checkboxOptions: Array<Option<string>>
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  checkedState: object
}

export const CommonCheckboxGroup: React.FunctionComponent<
  CommonCheckboxGroupProps
> = ({ checkboxOptions, onChange, checkedState }) => {
  const { t } = useTranslation()

  return (
    <FormControl fullWidth>
      <FormGroup>
        {checkboxOptions.map((item, index) => (
          <div key={index}>
            <FormControlLabel
              value={item.value}
              control={
                <Checkbox
                  onChange={onChange}
                  name={item.value}
                  checked={checkedState[index as keyof object]}
                  sx={{
                    "&.Mui-checked": {
                      color: "#000",
                    },
                  }}
                />
              }
              label={
                item.value === "agree" ? (
                  <p>
                    {t("str_terms_agree")}&nbsp;
                    <a
                      href={import.meta.env.VITE_TERM_URL}
                      className="underline hover:text-stone-600"
                      target="_blank"
                    >
                      ({t("str_terms_link")})
                    </a>
                  </p>
                ) : (
                  item.label
                )
              }
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontWeight: 500,
                  fontSize: 14,
                },
              }}
            />
          </div>
        ))}
      </FormGroup>
    </FormControl>
  )
}
