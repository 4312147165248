import { NFTItemDto } from "@/core/api"
import { format } from "date-fns"
import { TFunction } from "i18next"

export function getProperty(
  data: NFTItemDto | undefined,
  value: string
): string {
  let content

  if (data?.metadata.version) {
    content = data.metadata.sections?.[0].elements.find(item =>
      item.title.includes(value)
    )?.content
  } else {
    content = data?.metadata.properties.find(item =>
      item.title.includes(value)
    )?.content
  }

  return content && content !== "nan" ? content : ""
}

export function getDateRange(
  startDate: string,
  endDate: string,
  t: TFunction
): string {
  if (startDate === "-1" && endDate === "-1") {
    return t("permanent_date")
  }
  if (startDate && endDate) {
    const start = format(new Date(+startDate * 1000), "yyyy-MM-dd")
    const end = format(new Date(+endDate * 1000), "yyyy-MM-dd")
    return `${start} - ${end}`
  }
  return ""
}
