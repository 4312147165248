import React, { ReactElement } from "react"
import { useRoutes } from "react-router-dom"
import Home from "./home"
import Account from "./account"
import NFTDetail from "./nftDetail"
import PrivacyPolicy from "./PrivacyPolicy"
import NotFoundPage from "./NotFoundPage"
import PinGenerate from "./pinGenerate"
import Edit from "./edit"
import { MainLayout } from "@/components/layout/MainLayout"
import CollectionDetail from "./collectionDetail"
import TermsOfService from "./TermsofService"
import SequentialCollectionDetail from "./sequentialCollectionDetail"
import NormalPin from "./pinGenerate/NormalPin"
import SequentialPin from "./pinGenerate/SequentialPin"

export default function RouterMap(): ReactElement | null {
  return useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/term-of-service",
      element: <TermsOfService />,
    },
    {
      element: <MainLayout />,
      children: [
        {
          path: "/account",
          element: <Account />,
        },
        {
          path: "/collection/:id",
          element: <CollectionDetail />,
        },
        {
          path: "/sequential_collection/:id",
          element: <SequentialCollectionDetail />,
        },
        {
          path: "/nft/:contract/:id",
          element: <NFTDetail />,
        },
        {
          path: "/pin-generate",
          element: <PinGenerate />,
        },
        {
          path: "/pin-generate/:id",
          element: <NormalPin />,
        },
        {
          path: "/sequential-pin-generate/:id",
          element: <SequentialPin />,
        },
        {
          path: "/edit/:contract/:id",
          element: <Edit />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ])
}
