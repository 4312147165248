import { CircularProgress } from "@mui/material"
import React from "react"

export const LoadingComponent: React.FunctionComponent = () => {
  return (
    <div className="flex h-96 w-full items-center justify-center">
      <CircularProgress
        sx={{
          color: "#000000",
        }}
      />
    </div>
  )
}
