import axios, { AxiosInstance } from "axios"
import { OpenapiType } from "@/core/types/OpenapiType"
import { DefaultApiFactory } from "@/core/api"
import { getCookie, setCookie } from "@/core/utils/cookieUtils"
import { setInterceptors } from "@/core/api/axiosInterceptors"

let apiExecutor: OpenapiType

export const service: AxiosInstance = axios.create({
  timeout: 1000 * 60 * 2,
})
setInterceptors(service)

export class AppContext {
  public static get apiExecutor(): OpenapiType {
    if (!apiExecutor) {
      apiExecutor = {
        ...DefaultApiFactory(undefined, import.meta.env.VITE_API_URL, service),
      }
    }

    return apiExecutor
  }

  public static getJwtToken(): string {
    return getCookie("jwt-token")
  }

  public static getAuthAddress(): string {
    return getCookie("auth-address")
  }

  public static setJwtToken(token: string, address: string): void {
    const tokenExpiry = new Date().getTime() + 24 * 60 * 60 * 1000
    setCookie("jwt-token", token, new Date(tokenExpiry))
    setCookie("auth-address", address, new Date(tokenExpiry))
  }

  public static clearJwtToken(): void {
    document.cookie =
      "jwt-token" + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"
    document.cookie =
      "auth-address" + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"
  }
}
