import React, { useState, useEffect } from "react"
import { SequentialDetailBasic } from "@/pages/collectionDetail/SequentialDetailBasic"
import { Box, useMediaQuery, useTheme, Tooltip, Zoom } from "@mui/material"
import { useCopyToClipboard } from "react-use"
import { truncateEthAddress } from "@/core/utils/truncateEthAddress"
import { PinNFTDto, BaseResponseGetNFTsDto } from "@/core/api"
import { AsyncState } from "react-use/lib/useAsyncFn"
import { CollectionItemDto } from "@/core/api"
import { CommonButton } from "../common/CommonButton"
import { useTranslation } from "react-i18next"
import { LoadingWrapper } from "../common/loading/LoadingWrapper"
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft"
import CampaignIcon from "@mui/icons-material/Campaign"
import ErrorIcon from "@mui/icons-material/Error"
import LinkOffIcon from "@mui/icons-material/LinkOff"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import QrCodeIcon from "@mui/icons-material/QrCode"
import { getProperty, getDateRange } from "@/core/utils/detailUtils"
import { SectionComponent } from "@/components/common/metadata/SectionComponent"
import { ListComponent } from "@/components/common/metadata/ListComponent"
import { LinkComponent } from "@/components/common/metadata/LinkComponent"
import { BalanceOf } from "@/core/api/balanceOf"
import useCheckHeight from "@/core/hook/useCheckHeight"
import useDetailActions from "@/core/hook/useDetailActions"
import NoDataComponent from "@/components/common/NoDataComponent"

export type SequentialCollectionContainerProps = {
  collectionData: CollectionItemDto | undefined
  rawNFTData: AsyncState<BaseResponseGetNFTsDto>
  rawBalanceOf: AsyncState<BalanceOf>
}

export const SequentialCollectionContainer: React.FunctionComponent<
  SequentialCollectionContainerProps
> = ({ collectionData, rawNFTData, rawBalanceOf }) => {
  const [, copyToClipboard] = useCopyToClipboard()
  const [copySuccess, setCopySuccess] = useState(false)
  const { t } = useTranslation()
  const { palette, breakpoints } = useTheme()
  const isMobile = useMediaQuery(breakpoints.down("md"))
  const {
    isExpanded,
    setIsExpanded,
    showMoreButton,
    setShowMoreButton,
    textRef,
  } = useCheckHeight()
  const { loading, unPinNFT, showQrCode } = useDetailActions()

  useEffect(() => {
    const checkHeight = () => {
      const element = textRef.current
      if (element) {
        const lineHeight = 24
        setShowMoreButton(element.scrollHeight > lineHeight)
      }
    }
    checkHeight()

    window.addEventListener("resize", checkHeight)
    return () => {
      window.removeEventListener("resize", checkHeight)
    }
  }, [])

  return (
    <>
      <Box
        className="aspect-[4/1] h-fit w-full"
        sx={{
          backgroundImage: `url(${collectionData?.banner_img_url})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: "400px",
        }}
      >
        <div className="flex h-full w-full flex-col items-center justify-end bg-gradient-to-t from-[#00000066]">
          <div className="mx-auto flex w-full max-w-[1000px] gap-x-7 px-4 py-6">
            <img
              src={collectionData?.logo_img_url}
              className="aspect-square h-20 w-20 rounded-full object-cover sm:h-[90px] sm:w-[90px]"
              alt="avatar"
            />
            <div className="w-full">
              <div className="mb-4 flex items-center justify-between">
                <div className="line-clamp-2 break-all text-2xl font-bold text-white sm:text-3xl">
                  {collectionData?.name}
                </div>
                {Number(collectionData?.pin_counts) > 0 && (
                  <Box
                    className="rounded border border-[#F3F3F3] bg-white p-1 transition-colors hover:border-black"
                    onClick={() => showQrCode(collectionData?.pin as PinNFTDto)}
                  >
                    <QrCodeIcon sx={{ fontSize: 32 }} />
                  </Box>
                )}
              </div>

              <div
                ref={textRef}
                className={`scrollbar-thumb-rounded-[10px] whitespace-pre-wrap leading-6 text-white scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#ffffff80] ${
                  !isExpanded ? "line-clamp-1" : "overflow-y-auto"
                } max-h-24 break-all`}
              >
                {collectionData?.description}
                {isExpanded && (
                  <span
                    className="cursor-pointer font-bold underline transition-opacity hover:opacity-80"
                    onClick={() => {
                      setIsExpanded(false)
                      if (textRef.current) {
                        textRef.current.scrollTop = 0
                      }
                    }}
                  >
                    <br />
                    {t("less")}
                  </span>
                )}
              </div>
              {showMoreButton && !isExpanded && (
                <span
                  className="cursor-pointer font-bold text-white underline transition-opacity hover:opacity-80"
                  onClick={() => setIsExpanded(true)}
                >
                  {t("more")}
                </span>
              )}
            </div>
          </div>
        </div>
      </Box>
      <Box className="w-full bg-white">
        <LoadingWrapper
          state={rawNFTData}
          render={data =>
            Number(data.result.item_list?.length) > 0 ? (
              <div className="mx-auto w-11/12 sm:py-10 lg:w-4/5 xl:w-[1200px]">
                <SequentialDetailBasic
                  data={data.result.item_list?.[0]}
                  collectionData={collectionData}
                  rawBalanceOf={rawBalanceOf}
                />
                <div className="mb-8 w-full md:pr-8">
                  <SectionComponent
                    title="description"
                    icon={FormatAlignLeftIcon}
                  >
                    <div className="whitespace-pre-wrap p-6 leading-7">
                      {getProperty(
                        data.result.item_list?.[0].nft,
                        "details" || "クーポン内容の詳細説明"
                      )}
                    </div>
                  </SectionComponent>
                  <SectionComponent
                    title="information"
                    icon={CampaignIcon}
                    className="mt-12"
                  >
                    <>
                      <ListComponent
                        title={t("owner")}
                        value={
                          isMobile && data.result.item_list?.[0].nft
                            ? truncateEthAddress(
                                data.result.item_list?.[0].nft.owner
                              )
                            : data.result.item_list?.[0].nft.owner
                        }
                      />
                      <ListComponent
                        title={t("contract_address")}
                        value={
                          <>
                            <span className=" pr-2 ">
                              {isMobile && data.result.item_list?.[0].nft
                                ? truncateEthAddress(
                                    data.result.item_list?.[0].nft.address
                                  )
                                : data.result.item_list?.[0].nft.address}
                            </span>
                            <Tooltip
                              TransitionComponent={Zoom}
                              placement="top"
                              disableFocusListener
                              title={
                                copySuccess
                                  ? t("copy_successfully")
                                  : t("copy_contract_address")
                              }
                              PopperProps={{
                                sx: {
                                  "& .MuiTooltip-tooltip": {
                                    backgroundColor: copySuccess
                                      ? palette.success.main
                                      : "",
                                  },
                                },
                              }}
                            >
                              <ContentCopyIcon
                                className="hover:text-primary cursor-pointer"
                                fontSize="small"
                                onClick={() => {
                                  copyToClipboard(
                                    data.result.item_list?.[0].nft.address ?? ""
                                  )
                                  setCopySuccess(true)
                                }}
                                onMouseLeave={() =>
                                  setTimeout(() => setCopySuccess(false), 300)
                                }
                              />
                            </Tooltip>
                          </>
                        }
                      />
                      <ListComponent
                        title={t("token_standard")}
                        value={data.result.item_list?.[0].nft.standard}
                      />
                      <ListComponent
                        title={t("blockchain")}
                        value={data.result.item_list?.[0].nft.blockchain}
                      />
                    </>
                  </SectionComponent>
                  <SectionComponent
                    title="detail"
                    icon={ErrorIcon}
                    className="mt-12"
                  >
                    <>
                      <ListComponent
                        title={t("address")}
                        value={getProperty(
                          data.result.item_list?.[0].nft,
                          "spot" || "クーポン利用店舗名"
                        )}
                      />
                      <ListComponent
                        title={t("terms_and_conditions")}
                        value={getProperty(
                          data.result.item_list?.[0].nft,
                          "rules" || "クーポン提示条件"
                        )}
                      />
                      <ListComponent
                        title={t("daterange")}
                        value={
                          getDateRange(
                            getProperty(
                              data.result.item_list?.[0].nft,
                              "startDate"
                            ),
                            getProperty(
                              data.result.item_list?.[0].nft,
                              "endDate"
                            ),
                            t
                          ) ||
                          getProperty(
                            data.result.item_list?.[0].nft,
                            "クーポン有効期限"
                          )
                        }
                      />
                      <ListComponent
                        title={t("remarks")}
                        value={getProperty(
                          data.result.item_list?.[0].nft,
                          "remarks" || "その他特典"
                        )}
                      />
                      <ListComponent
                        title={t("storename")}
                        value={getProperty(
                          data.result.item_list?.[0].nft,
                          "storeName" || "店舗名"
                        )}
                      />
                      <ListComponent
                        title={t("phone")}
                        value={getProperty(
                          data.result.item_list?.[0].nft,
                          "phone" || "店舗電話番号"
                        )}
                      />
                      <ListComponent
                        title={t("website")}
                        value={
                          <LinkComponent
                            value={getProperty(
                              data.result.item_list?.[0].nft,
                              "website" || "店舗ホームページ"
                            )}
                          />
                        }
                      />
                      <ListComponent
                        title={t("SNS")}
                        value={
                          <LinkComponent
                            value={getProperty(
                              data.result.item_list?.[0].nft,
                              "social_media" || "店舗SNS"
                            )}
                          />
                        }
                      />
                    </>
                  </SectionComponent>
                </div>
                {data &&
                  data.result.item_list?.[0].pin &&
                  Object.keys(data.result.item_list?.[0].pin).length > 0 && (
                    <div className="mb-8 flex justify-center md:mb-16">
                      <CommonButton
                        variant="outlined"
                        sx={{
                          width: "240px",
                          color: "#000",
                          borderColor: "#000",
                          borderRadius: 2,
                          paddingY: "10px",
                          borderWidth: 2,
                          "&:hover": {
                            borderColor: "#000",
                            borderWidth: 2,
                            backgroundColor: "#00000009",
                          },
                        }}
                        onClick={() =>
                          unPinNFT(data.result.item_list?.[0].pin._id as string)
                        }
                        disabled={loading}
                      >
                        <LinkOffIcon
                          sx={{
                            marginRight: "16px",
                          }}
                        />
                        {loading ? t("loading") : t("unlink")}
                      </CommonButton>
                    </div>
                  )}
              </div>
            ) : (
              <NoDataComponent />
            )
          }
        />
      </Box>
    </>
  )
}
