/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useMemo } from "react"
import { LoadingWrapper } from "@/components/common/loading/LoadingWrapper"
import { Tooltip, Zoom, useTheme, Box, FormHelperText } from "@mui/material"
import { useAsync } from "react-use"
import { useParams } from "react-router-dom"
import { AppContext } from "@/AppContext"
import FileCopyRoundedIcon from "@mui/icons-material/FileCopyRounded"
import { useCopyToClipboard } from "react-use"
import { useTranslation } from "react-i18next"
import QRCode from "qrcode.react"
import { PhotoLineIcon } from "@/components/common/CommonIcon"
import FindReplaceIcon from "@mui/icons-material/FindReplace"
import AlertContainer from "@/core/store/AlertContainer"
import { NFTReplacePopup } from "./NFTReplacePopup"
import { NFTItemDto } from "@/core/api"
import { CommonDateRangePicker } from "@/components/common/CommonDateRangePicker"
import { Autocomplete, GoogleMap, LoadScript } from "@react-google-maps/api"
import { Library } from "@googlemaps/js-api-loader/src"
import { CommonCheckbox } from "@/components/common/form/CommonCheckbox"
import { CommonTextField } from "@/components/common/form/CommonTextField"
import { CommonRadioGroup } from "@/components/common/form/CommonRadioGroup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { getPinNFTFormSchema } from "@/core/constant/formSchema"
import { PinSetLocationFormRequest } from "@/core/types/Request"
import { initialPinSetLocationForm } from "@/core/constant/initial"
import {
  getUnixTime,
  startOfDay,
  endOfDay,
  eachWeekOfInterval,
  format,
  isAfter,
  isEqual,
  fromUnixTime,
  eachDayOfInterval,
  add,
  isBefore,
  differenceInCalendarDays,
  sub,
  startOfToday,
} from "date-fns"
import {
  getTimePeriodOptions,
  getWeekOptions,
  getRangeOptions,
} from "@/core/constant/options"
import { TimePeriodEnum } from "@/core/enum/TimePeriodEnum"
import { CommonTimeRangePicker } from "@/components/common/CommonTimeRangePicker"
import AddBoxIcon from "@mui/icons-material/AddBox"
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox"
import { WeekEnum } from "@/core/enum/WeekEnum"
import PinSuccess from "@/pages/pinGenerate/PinSuccess"
import { CommonButton } from "@/components/common/CommonButton"
import { DateRangeEnum } from "@/core/enum/DateRangeEnum"

export default function Edit() {
  const libraries: Array<Library> = ["places"]
  const [, copyToClipboard] = useCopyToClipboard()
  const [copySuccess, setCopySuccess] = useState(false)
  const [selectedNFT, setSelectedNFT] = useState<NFTItemDto | undefined>(
    undefined
  )
  const [selectedCollectionName, setSelectedCollectionName] =
    useState<string>("")
  const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState("")
  const [defaultDateRange, setDefaultDateRange] = useState<Date[]>([])
  const [success, setSuccess] = useState(false)
  const { openDialog, closeDialog, pushAlert } = AlertContainer.useContainer()
  const { t, i18n } = useTranslation()
  const { palette } = useTheme()
  const { id, contract } = useParams()

  const rawData = useAsync(async () => {
    if (contract) {
      const res = await AppContext.apiExecutor.getNFTs(
        "pin",
        1,
        1,
        contract,
        id
      )

      return res.data.result.item_list?.[0]
    }
    return undefined
  }, [contract])

  useEffect(() => {
    if (selectedNFT) {
      const fetchData = async () => {
        const res = await AppContext.apiExecutor.getNFTs(
          "all",
          1,
          1,
          selectedNFT.address,
          selectedNFT.tokenID
        )
        setSelectedCollectionName(
          res.data.result.item_list?.[0].collection.name as string
        )
      }
      fetchData()
    }
  }, [selectedNFT])

  const {
    watch,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
    trigger,
  } = useForm<PinSetLocationFormRequest>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(getPinNFTFormSchema(t)),
    defaultValues: initialPinSetLocationForm,
  })

  useEffect(() => {
    let properties

    if (selectedNFT?.metadata?.version) {
      properties = selectedNFT.metadata.sections?.[0].elements
    } else if (selectedNFT?.metadata?.properties) {
      properties = selectedNFT.metadata.properties
    }

    if (!properties) {
      if (rawData.value?.nft.metadata.version) {
        properties = rawData.value.nft.metadata.sections?.[0].elements
      } else if (rawData.value?.nft.metadata.properties) {
        properties = rawData.value.nft.metadata.properties
      }
    }
    const startDate =
      (properties?.find((propertie: any) => propertie.title === "startDate")
        ?.content as unknown as number) ?? null
    const endDate =
      (properties?.find((propertie: any) => propertie.title === "endDate")
        ?.content as unknown as number) ?? null

    if (startDate && endDate && startDate != -1 && endDate != -1) {
      const startDateToDate = fromUnixTime(startDate)
      const endDateToDate = fromUnixTime(endDate)
      setDefaultDateRange([startDateToDate, endDateToDate])
    } else {
      setDefaultDateRange([])
    }
  }, [rawData.value, selectedNFT])

  const onPlaceLoad = (autocomplete: google.maps.places.Autocomplete) => {
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace()
      place.formatted_address && setLocation(place.formatted_address)

      if (place.geometry && place.geometry.location) {
        setValue("lat", place.geometry.location.lat())
        setValue("lng", place.geometry.location.lng())
        setValue("address", place.formatted_address || "")
        clearErrors("address")
        clearErrors(["lat", "lng"])
      }
    })
  }

  useEffect(() => {
    if (watch().lat && watch().lng && location !== watch().address) {
      setValue("lat", 0)
      setValue("lng", 0)
      setValue("address", "")
    }
  }, [location])

  const mapWeekDayToEnum = (day: any) => {
    const weekDayMap: any = {
      monday: WeekEnum.MONDAY,
      tuesday: WeekEnum.TUESDAY,
      wednesday: WeekEnum.WEDNESDAY,
      thursday: WeekEnum.THURSDAY,
      friday: WeekEnum.FRIDAY,
      saturday: WeekEnum.SATURDAY,
      sunday: WeekEnum.SUNDAY,
    }
    return weekDayMap[day] || day
  }

  // 在 API 取到資料後，將預設資料填入表單
  useEffect(() => {
    if (rawData.value) {
      const startDate = fromUnixTime(rawData.value.pin.start_date_timestamp)
      const endDate = fromUnixTime(rawData.value.pin.end_date_timestamp)
      rawData.value.pin.end_date_timestamp === 4102415999
        ? setValue("timeRange", DateRangeEnum.NO_LIMIT)
        : setValue("timeRange", DateRangeEnum.THREE_MONTHS)
      setLocation(rawData.value.pin.geo.address as string)
      setValue("slogan", rawData.value.pin.slogan as string)
      setValue("distance", rawData.value.pin.geo.distance as number)
      setValue("address", rawData.value.pin.geo.address as string)
      setValue("lat", rawData.value.pin.geo.lat as number)
      setValue("lng", rawData.value.pin.geo.lon as number)
      setValue("timePeroid", rawData.value.pin.timePeriod as TimePeriodEnum)
      setValue("dateRange", [startDate, endDate], { shouldValidate: false })
      trigger(["lat", "lng"])
      if (rawData.value.pin.timePeriod === TimePeriodEnum.FIXED_TIME_PEROID) {
        const interval =
          rawData.value.pin.available_time.length / fixedTimeArrayNum
        setValue("fixedTimeArray", [])
        for (let i = 0; i < fixedTimeArrayNum; i++) {
          const index = i * interval
          setValue("fixedTimeArray", [
            ...watch("fixedTimeArray"),
            [
              fromUnixTime(
                rawData.value.pin.available_time[index]?.from as number
              ),
              fromUnixTime(
                rawData.value.pin.available_time[index]?.to as number
              ),
            ],
          ])
        }
      }
      if (rawData.value.pin.timePeriod === TimePeriodEnum.CUSTOM_TIME_PEROID) {
        const weekMap = new Map()

        for (const dateTime of rawData.value.pin.available_time) {
          const week = format(
            fromUnixTime(dateTime.from),
            "EEEE"
          ).toLocaleLowerCase()
          const startTime = format(fromUnixTime(dateTime.from), "HH:mm")
          const endTime = format(fromUnixTime(dateTime.to), "HH:mm")

          if (weekMap.has(week)) {
            weekMap.set(week, [
              ...new Set([...weekMap.get(week), `${startTime} ~ ${endTime}`]),
            ])
          } else {
            weekMap.set(week, [`${startTime} ~ ${endTime}`])
          }
        }

        // 將時間範圍轉換成 Date 物件的函數
        const convertTimeToDates = (timeStr: string, baseDate = new Date()) => {
          const [start, end] = timeStr.split(" ~ ")
          const [startHours, startMinutes] = start.split(":").map(Number)
          const [endHours, endMinutes] = end.split(":").map(Number)

          const startDate = new Date(baseDate)
          startDate.setHours(startHours, startMinutes, 0, 0)

          const endDate = new Date(baseDate)
          endDate.setHours(endHours, endMinutes, 0, 0)

          return [startDate, endDate]
        }

        // 轉換 Map 中的時間範圍
        const convertTimeMapToDateMap = (
          weekMap: Map<string, Array<string>>
        ) => {
          const convertedMap = new Map()
          weekMap.forEach((timeRanges, day) => {
            const dateRanges = timeRanges.map(timeRange =>
              convertTimeToDates(timeRange)
            )
            convertedMap.set(day, dateRanges)
          })
          return convertedMap
        }

        const convertedMap = convertTimeMapToDateMap(weekMap)

        // 設置每個星期的時間數據
        convertedMap.forEach((times, week) => {
          const weekKey = mapWeekDayToEnum(week).toLowerCase()
          setValue(weekKey, times)
        })

        // 設置選擇的星期
        const selectedWeeks = Array.from(convertedMap.keys()).map(
          mapWeekDayToEnum
        )
        setValue("weeks", selectedWeeks)
      }
    }
  }, [rawData.value, setValue])

  // 初始化表單
  useEffect(() => {
    if (selectedNFT) {
      setLocation("")
      setValue("address", "")
      setValue("lat", 0)
      setValue("lng", 0)
      setValue("timePeroid", TimePeriodEnum.NO_TIME_LIMIT)
      setValue("dateRange", [null, null])
      setValue("timeRange", DateRangeEnum.NO_LIMIT)
      setValue("fixedTimeArray", [])
      setValue("weeks", [])
      setValue("slogan", "")
      setValue("distance", 0)
    }
  }, [selectedNFT])

  const getWeekTime = (dateRange: Array<string>, week: WeekEnum) => {
    const returnWeekNum = (week: WeekEnum) => {
      const weekObj = {
        [WeekEnum.MONDAY]: 1,
        [WeekEnum.TUESDAY]: 2,
        [WeekEnum.WEDNESDAY]: 3,
        [WeekEnum.THURSDAY]: 4,
        [WeekEnum.FRIDAY]: 5,
        [WeekEnum.SATURDAY]: 6,
        [WeekEnum.SUNDAY]: 0,
      }

      return weekObj[week]
    }
    const start = new Date(dateRange[0] as string)
    const end = new Date(dateRange[1] as string)
    const weekStartsOn = returnWeekNum(week) as 0 | 1 | 2 | 3 | 4 | 5 | 6
    const dates = eachWeekOfInterval(
      {
        start,
        end,
      },
      { weekStartsOn: weekStartsOn }
    )
    return dates.filter(
      date =>
        isAfter(date, new Date(dateRange[0])) ||
        isEqual(
          new Date(date.getFullYear(), date.getMonth(), date.getDate()),
          new Date(start.getFullYear(), start.getMonth(), start.getDate())
        )
    )
  }
  const formatFromToTime = (
    start_date: string | Date,
    end_date: string | Date,
    start_time: Date,
    end_time: Date
  ) => {
    const from = getUnixTime(
      new Date(
        `${format(new Date(start_date), "yyyy-MM-dd")} ${format(
          start_time,
          "HH:mm"
        )}`
      )
    )

    const to = getUnixTime(
      new Date(
        `${format(new Date(end_date), "yyyy-MM-dd")} ${format(
          end_time,
          "HH:mm"
        )}`
      )
    )

    return { from, to }
  }

  const onSave = async (form: PinSetLocationFormRequest) => {
    const availableTime: { from: number; to: number }[] = []
    const start_date = form.dateRange[0] as string
    const end_date = form.dateRange[1] as string

    switch (form.timePeroid) {
      case TimePeriodEnum.NO_TIME_LIMIT: {
        availableTime.push(
          formatFromToTime(
            start_date,
            end_date,
            startOfDay(new Date(start_date)),
            endOfDay(new Date(end_date))
          )
        )
        break
      }

      case TimePeriodEnum.FIXED_TIME_PEROID: {
        const dates = eachDayOfInterval({
          start: new Date(start_date),
          end: new Date(end_date),
        })
        for (const timeArray of form.fixedTimeArray) {
          for (const date of dates) {
            availableTime.push(
              formatFromToTime(date, date, timeArray[0], timeArray[1])
            )
          }
        }
        break
      }
      case TimePeriodEnum.CUSTOM_TIME_PEROID: {
        for (const week of form.weeks) {
          const weekTimeList = getWeekTime(
            form.dateRange as Array<string>,
            week
          )

          for (const weekTime of weekTimeList) {
            for (const timeArray of form[
              week.toLocaleLowerCase() as
                | "mon"
                | "tue"
                | "wed"
                | "thu"
                | "fri"
                | "sat"
                | "sun"
            ]) {
              availableTime.push(
                formatFromToTime(weekTime, weekTime, timeArray[0], timeArray[1])
              )
            }
          }
        }
        break
      }
    }
    if (!loading) {
      setLoading(true)
      try {
        const request = {
          nftID: selectedNFT && selectedNFT._id,
          startDate: form.dateRange[0]
            ? getUnixTime(startOfDay(new Date(form.dateRange[0])))
            : getUnixTime(startOfDay(new Date())),
          endDate: form.dateRange[1]
            ? getUnixTime(endOfDay(new Date(form.dateRange[1])))
            : getUnixTime(endOfDay(new Date())),
          geo: {
            lat: form.lat,
            lon: form.lng,
            distance: form.distance,
            address: form.address,
          },
          availableTime,
          timePeriod: form.timePeroid,
          slogan: form.slogan,
        }
        await AppContext.apiExecutor.updatePinNFT(
          import.meta.env.VITE_API_KEY,
          `Bearer ${AppContext.getJwtToken()}`,
          rawData.value?.pin._id as string,
          request
        )
        setLoading(false)
        setSuccess(true)
      } catch (err: any) {
        console.error(err)
        setLoading(false)
        pushAlert({
          message: err.response.data.message,
          severity: "error",
        })
      }
    }
  }

  const setFixedTimeArray = (action: "add" | "remove") => {
    if (action === "add") {
      setValue("fixedTimeArray", [
        ...watch("fixedTimeArray"),
        [startOfDay(new Date()), endOfDay(new Date())],
      ])
    } else {
      const newTimeArray = watch("fixedTimeArray").slice(
        0,
        watch("fixedTimeArray").length - 1
      )
      setValue("fixedTimeArray", newTimeArray)
    }
  }

  const setWeekTimeArray = (
    week: "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun",
    action: "add" | "remove"
  ) => {
    if (action === "add") {
      setValue(week, [
        ...watch(week),
        [startOfDay(new Date()), endOfDay(new Date())],
      ])
    } else {
      const newTimeArray = watch(week).slice(0, watch(week).length - 1)
      setValue(week, newTimeArray)
    }
  }
  const renderWeekTime = () => {
    return getWeekOptions(t).map(item => {
      const weekKey = item.value.toLocaleLowerCase() as
        | "mon"
        | "tue"
        | "wed"
        | "thu"
        | "fri"
        | "sat"
        | "sun"
      const timeArray = watch(weekKey)
      return {
        ...item,
        selectedRender: (
          <>
            <div className="ms-6 flex">
              <div className="flex flex-col md:flex-row">
                {timeArray?.map((_: any, index: number) => (
                  <div
                    key={index}
                    className={`my-2 md:my-4  ${
                      index + 1 !== timeArray.length && timeArray.length > 1
                        ? "border-e-0 border-gray-300 pe-0 md:me-3.5 md:border-e-2 md:pe-3.5"
                        : " "
                    }`}
                  >
                    <CommonTimeRangePicker
                      control={control}
                      name={weekKey}
                      index={index}
                      errors={errors}
                    />
                  </div>
                ))}
              </div>

              <Box className="ms-1 flex flex-col justify-center text-[24px] text-black">
                <AddBoxIcon
                  onClick={() => setWeekTimeArray(weekKey, "add")}
                  className={`cursor-pointer ${
                    timeArray?.length < 3 ? "" : "!hidden"
                  }`}
                />
                <IndeterminateCheckBoxIcon
                  onClick={() => setWeekTimeArray(weekKey, "remove")}
                  className={`cursor-pointer ${
                    timeArray?.length > 1 ? "" : "!hidden"
                  }`}
                />
              </Box>
            </div>
          </>
        ),
      }
    })
  }

  const renderTimePeroid = (timePeroid: TimePeriodEnum) => {
    switch (timePeroid) {
      case TimePeriodEnum.FIXED_TIME_PEROID: {
        const timeArray = watch("fixedTimeArray")
        return (
          <div className="flex">
            <div className="my-3 flex flex-col md:flex-row">
              {timeArray.map((_: any, index: number) => (
                <div
                  key={index}
                  className={`mb-2 md:mb-0 ${
                    index + 1 !== timeArray.length && timeArray.length > 1
                      ? "border-e-0 border-gray-300 pe-0 md:me-3.5 md:border-e-2 md:pe-3.5"
                      : ""
                  }`}
                >
                  <CommonTimeRangePicker
                    control={control}
                    name="fixedTimeArray"
                    index={index}
                    errors={errors}
                    defaultFromValue={timeArray[index][0]}
                    defaultToValue={timeArray[index][1]}
                  ></CommonTimeRangePicker>
                </div>
              ))}
            </div>

            <Box className="ms-1 flex flex-col justify-center text-[24px] text-black">
              <AddBoxIcon
                onClick={() => setFixedTimeArray("add")}
                className={`cursor-pointer ${
                  timeArray.length < 3 ? "" : "!hidden"
                }`}
              />
              <IndeterminateCheckBoxIcon
                onClick={() => setFixedTimeArray("remove")}
                className={`cursor-pointer ${
                  timeArray.length > 1 ? "" : "!hidden"
                }`}
              />
            </Box>
          </div>
        )
      }

      case TimePeriodEnum.CUSTOM_TIME_PEROID: {
        return (
          <div className="ms-5 flex">
            <div className=" flex flex-col md:flex-row">
              <CommonCheckbox
                name="weeks"
                control={control}
                checkboxOptions={renderWeekTime()}
                errors={errors}
              ></CommonCheckbox>
            </div>
          </div>
        )
      }
    }
  }

  const renderRangePicker = (timeRange: DateRangeEnum) => {
    if (timeRange === DateRangeEnum.THREE_MONTHS) {
      return (
        <CommonDateRangePicker
          disabled={watch("timeRange") === "noLimit"}
          control={control}
          errors={errors}
          name="dateRange"
          {...(dateRangeMaxDate && {
            maxDate: dateRangeMaxDate,
          })}
        />
      )
    }
  }

  const isExpired = useMemo(() => {
    return (
      defaultDateRange.length > 0 &&
      isBefore(defaultDateRange[1], startOfToday())
    )
  }, [rawData, defaultDateRange])

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === "timePeroid") {
        setValue("fixedTimeArray", [
          [startOfDay(new Date()), endOfDay(new Date())],
        ])
        clearErrors(["fixedTimeArray", "weeks"])
        setValue("weeks", [])
        for (const week of getWeekOptions(t)) {
          const value = week.value?.toLocaleLowerCase() as
            | "mon"
            | "tue"
            | "wed"
            | "thu"
            | "fri"
            | "sat"
            | "sun"
          setValue(value, [[startOfDay(new Date()), endOfDay(new Date())]])
          clearErrors([value])
        }
      } else if (name === "dateRange") {
        trigger(["dateRange"])
        if (!values.dateRange || defaultDateRange.length !== 0) return
        const [startDate, endDate] = values.dateRange
        if (
          startDate &&
          endDate &&
          isAfter(new Date(endDate), defaultDateRange[1])
        ) {
          setValue("dateRange", [startDate, null])
        }
      } else if (name === "timeRange") {
        if (
          values.timeRange !== "noLimit" &&
          values.timeRange !== "threeMonths"
        )
          return
        if (values.timeRange === "noLimit") {
          const dateRangeValue =
            isExpired && defaultDateRange.length > 0
              ? [defaultDateRange[0], fromUnixTime(4102415999)]
              : [startOfToday(), fromUnixTime(4102415999)]
          setValue("dateRange", dateRangeValue)
          setValue("timePeroid", TimePeriodEnum.NO_TIME_LIMIT)
          return
        }
        if (values.timeRange === "threeMonths") {
          setValue("dateRange", [null, null])
          return
        }
      }
    })

    return () => subscription.unsubscribe()
  }, [watch, setValue, isExpired, defaultDateRange])

  useEffect(() => {
    if (watch("timeRange") !== "noLimit") return
    setValue("timePeroid", TimePeriodEnum.NO_TIME_LIMIT)

    const startDate =
      defaultDateRange.length > 0 && isExpired
        ? defaultDateRange[0]
        : startOfToday()
    const endDate = fromUnixTime(4102415999)
    setValue("dateRange", [startDate, endDate])
  }, [rawData, selectedNFT, watch, defaultDateRange, isExpired])

  const fixedTimeArrayNum = useMemo(() => {
    if (rawData.value) {
      const startDate = fromUnixTime(rawData.value.pin.start_date_timestamp)
      const endDate = fromUnixTime(rawData.value.pin.end_date_timestamp)
      // 用 differenceInCalendarDays 計算日期差，加 1 是因為包含開始日期
      const daysDifference = differenceInCalendarDays(endDate, startDate) + 1
      const length = rawData.value.pin.available_time.length

      // 若 endDate 與 startDate 相同，表示只有一天
      if (daysDifference !== 0) {
        return Math.floor(length / daysDifference)
      }
    }
    return 1
  }, [rawData.value])

  const dateRangeMaxDate = useMemo(() => {
    const [startDate] = watch("dateRange")
    const maxDate = defaultDateRange.length > 0 ? defaultDateRange[1] : null
    if (startDate) {
      let result = add(new Date(startDate), {
        months: 3,
      })
      result = sub(result, {
        days: 1,
      })
      return maxDate ? (isBefore(maxDate, result) ? maxDate : result) : result
    }
    return maxDate
  }, [watch("dateRange"), defaultDateRange])

  const showReplaceNFT = () => {
    openDialog({
      children: (
        <NFTReplacePopup
          close={closeDialog}
          setSelectedNFT={setSelectedNFT}
          companyHash={rawData.value?.pin.companyHash}
        />
      ),
      close: closeDialog,
    })
  }

  return !success ? (
    <div className="mx-auto w-11/12 py-14 lg:w-4/5 xl:w-[1200px]">
      <LoadingWrapper
        state={rawData}
        keepLoading={data => !data}
        render={data => (
          <div className="flex flex-col items-center justify-center">
            <div className="w-full border-b border-black pb-8 text-center text-5xl font-bold leading-[56px]">
              {t("str_edit_qrcode_information")}
            </div>
            <QRCode
              size={256}
              style={{ margin: "auto", marginTop: "32px" }}
              viewBox="0 0 256 256"
              value={data?.pin.pin_code as string}
            />
            <div className="mt-7 w-11/12 sm:w-1/2 md:w-1/3">
              <div className="flex justify-between rounded-md bg-[#EFF0F2] px-4 py-2">
                <span className="truncate pe-2 text-sm font-medium text-[#747474]">
                  {data?.pin.pin_code}
                </span>
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="top"
                  disableFocusListener
                  title={
                    copySuccess ? t("copy_successfully") : t("str_copy_link")
                  }
                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        backgroundColor: copySuccess
                          ? palette.success.main
                          : "",
                      },
                    },
                  }}
                >
                  <FileCopyRoundedIcon
                    className="cursor-pointer"
                    onClick={() => {
                      copyToClipboard(data?.pin.pin_code ?? "")
                      setCopySuccess(true)
                    }}
                    onMouseLeave={() =>
                      setTimeout(() => setCopySuccess(false), 300)
                    }
                  />
                </Tooltip>
              </div>
            </div>
            <div className="mb-8 mt-14 w-11/12 rounded-lg px-5 pb-7 pt-5 shadow-[0_1px_5px_0_rgba(0,0,0,0.2)] sm:w-1/2 md:w-1/3">
              <div className="relative mb-4 aspect-square">
                <img
                  src={selectedNFT ? selectedNFT.image : data?.nft.image}
                  className="pointer-events-none aspect-square w-full rounded-lg object-cover"
                  alt={selectedNFT ? selectedNFT.name : data?.nft.name}
                />
                <div className="absolute left-3 top-2 inline-flex items-center rounded bg-black/40 px-2 py-[6px] text-white">
                  <PhotoLineIcon fontSize="small" />
                  <span className="pl-1 text-sm font-medium">{t("image")}</span>
                </div>
              </div>
              <div className="text-lg font-bold leading-5">
                <p className="mb-[14px]">
                  {selectedNFT ? selectedCollectionName : data?.collection.name}
                </p>
                <p>{selectedNFT ? selectedNFT.name : data?.nft.name}</p>
              </div>
            </div>
            <div
              className="flex w-11/12 cursor-pointer items-center justify-center rounded-lg bg-black py-4 text-xl text-white transition-colors hover:bg-black/80 sm:w-1/2 md:w-1/3"
              onClick={showReplaceNFT}
            >
              <FindReplaceIcon fontSize="small" />
              <span className="pl-1">{t("str_replace")}</span>
            </div>
            <div className="w-full">
              <Box className="mt-16 min-h-[400px] border-y border-dashed border-black px-2 py-8 md:px-16">
                <div className="font-bold">
                  <div className="form_required_suffix mb-2 text-2xl leading-6">
                    {t("address")}
                  </div>
                  <div className="text-sm text-[#757575]">
                    {t("str_location_prompt")}
                  </div>
                </div>
                <LoadScript
                  googleMapsApiKey={import.meta.env.VITE_GOOGLE_API_KEY}
                  libraries={libraries}
                  language={i18n.language}
                >
                  <div className="py-4">
                    <Autocomplete onLoad={onPlaceLoad}>
                      <CommonTextField
                        variant="outlined"
                        type="text"
                        control={control}
                        errors={errors}
                        name="address"
                        value={location}
                        placeholder={t("str_location_placeholder")}
                        onChange={e => setLocation(e.target.value)}
                        sx={{
                          "& .MuiOutlinedInput-root > fieldset": {
                            borderColor:
                              errors.lat || errors.lng ? "red" : "black",
                          },
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              borderColor:
                                errors.lat || errors.lng ? "red" : "black",
                            },
                          },
                        }}
                      />
                    </Autocomplete>
                    {(errors.lat || errors.lng) && (
                      <FormHelperText error sx={{ margin: "4px 14px" }}>
                        {location
                          ? t("str_please_select_address_from_list")
                          : t("str_please_fill_out_this_field")}
                      </FormHelperText>
                    )}
                  </div>
                  <GoogleMap
                    mapContainerStyle={{
                      height: "400px",
                      width: "100%",
                    }}
                    center={{
                      lat: watch().lat || (data?.pin.geo.lat as number),
                      lng: watch().lng || (data?.pin.geo.lon as number),
                    }}
                    clickableIcons={false}
                    zoom={18}
                  />
                </LoadScript>
              </Box>
              <div className="min-h-[200px] border-b border-dashed border-black px-2 py-10 md:px-16">
                <div className="mb-4 font-bold">
                  <div className="form_required_suffix mb-2 text-2xl leading-6">
                    {t("range")}（{t("distance_receive_nft")}）
                  </div>
                  <div className="text-sm text-[#757575]">
                    {t("distance_limit_description")}
                  </div>
                </div>
                <CommonTextField
                  type="tel"
                  control={control}
                  errors={errors}
                  variant="outlined"
                  name="distance"
                  placeholder={t("str_distance_placeholder")}
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: "black",
                      },
                    },
                  }}
                />
              </div>
              <div className="min-h-[200px] border-b border-dashed border-black px-2 py-10 md:px-16">
                <div className="form_required_suffix mb-2 text-2xl font-bold leading-6">
                  {t("start_day_end_day")}
                </div>
                <Box
                  className={`mb-2 flex items-center text-sm font-medium text-red-500
         ${watch("timeRange") === "noLimit" ? "hidden" : ""}`}
                >
                  <div className="mr-2 aspect-square w-[8px] rounded-full bg-red-500"></div>
                  <span>{t("str_pin_range_three_month_desc")}</span>
                </Box>
                {defaultDateRange.length > 0 && (
                  <Box
                    className={`mb-4 flex items-center text-sm font-medium text-red-500 ${
                      watch("timeRange") === "noLimit" ? "hidden" : ""
                    }`}
                  >
                    <div className="mr-2 aspect-square w-[8px] rounded-full bg-red-500"></div>
                    <span>
                      {t("str_date_range_meta_required", {
                        startYear: format(defaultDateRange[0], "yyyy"),
                        startMonth: format(defaultDateRange[0], "MM"),
                        startDate: format(defaultDateRange[0], "dd"),
                        endYear: format(defaultDateRange[1], "yyyy"),
                        endMonth: format(defaultDateRange[1], "MM"),
                        endDate: format(defaultDateRange[1], "dd"),
                      })}
                    </span>
                  </Box>
                )}

                <div className="mb-4">
                  <CommonRadioGroup
                    name="timeRange"
                    radioOptions={getRangeOptions(t)}
                    control={control}
                    errors={errors}
                    selectedRender={renderRangePicker(watch("timeRange"))}
                  />
                </div>
              </div>
              <div className="min-h-[200px] border-b border-dashed border-black px-2 py-10 md:px-16">
                <div className="form_required_suffix mb-4 text-2xl font-bold leading-6">
                  {t("time_period_title")}
                </div>
                <CommonRadioGroup
                  name="timePeroid"
                  disabled={watch("timeRange") === "noLimit"}
                  radioOptions={getTimePeriodOptions(t)}
                  control={control}
                  errors={errors}
                  selectedRender={renderTimePeroid(watch("timePeroid"))}
                />
              </div>
              <div className="min-h-[200px] border-b border-dashed border-black px-2 py-10 md:px-16">
                <div className="mb-4 font-bold">
                  <div className="mb-2 text-2xl leading-6">{t("slogan")}</div>
                  <div className="text-sm text-[#757575]">
                    {t("str_slogan_description")}
                  </div>
                </div>
                <CommonTextField
                  type="text"
                  control={control}
                  errors={errors}
                  variant="outlined"
                  name="slogan"
                  placeholder={t("str_slogan_placeholder")}
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: "black",
                      },
                    },
                  }}
                />
              </div>
              <div className="px-2 py-10 md:px-16">
                <div className="text-sm font-medium text-red-500">
                  {t("str_create_collection_chainpin")}
                </div>
              </div>
              <div className="mb-16 mt-8 flex justify-center">
                <CommonButton
                  variant="contained"
                  onClick={handleSubmit(onSave)}
                  disabled={loading}
                  sx={{
                    minWidth: "376px",
                    paddingY: 2,
                    borderRadius: 2,
                    fontSize: "20px",
                    backgroundColor: "#000000",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#000000e9",
                    },
                  }}
                >
                  {loading ? t("loading") : t("save")}
                </CommonButton>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  ) : (
    <PinSuccess
      selectedNFT={
        selectedNFT ? selectedNFT : (rawData.value?.nft as NFTItemDto)
      }
    />
  )
}
