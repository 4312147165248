import { MenuItem } from "@/core/types/Options"

export const langMenus = [
  {
    name: "en-US",
    translation: "en",
  },
  {
    name: "ja-JP",
    translation: "ja",
  },
]

export const menus: Array<MenuItem> = [
  {
    id: "account",
    label: "account",
    path: "/account",
  },
  {
    id: "pin_generate",
    label: "pin_generate",
    path: "/pin-generate",
  },
]

export const accountMenus: Array<string> = [
  "basic_collection",
  "sequential_collection",
]

export const accountFilterMenus: Array<string> = ["all", "pin", "unpin"]

export const activeMenus: Array<string> = ["all", "holding", "transferred"]

export const pinStepMenus: Array<string> = [
  "nft_set_up_by_qr",
  "sign_up_site_by_qr",
  "confirm_information",
  "confirm",
]

export const pinSequentialStepMenus: Array<string> = [
  "confirm_nft",
  "sign_up_site_by_qr",
  "confirm_information",
  "confirm",
]
