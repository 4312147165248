import React from "react"
import Blockies, { IdenticonProps } from "react-blockies"

export type AvatarBlockProps = IdenticonProps

export const AvatarBlock: React.FunctionComponent<AvatarBlockProps> = props => {
  return (
    <Blockies size={10} scale={4} {...props} seed={props.seed || "Jeremy"} />
  )
}
