import { useState, useEffect, useRef } from "react"

const useCheckHeight = (lineHeight = 24) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [showMoreButton, setShowMoreButton] = useState(false)
  const textRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const checkHeight = () => {
      const element = textRef.current
      if (element) {
        setShowMoreButton(element.scrollHeight > lineHeight)
      }
    }
    checkHeight()

    window.addEventListener("resize", checkHeight)
    return () => {
      window.removeEventListener("resize", checkHeight)
    }
  }, [lineHeight])

  return {
    isExpanded,
    setIsExpanded,
    showMoreButton,
    setShowMoreButton,
    textRef,
  }
}

export default useCheckHeight
