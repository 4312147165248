import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material"
import React, { InputHTMLAttributes, ReactElement } from "react"
import { Option } from "@/core/types/Options"
import { Control, Controller } from "react-hook-form"
export type CommonRadioProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "value"
> & {
  radioOptions: Array<Option<string>>
  errors: { [x: string]: any }
  control: Control<any>
  name: string
  selectedRender?: ReactElement | null
  disabled?: boolean
}

export const CommonRadioGroup: React.FunctionComponent<CommonRadioProps> = ({
  radioOptions,
  errors,
  control,
  name,
  selectedRender,
  disabled,
}) => {
  return (
    <FormControl fullWidth error={errors[name]}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <RadioGroup {...field}>
            {radioOptions.map((item, index) => (
              <div key={index}>
                <FormControlLabel
                  disabled={disabled}
                  value={item.value}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#000",
                        },
                      }}
                    />
                  }
                  label={item.label}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontWeight: 700,
                      fontSize: 16,
                    },
                  }}
                />
                {item.value === field.value && selectedRender && selectedRender}
              </div>
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}
