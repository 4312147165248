import { AxiosInstance, AxiosRequestConfig } from "axios"
import { AppContext } from "@/AppContext"
import { checkNeedJwtToken } from "@/core/utils/decodeToken"

function setToken(config: AxiosRequestConfig): void {
  config.headers!["x-api-key"] = import.meta.env.VITE_API_KEY
  const token = AppContext.getJwtToken()
  if (token && !config.headers?.Authorization) {
    config.headers!["Authorization"] = `Bearer ${token}`
  }
}

export function checkIsRefreshingTokenDone(
  resolve: (value: void | PromiseLike<void>) => void
): void {
  if (!AppContext.getJwtToken()) {
    setTimeout(() => {
      checkIsRefreshingTokenDone(resolve)
    }, 200)
  } else {
    resolve()
  }
}

async function waitGetTokenDone(): Promise<void> {
  return new Promise(resolve => {
    checkIsRefreshingTokenDone(resolve)
  })
}

export function setInterceptors(service: AxiosInstance): void {
  service.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      if (checkNeedJwtToken(config.url || "") && !AppContext.getJwtToken()) {
        await waitGetTokenDone()
      }
      setToken(config)
      return config
    },
    error => Promise.reject(error)
  )

  service.interceptors.response.use(
    response => response,
    error => {
      // for local test
      if (process.env.NODE_ENV === "test") {
        return Promise.reject(error)
      }

      return Promise.reject(error)
    }
  )
}
