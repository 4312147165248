import React, { PropsWithChildren } from "react"
import { twMerge } from "tailwind-merge"

export type CommonSelectProps =
  React.SelectHTMLAttributes<HTMLSelectElement> & {
    className?: string
    wrapperClass?: string
  }

export const CommonSelect: React.FunctionComponent<
  PropsWithChildren<CommonSelectProps>
> = ({ children, className, wrapperClass, ...props }) => {
  return (
    <div
      className={twMerge(
        "relative inline-flex cursor-pointer items-center",
        wrapperClass
      )}
    >
      <select
        {...props}
        className={twMerge(
          "h-10 w-full cursor-pointer rounded border-r-8 border-transparent px-2 indent-1 outline outline-2 outline-[#DCDDDF] xl:h-9",
          className
        )}
      >
        {children}
      </select>
    </div>
  )
}
