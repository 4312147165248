import React from "react"
import { OtherInjectConnector } from "@/components/common/blockchain/OtherInjectConnector"
import { DiverWalletConnector } from "@/components/common/blockchain/DiverWalletConnector"

export type ConnectWalletPopupProps = { handleClose: () => void }

export const ConnectWalletPopup: React.FunctionComponent<
  ConnectWalletPopupProps
> = ({ handleClose }) => {
  const testModel = sessionStorage.getItem("metamask_connect")

  return (
    <>
      {testModel === "true" ? (
        <OtherInjectConnector handleClose={handleClose} />
      ) : (
        <DiverWalletConnector handleClose={handleClose} />
      )}
    </>
  )
}
