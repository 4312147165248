import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { TimePickerProps } from "@mui/x-date-pickers/TimePicker"
import { Box, useTheme } from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

import React, { useState } from "react"
import { Control, Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { endOfDay, isBefore } from "date-fns"

export type CommonTimeRangePickerProps = {
  errors: { [x: string]: any }
  control: Control<any>
  name: string
  onChange?: void
  index?: number
  defaultFromValue?: Date
  defaultToValue?: Date
} & Omit<TimePickerProps<Date>, "onChange" | "renderInput" | "value">

export const CommonTimeRangePicker: React.FunctionComponent<
  CommonTimeRangePickerProps
> = ({
  control,
  name,
  onChange,
  index,
  defaultFromValue,
  defaultToValue,
  ...props
}) => {
  const { t } = useTranslation()
  const { palette } = useTheme()
  const [open, setOpen] = useState("")
  const [endOpen, setEndOpen] = useState("")
  const [, setFocused] = useState(false)
  const [, setEndFocused] = useState(false)
  const inputSx = {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#ffffff",
      borderColor: "#00000033",
      borderRadius: 2,
      width: "120px",
      height: "44px",
      minHeight: 50,
      "& fieldset": {
        borderColor: "#E0E3E7",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  }
  const onTimeChange = (
    oldValue: Array<Array<Date>>,
    event: Date | null,
    action: "start" | "end"
  ) => {
    const returnValue = oldValue.map((item, _index) => {
      if (!event) return item
      if (_index === index) {
        if (action === "start") {
          return [
            event,
            !item[1] || isBefore(item[1], event)
              ? endOfDay(new Date())
              : item[1],
          ]
        } else {
          return [item[0], event]
        }
      }
      return item
    })
    return returnValue
  }
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="flex items-center justify-between">
              <TimePicker
                ampm={false}
                disableIgnoringDatePartForTimeValidation={true}
                open={!!open}
                format="HH:mm"
                onClose={() => setOpen("")}
                {...props}
                value={
                  index !== undefined && field.value[index]?.[0]
                    ? defaultFromValue || field.value[index][0]
                    : defaultFromValue || field.value[0]
                }
                sx={inputSx}
                onChange={() => {}}
                onAccept={event =>
                  onChange ||
                  field.onChange(onTimeChange(field.value, event, "start"))
                }
                slots={{
                  openPickerIcon: KeyboardArrowDownIcon,
                }}
                slotProps={{
                  textField: {
                    InputProps: {
                      className: "dateField",
                      placeholder: t("start_date"),
                      readOnly: true,
                    },
                    role: "button",
                    onClick: () => setOpen(open === "start" ? "" : "start"),
                    onBlur: () => {
                      setFocused(false)
                      field.onBlur()
                    },
                    onFocus: () => {
                      setFocused(true)
                      setEndFocused(false)
                    },
                  },
                }}
              />
              <Box
                className="mx-2 text-[24px]"
                sx={{ color: palette.text.secondary }}
              >
                {"~"}
              </Box>
              <TimePicker
                ampm={false}
                disableIgnoringDatePartForTimeValidation={true}
                open={!!endOpen}
                minTime={
                  index !== undefined && field.value[index]?.[0]
                    ? defaultFromValue || field.value[index][0]
                    : defaultFromValue || field.value[0]
                }
                format="HH:mm"
                onClose={() => setEndOpen("")}
                {...props}
                value={
                  index !== undefined && field.value[index]?.[1]
                    ? defaultToValue || field.value[index][1]
                    : defaultToValue || field.value[1]
                }
                sx={inputSx}
                onChange={() => {}}
                onAccept={event =>
                  onChange ||
                  field.onChange(onTimeChange(field.value, event, "end"))
                }
                slots={{
                  openPickerIcon: KeyboardArrowDownIcon,
                }}
                slotProps={{
                  textField: {
                    InputProps: {
                      readOnly: true,
                    },
                    role: "button",
                    onClick: () => setEndOpen(open === "start" ? "" : "start"),
                    onBlur: () => {
                      setEndFocused(false)
                      field.onBlur()
                    },
                    onFocus: () => {
                      setFocused(false)
                      setEndFocused(true)
                    },
                  },
                }}
              />
            </div>
          </LocalizationProvider>
        </>
      )}
    />
  )
}
