import React, { useState, useEffect } from "react"
import { CommonButton } from "@/components/common/CommonButton"
import { CommonCheckboxGroup } from "@/components/common/form/CommonCheckboxGroup"
import { PinNFTReviewRequest } from "@/core/types/Request"
import { useTranslation } from "react-i18next"
import { format, fromUnixTime } from "date-fns"
import { TimePeriodEnum } from "@/core/enum/TimePeriodEnum"
import { getTermsOptions } from "@/core/constant/options"
import { CircularProgress } from "@mui/material"
import { AppContext } from "@/AppContext"

type Props = {
  back: () => void
  next: () => void
  formState: PinNFTReviewRequest | undefined
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

export default function PinReview({
  back,
  next,
  formState,
  setSuccess,
}: Props) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [checkedState, setCheckedState] = useState({
    risk: false,
    responsible: false,
    agree: false,
  })

  const pinNFT = async () => {
    setLoading(true)
    if (formState) {
      try {
        await AppContext.apiExecutor.pinNFT({
          nftID: formState.nftID,
          geo: formState.geo,
          start_date: formState.start_date,
          end_date: formState.end_date,
          user_address: formState.user_address,
          available_time: formState.available_time,
          timePeroid: formState.timePeroid,
          slogan: formState.slogan,
        })
        setSuccess(true)
        next()
      } catch (err) {
        console.error(err)
        setSuccess(false)
        next()
      } finally {
        setLoading(false)
      }
    }
  }

  const renderTime = () => {
    switch (formState?.timePeroid) {
      case TimePeriodEnum.FIXED_TIME_PEROID: {
        let avaliableTime = new Array<string>()
        for (const dateTime of formState.available_time ?? []) {
          const startTime = format(
            fromUnixTime(dateTime.from as number),
            "HH:mm"
          )
          const endTime = format(fromUnixTime(dateTime.to as number), "HH:mm")
          avaliableTime.push(`${startTime} ~ ${endTime}`)
        }
        avaliableTime = Array.from(new Set(avaliableTime))
        return (
          <div className="mb-2 flex">
            <div>
              <span>{`${format(
                fromUnixTime(formState.start_date),
                "yyyy-MM-dd"
              )}`}</span>
              <span className="mx-2">~</span>
              <span className="me-2">{`${format(
                fromUnixTime(formState.end_date),
                "yyyy-MM-dd"
              )}`}</span>
              <div>
                {avaliableTime.map((time, index) => (
                  <span
                    key={index}
                    className={`${
                      index + 1 !== avaliableTime.length &&
                      avaliableTime.length > 1
                        ? "me-2 border-e-2  border-gray-400 pe-2 "
                        : ""
                    }`}
                  >
                    <span>{time}</span>
                  </span>
                ))}
              </div>
            </div>
          </div>
        )
      }

      case TimePeriodEnum.CUSTOM_TIME_PEROID: {
        const weekMap = new Map()
        for (const dateTime of formState.available_time ?? []) {
          const week = format(
            fromUnixTime(dateTime.from as number),
            "EEEE"
          ).toLocaleLowerCase()
          const startTime = format(
            fromUnixTime(dateTime.from as number),
            "HH:mm"
          )
          const endTime = format(fromUnixTime(dateTime.to as number), "HH:mm")

          if (weekMap.has(week)) {
            weekMap.set(week, [
              ...new Set([...weekMap.get(week), `${startTime} ~ ${endTime}`]),
            ])
          } else {
            weekMap.set(week, [`${startTime} ~ ${endTime}`])
          }
        }
        return (
          <div className="mb-2 flex">
            <div className="w-full flex-1">
              <span>{`${format(
                fromUnixTime(formState.start_date),
                "yyyy-MM-dd"
              )}`}</span>
              <span className="mx-2">~</span>
              <span className="me-2">{`${format(
                fromUnixTime(formState.end_date),
                "yyyy-MM-dd"
              )}`}</span>
              <div>
                {Array.from(weekMap.entries()).map(([key, value]) => (
                  <div key={key} className="py-1">
                    <span className="me-2">{t(`option_${key}`)} :</span>
                    <div className="my-1 ms-[10%]">
                      {value.map((time: string, index: number) => (
                        <span
                          key={index}
                          className={`${
                            index + 1 !== value.length && value.length > 1
                              ? "me-2 border-e-2  border-gray-400 pe-2 "
                              : ""
                          }`}
                        >
                          <span>{time}</span>
                        </span>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      }
      default: {
        return (
          <div className="mb-2">
            {formState?.end_date === 4102415999 ? (
              t("option_no_limit")
            ) : (
              <div className="inline-block">
                <span>{`${format(
                  fromUnixTime(formState?.start_date as number),
                  "yyyy-MM-dd"
                )}`}</span>
                <span className="mx-2">~</span>
                <span>{`${format(
                  fromUnixTime(formState?.end_date as number),
                  "yyyy-MM-dd"
                )}`}</span>
              </div>
            )}
          </div>
        )
      }
    }
  }

  const renderTimePeroid = (): string => {
    switch (formState?.timePeroid) {
      case TimePeriodEnum.NO_TIME_LIMIT:
        return t("option_no_time_limit")
      case TimePeriodEnum.FIXED_TIME_PEROID:
        return t("option_fixed_time_peroid")
      case TimePeriodEnum.CUSTOM_TIME_PEROID:
        return t("option_custom_time_peroid")
    }
    return ""
  }

  const renderSection = (title: string, content: React.ReactNode) => (
    <div className="py-4 text-xl md:py-6">
      <div className="mb-2 text-[#AEAEAE]">{title}</div>
      <div className="font-bold leading-9">{content}</div>
    </div>
  )

  useEffect(() => {
    Object.values(checkedState).find(item => item === false) === undefined
      ? setChecked(true)
      : setChecked(false)
  }, [checkedState])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedState({
      ...checkedState,
      [event.target.name]: event.target.checked,
    })
  }

  return (
    <div className="pb-6">
      {formState && (
        <div className="border-b border-dashed border-black px-6 md:px-12">
          {renderSection(t("address"), formState.geo.address)}
          {renderSection(
            `${t("range")}  (${t("distance_receive_nft")})`,
            formState.geo.distance === 0
              ? t("option_no_limit")
              : formState.geo.distance
          )}
          {renderSection(t("start_day_end_day"), renderTime())}
          {renderSection(t("time_period_title"), renderTimePeroid())}
          {formState.slogan && renderSection(t("slogan"), formState.slogan)}
        </div>
      )}
      <div className="p-6 md:p-12">
        <div className="mb-4 text-sm font-medium text-red-500 md:mb-6">
          {t("str_create_collection_chainpin")}
        </div>
        <div className="mb-12 flex items-start md:mb-20">
          <CommonCheckboxGroup
            checkboxOptions={getTermsOptions(t)}
            onChange={handleChange}
            checkedState={checkedState}
          />
        </div>
        <div className="flex flex-col justify-center gap-6 text-center md:flex-row">
          <CommonButton
            variant="outlined"
            sx={{
              borderRadius: 2,
              paddingY: "15px",
              paddingX: 16,
              minWidth: "320px",
              borderColor: "#000",
              color: "#000",
              "&:hover": {
                borderColor: "#000",
                bgcolor: "#00000009",
              },
            }}
            disabled={loading}
            onClick={back}
          >
            {t("back")}
          </CommonButton>
          <CommonButton
            variant="contained"
            sx={{
              bgcolor: "#000",
              borderRadius: 2,
              paddingY: "15px",
              paddingX: 16,
              minWidth: "320px",
              "&:hover": {
                bgcolor: "#000000d9",
              },
            }}
            disabled={!checked || loading}
            onClick={pinNFT}
          >
            {loading ? (
              <CircularProgress
                size="1.5rem"
                sx={{
                  color: "#000",
                }}
              />
            ) : (
              t("create")
            )}
          </CommonButton>
        </div>
      </div>
    </div>
  )
}
