import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormHelperText,
} from "@mui/material"
import React, { InputHTMLAttributes, ReactElement } from "react"
import { Option } from "@/core/types/Options"
import { Control, Controller } from "react-hook-form"
export type CommonCheckboxProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "value"
> & {
  checkboxOptions: Array<Option<string> & { selectedRender?: ReactElement }>
  errors: { [x: string]: any }
  control: Control<any>
  name: string
}

export const CommonCheckbox: React.FunctionComponent<CommonCheckboxProps> = ({
  checkboxOptions,
  errors,
  control,
  name,
}) => {
  return (
    <FormControl fullWidth error={!!errors[name]}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <>
            {errors[name] && (
              <FormHelperText error sx={{ margin: "3px 14px" }}>
                {errors[name]?.message}
              </FormHelperText>
            )}
            <FormGroup>
              {checkboxOptions.map((item, index) => (
                <div key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value?.includes(item.value)}
                        onChange={() => {
                          if (!field.value.includes(item.value)) {
                            field.onChange([...field.value, item.value])
                            return
                          }
                          const newTopics = field.value.filter(
                            (topic: string) => topic !== item.value
                          )
                          field.onChange(newTopics)
                          field.onBlur()
                        }}
                        sx={{
                          "&.Mui-checked": {
                            color: "#000",
                          },
                        }}
                      />
                    }
                    label={item.label}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontWeight: 500,
                        fontSize: 18,
                      },
                    }}
                  />
                  {field.value.includes(item.value) &&
                    item.selectedRender &&
                    item.selectedRender}
                </div>
              ))}
            </FormGroup>
          </>
        )}
      />
    </FormControl>
  )
}
