import React, { useState } from "react"
import { AppContext } from "@/AppContext"
import { useAsync } from "react-use"
import { useParams } from "react-router-dom"
import { LoadingWrapper } from "@/components/common/loading/LoadingWrapper"
import { CollectionContainer } from "@/components/collection/CollectionContainer"
import { ContractTypeEnum } from "@/core/enum/ContractTypeEnum"

export default function CollectionDetail() {
  const collectionNFTPageSize = 16
  const { id } = useParams()
  const [page, setPage] = useState(1)
  const [active, setActive] = useState("all")

  const rawData = useAsync(async () => {
    const res = await AppContext.apiExecutor.getCollections(
      "all",
      1,
      1,
      undefined,
      ContractTypeEnum.NORMAL,
      id
    )

    return res.data.result?.item_list?.[0]
  }, [])

  const rawNFTData = useAsync(async () => {
    if (
      id &&
      AppContext.getAuthAddress() &&
      rawData.value &&
      rawData.value.contract_address
    ) {
      const nftRes = await AppContext.apiExecutor.getNFTs(
        active,
        page,
        collectionNFTPageSize,
        rawData.value.contract_address
      )
      return nftRes.data
    } else {
      return {
        code: "",
        result: {
          item_list: [],
          total_page: 0,
          total_item_size: 0,
          page: 0,
        },
      }
    }
  }, [AppContext.getAuthAddress, page, rawData.value, active, id])

  return (
    <LoadingWrapper
      state={rawData}
      keepLoading={data => !data}
      render={data => (
        <CollectionContainer
          collectionData={data}
          rawNFTData={rawNFTData}
          page={page}
          setPage={setPage}
          collectionNFTPageSize={collectionNFTPageSize}
          active={active}
          setActive={setActive}
        />
      )}
    />
  )
}
