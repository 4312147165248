/* tslint:disable */
/* eslint-disable */
/**
 * chain-pin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BaseCommonResponse
 */
export interface BaseCommonResponse {
    /**
     * 
     * @type {string}
     * @memberof BaseCommonResponse
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof BaseCommonResponse
     */
    result: string;
}
/**
 * 
 * @export
 * @interface BasePinNFTResponse
 */
export interface BasePinNFTResponse {
    /**
     * 
     * @type {string}
     * @memberof BasePinNFTResponse
     */
    code: string;
    /**
     * 
     * @type {BasePinNFTResponseResult}
     * @memberof BasePinNFTResponse
     */
    result: BasePinNFTResponseResult;
}
/**
 * 
 * @export
 * @interface BasePinNFTResponseResult
 */
export interface BasePinNFTResponseResult {
    /**
     * 
     * @type {string}
     * @memberof BasePinNFTResponseResult
     */
    obj_id?: string;
}
/**
 * 
 * @export
 * @interface BaseResponseGetCollectionsDto
 */
export interface BaseResponseGetCollectionsDto {
    /**
     * 
     * @type {string}
     * @memberof BaseResponseGetCollectionsDto
     */
    code: string;
    /**
     * 
     * @type {BaseResponseGetCollectionsDtoResult}
     * @memberof BaseResponseGetCollectionsDto
     */
    result: BaseResponseGetCollectionsDtoResult;
}
/**
 * 
 * @export
 * @interface BaseResponseGetCollectionsDtoResult
 */
export interface BaseResponseGetCollectionsDtoResult {
    /**
     * 
     * @type {Array<CollectionItemDto>}
     * @memberof BaseResponseGetCollectionsDtoResult
     */
    item_list?: Array<CollectionItemDto>;
    /**
     * 
     * @type {number}
     * @memberof BaseResponseGetCollectionsDtoResult
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseResponseGetCollectionsDtoResult
     */
    total_item_size?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseResponseGetCollectionsDtoResult
     */
    total_page?: number;
}
/**
 * 
 * @export
 * @interface BaseResponseGetNFTItemsDto
 */
export interface BaseResponseGetNFTItemsDto {
    /**
     * 
     * @type {CollectionItemDto}
     * @memberof BaseResponseGetNFTItemsDto
     */
    collection: CollectionItemDto;
    /**
     * 
     * @type {NFTItemDto}
     * @memberof BaseResponseGetNFTItemsDto
     */
    nft: NFTItemDto;
    /**
     * 
     * @type {PinNFTDto}
     * @memberof BaseResponseGetNFTItemsDto
     */
    pin: PinNFTDto;
}
/**
 * 
 * @export
 * @interface BaseResponseGetNFTsDto
 */
export interface BaseResponseGetNFTsDto {
    /**
     * 
     * @type {string}
     * @memberof BaseResponseGetNFTsDto
     */
    code: string;
    /**
     * 
     * @type {BaseResponseGetNFTsDtoResult}
     * @memberof BaseResponseGetNFTsDto
     */
    result: BaseResponseGetNFTsDtoResult;
}
/**
 * 
 * @export
 * @interface BaseResponseGetNFTsDtoResult
 */
export interface BaseResponseGetNFTsDtoResult {
    /**
     * 
     * @type {Array<BaseResponseGetNFTItemsDto>}
     * @memberof BaseResponseGetNFTsDtoResult
     */
    item_list?: Array<BaseResponseGetNFTItemsDto>;
    /**
     * 
     * @type {number}
     * @memberof BaseResponseGetNFTsDtoResult
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseResponseGetNFTsDtoResult
     */
    total_item_size?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseResponseGetNFTsDtoResult
     */
    total_page?: number;
}
/**
 * 
 * @export
 * @interface BaseResponseGetNonceDto
 */
export interface BaseResponseGetNonceDto {
    /**
     * 
     * @type {string}
     * @memberof BaseResponseGetNonceDto
     */
    code: string;
    /**
     * 
     * @type {BaseResponseGetNonceDtoResult}
     * @memberof BaseResponseGetNonceDto
     */
    result: BaseResponseGetNonceDtoResult;
}
/**
 * 
 * @export
 * @interface BaseResponseGetNonceDtoResult
 */
export interface BaseResponseGetNonceDtoResult {
    /**
     * 
     * @type {number}
     * @memberof BaseResponseGetNonceDtoResult
     */
    nonce?: number;
}
/**
 * 
 * @export
 * @interface BaseResponseLogin
 */
export interface BaseResponseLogin {
    /**
     * 
     * @type {string}
     * @memberof BaseResponseLogin
     */
    code: string;
    /**
     * 
     * @type {BaseResponseLoginResult}
     * @memberof BaseResponseLogin
     */
    result: BaseResponseLoginResult;
}
/**
 * 
 * @export
 * @interface BaseResponseLoginResult
 */
export interface BaseResponseLoginResult {
    /**
     * 
     * @type {string}
     * @memberof BaseResponseLoginResult
     */
    jwt?: string;
}
/**
 * 
 * @export
 * @interface CollectionItemDto
 */
export interface CollectionItemDto {
    /**
     * 
     * @type {number}
     * @memberof CollectionItemDto
     */
    collectionID: number;
    /**
     * 
     * @type {string}
     * @memberof CollectionItemDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionItemDto
     */
    logo_img_url: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionItemDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionItemDto
     */
    contract_address: string;
    /**
     * 
     * @type {number}
     * @memberof CollectionItemDto
     */
    create_date: number;
    /**
     * 
     * @type {number}
     * @memberof CollectionItemDto
     */
    modify_date?: number;
    /**
     * 
     * @type {string}
     * @memberof CollectionItemDto
     */
    banner_img_url?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionItemDto
     */
    feature_img_url?: string;
    /**
     * normal or sequential
     * @type {string}
     * @memberof CollectionItemDto
     */
    contract_type: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionItemDto
     */
    companyHash: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionItemDto
     */
    pin_counts: string;
    /**
     * 
     * @type {PinNFTDto}
     * @memberof CollectionItemDto
     */
    pin?: PinNFTDto;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface GeographicDto
 */
export interface GeographicDto {
    /**
     * 
     * @type {number}
     * @memberof GeographicDto
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof GeographicDto
     */
    lon: number;
    /**
     * 
     * @type {number}
     * @memberof GeographicDto
     */
    distance: number;
    /**
     * 
     * @type {string}
     * @memberof GeographicDto
     */
    address: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    code?: string;
    /**
     * 
     * @type {InlineResponse200Result}
     * @memberof InlineResponse200
     */
    result?: InlineResponse200Result;
}
/**
 * 
 * @export
 * @interface InlineResponse200Result
 */
export interface InlineResponse200Result {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200Result
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200Result
     */
    pinID?: string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    sign: string;
}
/**
 * 
 * @export
 * @interface NFTItemDto
 */
export interface NFTItemDto {
    /**
     * 
     * @type {string}
     * @memberof NFTItemDto
     */
    _id: string;
    /**
     * 
     * @type {string}
     * @memberof NFTItemDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof NFTItemDto
     */
    tokenID: string;
    /**
     * 
     * @type {string}
     * @memberof NFTItemDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NFTItemDto
     */
    owner: string;
    /**
     * 
     * @type {string}
     * @memberof NFTItemDto
     */
    image: string;
    /**
     * 
     * @type {string}
     * @memberof NFTItemDto
     */
    metadata_uri: string;
    /**
     * 
     * @type {NFTItemMetadataDto}
     * @memberof NFTItemDto
     */
    metadata: NFTItemMetadataDto;
    /**
     * 
     * @type {string}
     * @memberof NFTItemDto
     */
    standard: string;
    /**
     * 
     * @type {string}
     * @memberof NFTItemDto
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof NFTItemDto
     */
    blockchain: string;
    /**
     * 
     * @type {number}
     * @memberof NFTItemDto
     */
    supply: number;
}
/**
 * 
 * @export
 * @interface NFTItemMetadataDto
 */
export interface NFTItemMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof NFTItemMetadataDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NFTItemMetadataDto
     */
    subname: string;
    /**
     * 
     * @type {string}
     * @memberof NFTItemMetadataDto
     */
    image: string;
    /**
     * 
     * @type {string}
     * @memberof NFTItemMetadataDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof NFTItemMetadataDto
     */
    version?: string;
    /**
     * 
     * @type {Array<NFTItemMetadataSectionDto>}
     * @memberof NFTItemMetadataDto
     */
    sections?: Array<NFTItemMetadataSectionDto>;
    /**
     * 
     * @type {Array<NFTItemMetadataPropertiesDto>}
     * @memberof NFTItemMetadataDto
     */
    properties: Array<NFTItemMetadataPropertiesDto>;
}
/**
 * 
 * @export
 * @interface NFTItemMetadataPropertiesDto
 */
export interface NFTItemMetadataPropertiesDto {
    /**
     * 
     * @type {string}
     * @memberof NFTItemMetadataPropertiesDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NFTItemMetadataPropertiesDto
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof NFTItemMetadataPropertiesDto
     */
    action: string;
    /**
     * 
     * @type {string}
     * @memberof NFTItemMetadataPropertiesDto
     */
    type: string;
}
/**
 * 
 * @export
 * @interface NFTItemMetadataSectionDto
 */
export interface NFTItemMetadataSectionDto {
    /**
     * 
     * @type {string}
     * @memberof NFTItemMetadataSectionDto
     */
    title: string;
    /**
     * 
     * @type {Array<NFTItemMetadataPropertiesDto>}
     * @memberof NFTItemMetadataSectionDto
     */
    elements: Array<NFTItemMetadataPropertiesDto>;
}
/**
 * 
 * @export
 * @interface NFTWeekAvaliableTimeDto
 */
export interface NFTWeekAvaliableTimeDto {
    /**
     * 
     * @type {Array<NftAvaliableTimeDto>}
     * @memberof NFTWeekAvaliableTimeDto
     */
    _1?: Array<NftAvaliableTimeDto>;
    /**
     * 
     * @type {Array<NftAvaliableTimeDto>}
     * @memberof NFTWeekAvaliableTimeDto
     */
    _2?: Array<NftAvaliableTimeDto>;
    /**
     * 
     * @type {Array<NftAvaliableTimeDto>}
     * @memberof NFTWeekAvaliableTimeDto
     */
    _3?: Array<NftAvaliableTimeDto>;
    /**
     * 
     * @type {Array<NftAvaliableTimeDto>}
     * @memberof NFTWeekAvaliableTimeDto
     */
    _4?: Array<NftAvaliableTimeDto>;
    /**
     * 
     * @type {Array<NftAvaliableTimeDto>}
     * @memberof NFTWeekAvaliableTimeDto
     */
    _5?: Array<NftAvaliableTimeDto>;
    /**
     * 
     * @type {Array<NftAvaliableTimeDto>}
     * @memberof NFTWeekAvaliableTimeDto
     */
    _6?: Array<NftAvaliableTimeDto>;
    /**
     * 
     * @type {Array<NftAvaliableTimeDto>}
     * @memberof NFTWeekAvaliableTimeDto
     */
    _7?: Array<NftAvaliableTimeDto>;
}
/**
 * 
 * @export
 * @interface NftAvaliableTimeDto
 */
export interface NftAvaliableTimeDto {
    /**
     * 
     * @type {number}
     * @memberof NftAvaliableTimeDto
     */
    from: number;
    /**
     * 
     * @type {number}
     * @memberof NftAvaliableTimeDto
     */
    to: number;
}
/**
 * 
 * @export
 * @interface PinNFTDto
 */
export interface PinNFTDto {
    /**
     * 
     * @type {string}
     * @memberof PinNFTDto
     */
    _id: string;
    /**
     * 
     * @type {string}
     * @memberof PinNFTDto
     */
    contract_address: string;
    /**
     * 
     * @type {string}
     * @memberof PinNFTDto
     */
    token_ID: string;
    /**
     * 
     * @type {GeographicDto}
     * @memberof PinNFTDto
     */
    geo: GeographicDto;
    /**
     * 
     * @type {string}
     * @memberof PinNFTDto
     */
    pin_code: string;
    /**
     * 
     * @type {boolean}
     * @memberof PinNFTDto
     */
    is_pin: boolean;
    /**
     * 
     * @type {string}
     * @memberof PinNFTDto
     */
    user_address: string;
    /**
     * 
     * @type {string}
     * @memberof PinNFTDto
     */
    create_date: string;
    /**
     * 
     * @type {string}
     * @memberof PinNFTDto
     */
    nft_obj_id: string;
    /**
     * 
     * @type {string}
     * @memberof PinNFTDto
     */
    slogan?: string;
    /**
     * 
     * @type {string}
     * @memberof PinNFTDto
     */
    companyHash?: string;
    /**
     * 
     * @type {number}
     * @memberof PinNFTDto
     */
    start_date_timestamp: number;
    /**
     * 
     * @type {number}
     * @memberof PinNFTDto
     */
    end_date_timestamp: number;
    /**
     * timePeroid or noTimeLimit
     * @type {string}
     * @memberof PinNFTDto
     */
    timePeriod: string;
    /**
     * 
     * @type {Array<NftAvaliableTimeDto>}
     * @memberof PinNFTDto
     */
    available_time: Array<NftAvaliableTimeDto>;
    /**
     * 
     * @type {NFTWeekAvaliableTimeDto}
     * @memberof PinNFTDto
     */
    weeklyTime?: NFTWeekAvaliableTimeDto;
}
/**
 * 
 * @export
 * @interface PinNFTRequest
 */
export interface PinNFTRequest {
    /**
     * 
     * @type {string}
     * @memberof PinNFTRequest
     */
    nftID: string;
    /**
     * 
     * @type {GeographicDto}
     * @memberof PinNFTRequest
     */
    geo: GeographicDto;
    /**
     * 
     * @type {number}
     * @memberof PinNFTRequest
     */
    start_date: number;
    /**
     * 如果無期限, value = -1
     * @type {number}
     * @memberof PinNFTRequest
     */
    end_date: number;
    /**
     * 
     * @type {string}
     * @memberof PinNFTRequest
     */
    user_address: string;
    /**
     * 
     * @type {Array<PinNFTRequestAvailableTime>}
     * @memberof PinNFTRequest
     */
    available_time?: Array<PinNFTRequestAvailableTime>;
    /**
     * 
     * @type {NFTWeekAvaliableTimeDto}
     * @memberof PinNFTRequest
     */
    weeklyTime?: NFTWeekAvaliableTimeDto;
    /**
     * timePeroid or noTimeLimit
     * @type {string}
     * @memberof PinNFTRequest
     */
    timePeroid: string;
    /**
     * PR
     * @type {string}
     * @memberof PinNFTRequest
     */
    slogan?: string;
}
/**
 * 
 * @export
 * @interface PinNFTRequestAvailableTime
 */
export interface PinNFTRequestAvailableTime {
    /**
     * 
     * @type {number}
     * @memberof PinNFTRequestAvailableTime
     */
    from?: number;
    /**
     * 
     * @type {number}
     * @memberof PinNFTRequestAvailableTime
     */
    to?: number;
}
/**
 * 
 * @export
 * @interface UpdatePinNFTRequest
 */
export interface UpdatePinNFTRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePinNFTRequest
     */
    nftID?: string;
    /**
     * 
     * @type {GeographicDto}
     * @memberof UpdatePinNFTRequest
     */
    geo?: GeographicDto;
    /**
     * 
     * @type {number}
     * @memberof UpdatePinNFTRequest
     */
    startDate?: number;
    /**
     * 如果無期限, value = -1
     * @type {number}
     * @memberof UpdatePinNFTRequest
     */
    endDate?: number;
    /**
     * 
     * @type {NFTWeekAvaliableTimeDto}
     * @memberof UpdatePinNFTRequest
     */
    weeklyTime?: NFTWeekAvaliableTimeDto;
    /**
     * 
     * @type {string}
     * @memberof UpdatePinNFTRequest
     */
    timePeriod?: string;
    /**
     * 
     * @type {Array<NftAvaliableTimeDto>}
     * @memberof UpdatePinNFTRequest
     */
    availableTime?: Array<NftAvaliableTimeDto>;
    /**
     * 
     * @type {string}
     * @memberof UpdatePinNFTRequest
     */
    slogan?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [type] all, pin, unpin
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [contractType] normal, sequential
         * @param {string} [collectionid] 
         * @param {string} [orderBy] 
         * @param {'asc' | 'desc'} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollections: async (type?: string, page?: number, pageSize?: number, search?: string, contractType?: string, collectionid?: string, orderBy?: string, order?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/collects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (contractType !== undefined) {
                localVarQueryParameter['contract_type'] = contractType;
            }

            if (collectionid !== undefined) {
                localVarQueryParameter['collectionid'] = collectionid;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [type] pin&#x3D;取得pin過的nft all&#x3D;取得所有的nft unpin&#x3D;取得沒被pin的nft
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [contractAddress] 
         * @param {string} [tokenID] 
         * @param {string} [search] 
         * @param {string} [companyHash] 
         * @param {string} [contractType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNFTs: async (type?: string, page?: number, pageSize?: number, contractAddress?: string, tokenID?: string, search?: string, companyHash?: string, contractType?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/nfts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (contractAddress !== undefined) {
                localVarQueryParameter['contractAddress'] = contractAddress;
            }

            if (tokenID !== undefined) {
                localVarQueryParameter['tokenID'] = tokenID;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (companyHash !== undefined) {
                localVarQueryParameter['companyHash'] = companyHash;
            }

            if (contractType !== undefined) {
                localVarQueryParameter['contract_type'] = contractType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 取得隨機碼
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNonce: async (address: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'address' is not null or undefined
            assertParamExists('getNonce', 'address', address)
            const localVarPath = `/nonce`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest: LoginRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('login', 'loginRequest', loginRequest)
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PinNFTRequest} pinNFTRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinNFT: async (pinNFTRequest: PinNFTRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pinNFTRequest' is not null or undefined
            assertParamExists('pinNFT', 'pinNFTRequest', pinNFTRequest)
            const localVarPath = `/pin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pinNFTRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} objId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unPinNFT: async (objId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'objId' is not null or undefined
            assertParamExists('unPinNFT', 'objId', objId)
            const localVarPath = `/unpin/{obj_id}`
                .replace(`{${"obj_id"}}`, encodeURIComponent(String(objId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 符合以下幾種情境  1. 更新NFT, 須包含nftID, startDate, endDate, timePeriod, avaliableTime  2. 更新日期時間, 須包含startDate, endDate, timePeriod, avaliableTime  3. 其餘可獨立 
         * @summary Update the pin info
         * @param {string} xApiKey 
         * @param {string} authorization 
         * @param {string} pinID 
         * @param {UpdatePinNFTRequest} updatePinNFTRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePinNFT: async (xApiKey: string, authorization: string, pinID: string, updatePinNFTRequest: UpdatePinNFTRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('updatePinNFT', 'xApiKey', xApiKey)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updatePinNFT', 'authorization', authorization)
            // verify required parameter 'pinID' is not null or undefined
            assertParamExists('updatePinNFT', 'pinID', pinID)
            // verify required parameter 'updatePinNFTRequest' is not null or undefined
            assertParamExists('updatePinNFT', 'updatePinNFTRequest', updatePinNFTRequest)
            const localVarPath = `/pin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pinID !== undefined) {
                localVarQueryParameter['pinID'] = pinID;
            }

            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePinNFTRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [type] all, pin, unpin
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [contractType] normal, sequential
         * @param {string} [collectionid] 
         * @param {string} [orderBy] 
         * @param {'asc' | 'desc'} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollections(type?: string, page?: number, pageSize?: number, search?: string, contractType?: string, collectionid?: string, orderBy?: string, order?: 'asc' | 'desc', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponseGetCollectionsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollections(type, page, pageSize, search, contractType, collectionid, orderBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [type] pin&#x3D;取得pin過的nft all&#x3D;取得所有的nft unpin&#x3D;取得沒被pin的nft
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [contractAddress] 
         * @param {string} [tokenID] 
         * @param {string} [search] 
         * @param {string} [companyHash] 
         * @param {string} [contractType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNFTs(type?: string, page?: number, pageSize?: number, contractAddress?: string, tokenID?: string, search?: string, companyHash?: string, contractType?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponseGetNFTsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNFTs(type, page, pageSize, contractAddress, tokenID, search, companyHash, contractType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 取得隨機碼
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNonce(address: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponseGetNonceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNonce(address, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest: LoginRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponseLogin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PinNFTRequest} pinNFTRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinNFT(pinNFTRequest: PinNFTRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasePinNFTResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pinNFT(pinNFTRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} objId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unPinNFT(objId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseCommonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unPinNFT(objId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 符合以下幾種情境  1. 更新NFT, 須包含nftID, startDate, endDate, timePeriod, avaliableTime  2. 更新日期時間, 須包含startDate, endDate, timePeriod, avaliableTime  3. 其餘可獨立 
         * @summary Update the pin info
         * @param {string} xApiKey 
         * @param {string} authorization 
         * @param {string} pinID 
         * @param {UpdatePinNFTRequest} updatePinNFTRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePinNFT(xApiKey: string, authorization: string, pinID: string, updatePinNFTRequest: UpdatePinNFTRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePinNFT(xApiKey, authorization, pinID, updatePinNFTRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [type] all, pin, unpin
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [contractType] normal, sequential
         * @param {string} [collectionid] 
         * @param {string} [orderBy] 
         * @param {'asc' | 'desc'} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollections(type?: string, page?: number, pageSize?: number, search?: string, contractType?: string, collectionid?: string, orderBy?: string, order?: 'asc' | 'desc', options?: any): AxiosPromise<BaseResponseGetCollectionsDto> {
            return localVarFp.getCollections(type, page, pageSize, search, contractType, collectionid, orderBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [type] pin&#x3D;取得pin過的nft all&#x3D;取得所有的nft unpin&#x3D;取得沒被pin的nft
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [contractAddress] 
         * @param {string} [tokenID] 
         * @param {string} [search] 
         * @param {string} [companyHash] 
         * @param {string} [contractType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNFTs(type?: string, page?: number, pageSize?: number, contractAddress?: string, tokenID?: string, search?: string, companyHash?: string, contractType?: string, options?: any): AxiosPromise<BaseResponseGetNFTsDto> {
            return localVarFp.getNFTs(type, page, pageSize, contractAddress, tokenID, search, companyHash, contractType, options).then((request) => request(axios, basePath));
        },
        /**
         * 取得隨機碼
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNonce(address: string, options?: any): AxiosPromise<BaseResponseGetNonceDto> {
            return localVarFp.getNonce(address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: any): AxiosPromise<BaseResponseLogin> {
            return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PinNFTRequest} pinNFTRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinNFT(pinNFTRequest: PinNFTRequest, options?: any): AxiosPromise<BasePinNFTResponse> {
            return localVarFp.pinNFT(pinNFTRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} objId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unPinNFT(objId: string, options?: any): AxiosPromise<BaseCommonResponse> {
            return localVarFp.unPinNFT(objId, options).then((request) => request(axios, basePath));
        },
        /**
         * 符合以下幾種情境  1. 更新NFT, 須包含nftID, startDate, endDate, timePeriod, avaliableTime  2. 更新日期時間, 須包含startDate, endDate, timePeriod, avaliableTime  3. 其餘可獨立 
         * @summary Update the pin info
         * @param {string} xApiKey 
         * @param {string} authorization 
         * @param {string} pinID 
         * @param {UpdatePinNFTRequest} updatePinNFTRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePinNFT(xApiKey: string, authorization: string, pinID: string, updatePinNFTRequest: UpdatePinNFTRequest, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.updatePinNFT(xApiKey, authorization, pinID, updatePinNFTRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - interface
 * @export
 * @interface DefaultApi
 */
export interface DefaultApiInterface {
    /**
     * 
     * @param {string} [type] all, pin, unpin
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [search] 
     * @param {string} [contractType] normal, sequential
     * @param {string} [collectionid] 
     * @param {string} [orderBy] 
     * @param {'asc' | 'desc'} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getCollections(type?: string, page?: number, pageSize?: number, search?: string, contractType?: string, collectionid?: string, orderBy?: string, order?: 'asc' | 'desc', options?: any): AxiosPromise<BaseResponseGetCollectionsDto>;

    /**
     * 
     * @param {string} [type] pin&#x3D;取得pin過的nft all&#x3D;取得所有的nft unpin&#x3D;取得沒被pin的nft
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [contractAddress] 
     * @param {string} [tokenID] 
     * @param {string} [search] 
     * @param {string} [companyHash] 
     * @param {string} [contractType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getNFTs(type?: string, page?: number, pageSize?: number, contractAddress?: string, tokenID?: string, search?: string, companyHash?: string, contractType?: string, options?: any): AxiosPromise<BaseResponseGetNFTsDto>;

    /**
     * 取得隨機碼
     * @param {string} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getNonce(address: string, options?: any): AxiosPromise<BaseResponseGetNonceDto>;

    /**
     * 
     * @param {LoginRequest} loginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    login(loginRequest: LoginRequest, options?: any): AxiosPromise<BaseResponseLogin>;

    /**
     * 
     * @param {PinNFTRequest} pinNFTRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    pinNFT(pinNFTRequest: PinNFTRequest, options?: any): AxiosPromise<BasePinNFTResponse>;

    /**
     * 
     * @param {string} objId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    unPinNFT(objId: string, options?: any): AxiosPromise<BaseCommonResponse>;

    /**
     * 符合以下幾種情境  1. 更新NFT, 須包含nftID, startDate, endDate, timePeriod, avaliableTime  2. 更新日期時間, 須包含startDate, endDate, timePeriod, avaliableTime  3. 其餘可獨立 
     * @summary Update the pin info
     * @param {string} xApiKey 
     * @param {string} authorization 
     * @param {string} pinID 
     * @param {UpdatePinNFTRequest} updatePinNFTRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    updatePinNFT(xApiKey: string, authorization: string, pinID: string, updatePinNFTRequest: UpdatePinNFTRequest, options?: any): AxiosPromise<InlineResponse200>;

}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI implements DefaultApiInterface {
    /**
     * 
     * @param {string} [type] all, pin, unpin
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [search] 
     * @param {string} [contractType] normal, sequential
     * @param {string} [collectionid] 
     * @param {string} [orderBy] 
     * @param {'asc' | 'desc'} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCollections(type?: string, page?: number, pageSize?: number, search?: string, contractType?: string, collectionid?: string, orderBy?: string, order?: 'asc' | 'desc', options?: any) {
        return DefaultApiFp(this.configuration).getCollections(type, page, pageSize, search, contractType, collectionid, orderBy, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [type] pin&#x3D;取得pin過的nft all&#x3D;取得所有的nft unpin&#x3D;取得沒被pin的nft
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [contractAddress] 
     * @param {string} [tokenID] 
     * @param {string} [search] 
     * @param {string} [companyHash] 
     * @param {string} [contractType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNFTs(type?: string, page?: number, pageSize?: number, contractAddress?: string, tokenID?: string, search?: string, companyHash?: string, contractType?: string, options?: any) {
        return DefaultApiFp(this.configuration).getNFTs(type, page, pageSize, contractAddress, tokenID, search, companyHash, contractType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 取得隨機碼
     * @param {string} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNonce(address: string, options?: any) {
        return DefaultApiFp(this.configuration).getNonce(address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginRequest} loginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public login(loginRequest: LoginRequest, options?: any) {
        return DefaultApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PinNFTRequest} pinNFTRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public pinNFT(pinNFTRequest: PinNFTRequest, options?: any) {
        return DefaultApiFp(this.configuration).pinNFT(pinNFTRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} objId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public unPinNFT(objId: string, options?: any) {
        return DefaultApiFp(this.configuration).unPinNFT(objId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 符合以下幾種情境  1. 更新NFT, 須包含nftID, startDate, endDate, timePeriod, avaliableTime  2. 更新日期時間, 須包含startDate, endDate, timePeriod, avaliableTime  3. 其餘可獨立 
     * @summary Update the pin info
     * @param {string} xApiKey 
     * @param {string} authorization 
     * @param {string} pinID 
     * @param {UpdatePinNFTRequest} updatePinNFTRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updatePinNFT(xApiKey: string, authorization: string, pinID: string, updatePinNFTRequest: UpdatePinNFTRequest, options?: any) {
        return DefaultApiFp(this.configuration).updatePinNFT(xApiKey, authorization, pinID, updatePinNFTRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


