import React from "react"
import { OutlinedTextFieldProps } from "@mui/material/TextField/TextField"
import { InputAdornment } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import { Control, Controller } from "react-hook-form"
import { CustomTextField } from "@/components/common/form/widget/CustomTextField"

export type CommonTextFieldProps = OutlinedTextFieldProps & {
  control: Control<any>
  name: string
  errors: { [x: string]: any }
  hideClear?: boolean
}

export const CommonTextField: React.FunctionComponent<CommonTextFieldProps> = ({
  hideClear,
  ...props
}) => {
  const renderEndAdornment = () => {
    return (
      <InputAdornment
        position="end"
        className="cursor-pointer"
        onClick={() => {
          props.onChange &&
            props.onChange({
              target: { value: "" },
            } as unknown as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)
        }}
      >
        <CloseIcon />
      </InputAdornment>
    )
  }

  const setEmptyValue = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      event.target.value === "" &&
      (props.type === "tel" || props.type === "number")
    ) {
      return { target: { value: null } }
    }
    return event
  }

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { value, onChange, onBlur } }) => (
        <CustomTextField
          value={value || ""}
          onBlur={onBlur}
          onChange={event => onChange(setEmptyValue(event))}
          error={!!props.errors[props.name]}
          helperText={props.errors[props.name]?.message}
          InputProps={{
            endAdornment:
              !hideClear &&
              !props.disabled &&
              !!props.value &&
              renderEndAdornment(),
            startAdornment: props.type === "search" && (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          {...props}
        />
      )}
    />
  )
}
