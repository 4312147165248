export type BalanceOf = {
    data: {
        balanceDict: {
            [key: string]: {
                amount: number
            }
        }
        total: number
    }
}

export async function getBalanceOf(
    userAddress: string,
    contractAddress: string,
    tokenList?: string,
): Promise<BalanceOf | undefined> {
    try {
        if (contractAddress) {
            let url = `${import.meta.env.VITE_API_URL}/scan/internal/v1/user/balance?userAddress=${userAddress}&contractAddress=${contractAddress}`
              if (tokenList !== undefined) {
                url += `&tokenList=${tokenList}`
              }
                const response = await fetch(url)
                const data = await response.json()
                return data
          }
    } catch (err) {
        console.error(err)
        return undefined
    }
}