import { PinSetLocationFormRequest } from "@/core/types/Request"
import { PinNFTRequest } from "@/core/api"
import { TimePeriodEnum } from "@/core/enum/TimePeriodEnum"
import { DateRangeEnum } from "@/core/enum/DateRangeEnum"

export const initialPinSetLocationForm: PinSetLocationFormRequest = {
  timeRange: DateRangeEnum.NO_LIMIT,
  dateRange: [null, null],
  distance: 0,
  lat: 0,
  lng: 0,
  slogan: "",
  address: "",
  timePeroid: TimePeriodEnum.NO_TIME_LIMIT,
  fixedTimeArray: [],
  mon: [],
  tue: [],
  wed: [],
  fri: [],
  sat: [],
  sun: [],
  weeks: [],
}

export const initialPinNFTForm: PinNFTRequest = {
  nftID: "",
  geo: {
    lat: 0,
    lon: 0,
    distance: 0,
    address: "",
  },
  user_address: "",
  start_date: 0,
  end_date: 0,
  available_time: [],
  timePeroid: TimePeriodEnum.NO_TIME_LIMIT,
}
