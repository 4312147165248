import React from "react"
import { styled } from "@mui/material/styles"
import { TextField } from "@mui/material"
import { TextFieldProps } from "@mui/material/TextField/TextField"

export const CustomTextField = styled((props: TextFieldProps) => (
  <TextField {...props} fullWidth />
))(() => ({
  "& .MuiOutlinedInput-root": {
    fontFamily: "inherit",
    padding: 0,
    minHeight: 50,
    borderRadius: 10,
    backgroundColor: "#ffffff",
    borderColor: "#949494",
    fieldset: {
      borderColor: "#949494",
      borderWidth: 2,
    },
    "&:hover fieldset": {
      borderWidth: 2,
    },
    "input, textarea": {
      fontSize: 16,
      lineHeight: 1.15,
      padding: "15px 20px",
      textAlign: "left",
      "&:focus": {
        outline: 0,
        borderColor: "#000000",
      },
    },
    "& .MuiInputAdornment-root": {
      alignItems: "center",
      marginRight: 8,
    },
  },
  "& .MuiOutlinedInput-root:not(.Mui-error):not(.Mui-disabled)": {
    "&:hover fieldset, &:focus fieldset": {
      borderColor: "#000000",
    },
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "14px",
  },
}))
