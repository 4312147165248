import React, { useState, useEffect, useMemo } from "react"
import { BaseResponseGetNFTItemsDto, CollectionItemDto } from "@/core/api"
import { activeMenus } from "@/core/constant/menu"
import { OverlayImagesComponent } from "@/components/common/OverlayImagesComponent"
import {
  CircularProgress,
  Box,
  Pagination,
  Tooltip,
  Zoom,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { PhotoLineIcon } from "@/components/common/CommonIcon"
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt"
import SearchIcon from "@mui/icons-material/Search"
import CheckIcon from "@mui/icons-material/Check"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import SearchOffIcon from "@mui/icons-material/SearchOff"
import { useTranslation } from "react-i18next"
import { BalanceOf } from "@/core/api/balanceOf"
import { useAsync, useCopyToClipboard } from "react-use"
import { TokenRow } from "@/components/common/TokenRow"
import { TokenData } from "@/core/types/Response"
import { QRCodeDownloadWrapper } from "@/pages/nftDetail/QRCodeDownloadWrapper"
import { AsyncState } from "react-use/lib/useAsyncFn"
import { getBalanceOf } from "@/core/api/balanceOf"
import { AppContext } from "@/AppContext"
import { CommonButton } from "@/components/common/CommonButton"
import useDetailActions from "@/core/hook/useDetailActions"
import TimePeriodComponent from "@/components/common/TimePeriodComponent"

export type SequentialDetailBasicProps = {
  data: BaseResponseGetNFTItemsDto | undefined
  collectionData: CollectionItemDto | undefined
  rawBalanceOf: AsyncState<BalanceOf>
}

export const SequentialDetailBasic: React.FunctionComponent<
  SequentialDetailBasicProps
> = ({ data, collectionData, rawBalanceOf }) => {
  const [, copyToClipboard] = useCopyToClipboard()
  const [copySuccess, setCopySuccess] = useState(false)
  const [ethersData, setEthersData] = useState<number[]>([])
  const [tokenData, setTokenData] = useState<Array<TokenData>>([])
  const [latestBalance, setLatestBalance] = useState(rawBalanceOf.value)
  const [page, setPage] = useState(1)
  const rowsPerPage = 10
  const [search, setSearch] = useState("")
  const [active, setActive] = useState("all")
  const { breakpoints, palette } = useTheme()
  const isMobile = useMediaQuery(breakpoints.down("lg"))
  const { t, i18n } = useTranslation()
  const { showExamplePopup } = useDetailActions()

  const markdown = `<a href="${collectionData?.pin?.pin_code}">
  <img
    src="https://diver-static-asset.diver.io/getnft.png"
    alt="get-nft"
  />
</a>`

  // API polling
  useEffect(() => {
    const interval = setInterval(async () => {
      const res = await getBalanceOf(
        AppContext.getAuthAddress(),
        collectionData?.contract_address as string,
        undefined
      )
      setLatestBalance(res)
    }, 5000)

    return () => clearInterval(interval)
  }, [rawBalanceOf])

  // 更新鏈上資料
  useEffect(() => {
    const length = data?.nft.supply as number
    const resultArray = new Array(length).fill(0)
    if (rawBalanceOf.value?.data.balanceDict && latestBalance) {
      Object.keys(latestBalance.data.balanceDict).forEach((key: string) => {
        if (Number(key) < length) {
          resultArray[Number(key)] = latestBalance.data.balanceDict[key].amount
        }
      })
    }
    setEthersData(resultArray)
  }, [latestBalance, collectionData, data])

  const rawTokenData = useAsync(async () => {
    return Array.from({ length: data?.nft.supply as number }, (_, index) => ({
      address: data?.nft.address,
      owner: data?.nft.owner,
      tokenID: index,
    }))
  }, [data, ethersData, search, active, page])

  useEffect(() => {
    if (ethersData.length > 0 && rawTokenData.value) {
      const updatedData =
        rawTokenData.value?.map((data, index) => ({
          ...data,
          isTransferred: ethersData[index] === 0,
        })) ?? []
      setTokenData(updatedData)
    }
  }, [ethersData, rawTokenData])

  const startIndex = useMemo(() => (page - 1) * rowsPerPage, [page])

  const filteredData: TokenData[] = useMemo(() => {
    return tokenData
      .filter(token => {
        switch (active) {
          case "all":
            return true
          case "holding":
            return !token.isTransferred
          case "transferred":
            return token.isTransferred
          default:
            return true
        }
      })
      .filter(token => {
        return (
          search.trim() === "" ||
          token.tokenID.toString().includes(search.trim())
        )
      })
  }, [tokenData, active, search])

  const currentData: Array<TokenData> = useMemo(
    () => filteredData.slice(startIndex, startIndex + rowsPerPage) ?? [],
    [rawTokenData?.value, startIndex, tokenData, page, active, search]
  )

  useEffect(() => {
    setPage(1)
  }, [search, active])

  return (
    <>
      <div className="flex w-full flex-col gap-x-6 pb-10 sm:flex-row">
        <div className="w-full sm:w-1/3">
          <div className="relative aspect-square pt-6 sm:pt-10">
            <OverlayImagesComponent url={data?.nft.image} />
          </div>
          <div className="lg:-ml-8">
            <div className="mb-3 mt-14 flex items-center">
              <PhotoLineIcon />
              <span className="pl-1 font-medium tracking-widest">
                {t("image")}
              </span>
            </div>
            <div className="mb-4 text-2xl font-bold sm:mb-8">
              {data?.nft.name}
            </div>
            <div className="w-full rounded border border-[#D1D1D1]">
              <div className="flex items-center rounded-t border-b border-[#D1D1D1] bg-[#F3F3F3] px-4 py-5">
                <SystemUpdateAltIcon fontSize="small" />
                <span className="pl-2">{t("sequential_sent_stock")}</span>
              </div>
              <div className="py-6 text-center text-4xl font-bold">
                {!latestBalance ? (
                  <CircularProgress
                    size={25}
                    sx={{
                      color: "#000",
                    }}
                  />
                ) : (
                  <span>{latestBalance?.data.total}</span>
                )}
                <span className="ml-3 inline-block">/ {data?.nft.supply}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10 w-full sm:mt-0 sm:w-2/3">
          <Box
            className="flex items-center justify-between pb-2"
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <div className="text-2xl font-bold">{t("token_list")}</div>
            <Box
              className="relative hidden max-w-[230px] flex-1 items-center transition-all lg:inline-flex"
              onSubmit={event => {
                event.stopPropagation()
                event.preventDefault()
              }}
              component="form"
              sx={{ maxWidth: 230 }}
            >
              <SearchIcon
                className="absolute left-2 text-gray-400"
                fontSize="small"
              />
              <input
                type="search"
                value={search}
                onChange={event => {
                  setSearch(event.currentTarget.value)
                }}
                className="focus:border-primary w-full rounded-md border border-gray-400 py-2 pl-8 pr-3 text-sm outline-none"
                placeholder={t("search_token_id")}
              />
              <button className="hidden" type="submit" />
            </Box>
          </Box>
          <div className="flex gap-x-2 py-4">
            {activeMenus.map((menu, index) => (
              <CommonButton
                key={index}
                variant="outlined"
                disabled={!!search && filteredData.length === 0}
                onClick={() => setActive(menu)}
                sx={{
                  fontWeight: "medium",
                  transition: "all 0.3s",
                  borderRadius: 1,
                  padding: "7px 14px",
                  "&.MuiButton-root": {
                    borderColor: menu === active ? "#333" : "#D1D1D1",
                    color: menu === active ? "#333" : "#D1D1D1",
                  },
                  "&:hover": {
                    borderColor: "#333",
                    backgroundColor: "#F5F5F7",
                    color: "#333",
                  },
                }}
              >
                {menu === active && (
                  <CheckIcon
                    className="mr-2 inline-block"
                    sx={{ fontSize: "16px", color: "#333333" }}
                  />
                )}
                {t(menu)}
              </CommonButton>
            ))}
          </div>
          {filteredData.length === 0 ? (
            <div className="grid h-full w-full place-items-center">
              <div className="text-center">
                <SearchOffIcon
                  sx={{
                    fontSize: 70,
                    color: "#757575",
                    marginBottom: 1,
                  }}
                />
                <div className="text-[18px] text-[#757575]">
                  {t("no_match_token_id")}
                </div>
              </div>
            </div>
          ) : latestBalance ? (
            <>
              <div className="flex flex-col gap-2">
                {currentData.map(nft => (
                  <TokenRow key={nft.tokenID} nft={nft} />
                ))}
              </div>
              <div className="mt-6 flex justify-center">
                <Pagination
                  page={page}
                  onChange={(_, page) => setPage(page)}
                  count={Math.ceil(Number(filteredData.length) / 10) || 1}
                  variant="outlined"
                  shape="rounded"
                  siblingCount={isMobile ? 0 : 1}
                  showFirstButton
                  showLastButton
                />
              </div>
            </>
          ) : (
            <div className="py-20 text-center">
              <CircularProgress
                size={32}
                sx={{
                  color: "#000",
                }}
              />
            </div>
          )}
        </div>
      </div>
      {collectionData?.pin && (
        <>
          <div className="flex flex-col border-b border-t border-[#D1D1D1] py-10 md:flex-row lg:-ml-8">
            <div className="mb-10 flex-1 md:mb-0">
              <iframe
                src={`https://maps.google.com/maps?&q=${collectionData.pin.geo.address}&center=${collectionData.pin.geo.lat},${collectionData.pin.geo.lon}&language=${i18n.language}&output=embed`}
                width="100%"
                height="400"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                allowFullScreen
              />
            </div>
            <div className="flex w-full flex-col md:w-2/5 md:pl-6">
              <TimePeriodComponent pin={collectionData.pin} />
              <div className="mb-2 flex">
                <span className="whitespace-nowrap">{`${t("address")}：`}</span>
                <span className="break-words">{`${collectionData.pin.geo.address}`}</span>
              </div>
              <div className="mb-2">
                <span>{`${t("range")}：`}</span>
                {collectionData.pin.geo.distance === 0 ? (
                  <span>{t("option_no_limit")}</span>
                ) : (
                  <span>{`${collectionData.pin.geo.distance}m`}</span>
                )}
              </div>
              {collectionData.pin.slogan && (
                <div>
                  <span>{`${t("slogan")}：`}</span>
                  <span>{collectionData.pin.slogan}</span>
                </div>
              )}
            </div>
          </div>
          <div className="py-10 lg:-ml-8">
            <div className="mb-2 text-center text-2xl font-bold leading-7">
              {t("str_qrcode_promote_way")}
            </div>
            <div className="mb-4 text-center text-sm">
              {t("str_qrcode_promote_way_subtitle")}
            </div>
            <div className="mb-4 flex flex-col rounded-lg border border-[#D1D1D1] md:flex-row">
              <div className="relative flex w-full flex-0 flex-col items-center rounded-l-lg border-b border-[#D1D1D1] bg-[#F6F6F6] p-3 text-center md:aspect-square md:w-1/3 md:border-b-0 md:border-r">
                <HelpOutlineIcon
                  className="absolute right-3 cursor-pointer text-[#aeaeae]"
                  onClick={() => showExamplePopup("qrcode")}
                />
                <div className="mb-6 pt-3 font-bold md:mb-12">
                  {t("qrcode")}
                </div>
                <img
                  src="/images/get-qrcode-img.png"
                  alt="get-qrcode"
                  className="mx-auto w-1/3 object-contain md:aspect-square md:w-3/4"
                />
              </div>
              <div className="flex flex-1 items-center justify-center sm:mx-2 md:mx-4 lg:mx-[60px]">
                {collectionData.pin &&
                  Object.keys(collectionData.pin).length > 0 && (
                    <QRCodeDownloadWrapper
                      pin={collectionData.pin}
                      fileName={data?.nft.name || ""}
                    />
                  )}
              </div>
            </div>
            <div className="flex flex-col rounded-lg border border-[#D1D1D1] md:flex-row">
              <div className="relative flex w-full flex-0 flex-col items-center rounded-l-lg border-b border-[#D1D1D1] bg-[#F6F6F6] p-3 text-center md:aspect-square md:w-1/3 md:border-b-0 md:border-r">
                <HelpOutlineIcon
                  className="absolute right-3 cursor-pointer text-[#aeaeae]"
                  onClick={() => showExamplePopup("button")}
                />
                <div className="mb-6 pt-3 font-bold md:mb-12">
                  {t("button")}
                </div>
                <img
                  src="/images/get-nft-img.png"
                  alt="get-nft"
                  className="mx-auto w-1/3 object-contain md:aspect-square md:w-3/4"
                />
              </div>
              <div className="flex flex-1 items-center justify-center sm:mx-2 md:mx-4 md:items-center lg:mx-[60px]">
                <div className="flex w-full flex-col items-center justify-center py-10">
                  <article className="prose prose-sm prose-stone w-3/4 md:w-5/6 lg:w-auto">
                    <pre>{markdown}</pre>
                  </article>
                  <Tooltip
                    TransitionComponent={Zoom}
                    placement="bottom"
                    disableFocusListener
                    title={
                      copySuccess ? t("copy_successfully") : t("str_copy_link")
                    }
                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          backgroundColor: copySuccess
                            ? palette.success.main
                            : "",
                        },
                      },
                    }}
                  >
                    <div
                      className="mt-8 flex w-56 flex-0 cursor-pointer items-center justify-center rounded-lg bg-black py-3 text-white transition-colors hover:bg-black/90 md:w-64 xl:h-12"
                      onClick={() => {
                        copyToClipboard(markdown)
                        setCopySuccess(true)
                      }}
                      onMouseLeave={() =>
                        setTimeout(() => setCopySuccess(false), 300)
                      }
                    >
                      <ContentCopyIcon fontSize="small" />
                      <span className="pl-2 font-medium">
                        {t("str_copy_code")}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
