import React from "react"

export type OverlayImagesComponentProps = {
  url: string | undefined
}

export const OverlayImagesComponent: React.FunctionComponent<
  OverlayImagesComponentProps
> = ({ url }) => {
  return (
    <>
      <img
        className="overlay opacity-40 sm:-translate-x-8 sm:-translate-y-8"
        src={url}
        alt=""
      />
      <img
        className="overlay opacity-70 sm:-translate-x-4 sm:-translate-y-4"
        src={url}
        alt=""
      />
      <img className="overlay" src={url} alt="" />
    </>
  )
}
