import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import ja from "../locales/ja/strings.json"
import en from "../locales/en/strings.json"
function getBrowserLang(lang?: string): string {
  switch (lang) {
    case "en":
    case "en_US":
    case "en-US":
      return "en"
    case "ja":
    case "ja_JP":
    default:
      return "ja"
  }
}
i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "ja",
    lng:
      (typeof window !== "undefined" &&
        (localStorage.getItem("i18nextLng") ||
          getBrowserLang(navigator.language))) ||
      "ja",
    debug: false,
    resources: {
      ja: {
        translation: ja,
      },
      en: {
        translation: en,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  })
  .catch()

export default i18next
