import { Box } from "@mui/material"
import React, { PropsWithChildren, useState } from "react"
import { SearchContext } from "@/core/store/SearchContext"
import { useDebounce } from "react-use"
import Header from "@/components/Header"
import Footer from "@/components/Footer"
import { useNavigate } from "react-router-dom"

export const LayoutWrapper: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [search, setSearch] = useState("")
  const [debounce, setDebounce] = useState("")
  const navigate = useNavigate()

  useDebounce(
    () => {
      setSearch(debounce)
      debounce !== "" &&
        location.pathname !== "/account" &&
        navigate("/account")
    },
    500,
    [debounce]
  )

  return (
    <SearchContext.Provider value={{ search, debounce, setDebounce }}>
      <Box className="h-available relative">
        <Header />
        <Box sx={{ minHeight: "calc(100% - 86px)" }}>{children}</Box>
        <Footer />
      </Box>
    </SearchContext.Provider>
  )
}
