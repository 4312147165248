import React, { useEffect } from "react"
import { LoadingWrapper } from "@/components/common/loading/LoadingWrapper"
import { NFTItemDto } from "@/core/api"
import { AppContext } from "@/AppContext"
import { useAsync } from "react-use"
import { ContractTypeEnum } from "@/core/enum/ContractTypeEnum"
import { useParams } from "react-router-dom"
import { CommonButton } from "@/components/common/CommonButton"
import { useTranslation } from "react-i18next"
import { CircularProgress } from "@mui/material"
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt"
import { PhotoLineIcon } from "@/components/common/CommonIcon"
import { OverlayImagesComponent } from "@/components/common/OverlayImagesComponent"
import { getBalanceOf } from "@/core/api/balanceOf"
import NoDataComponent from "@/components/common/NoDataComponent"

type Props = {
  next: () => void
  setSelectedNFT: React.Dispatch<React.SetStateAction<NFTItemDto | undefined>>
}

export default function PinPreview({ next, setSelectedNFT }: Props) {
  const { id } = useParams()
  const { t } = useTranslation()

  const rawData = useAsync(async () => {
    const res = await AppContext.apiExecutor.getCollections(
      "all",
      1,
      1,
      undefined,
      ContractTypeEnum.SEQUENTIAL,
      id
    )

    return res.data.result?.item_list?.[0]
  }, [])

  const rawNFTData = useAsync(async () => {
    if (id && AppContext.getAuthAddress() && rawData.value) {
      const nftRes = await AppContext.apiExecutor.getNFTs(
        "unpin",
        1,
        1,
        rawData.value.contract_address
      )
      return nftRes.data
    } else {
      return {
        code: "",
        result: {
          item_list: [],
          total_page: 0,
          total_item_size: 0,
          page: 0,
        },
      }
    }
  }, [AppContext.getAuthAddress, rawData.value, id])

  const rawBalanceOf = useAsync(async () => {
    if (rawNFTData.value?.result.item_list) {
      const res = await getBalanceOf(
        rawNFTData.value?.result.item_list[0]?.nft.owner as string,
        rawNFTData.value?.result.item_list[0]?.nft.address as string
      )
      return res
    }
  }, [rawNFTData])

  useEffect(() => {
    if (Number(rawNFTData.value?.result.total_item_size) > 0) {
      setSelectedNFT(rawNFTData.value?.result.item_list?.[0].nft)
    }
  }, [rawNFTData, setSelectedNFT])

  return (
    <LoadingWrapper
      state={rawNFTData}
      render={data =>
        data.result.item_list && Number(data.result.item_list.length) > 0 ? (
          <div className="mx-auto md:w-1/3">
            <div className="relative mt-5 aspect-square md:mt-[72px]">
              <OverlayImagesComponent
                url={data.result.item_list[0].nft.image}
              />
            </div>
            <div className="md:-ml-8">
              <div className="my-9">
                <div className="mb-3 flex items-center">
                  <PhotoLineIcon />
                  <div className="ml-1">{t("image")}</div>
                </div>
                <div className="text-2xl font-bold leading-7">
                  {data.result.item_list[0].nft.name}
                </div>
              </div>
              <div className="mb-10 w-full rounded border border-[#D1D1D1]">
                <div className="flex items-center rounded-t border-b border-[#D1D1D1] bg-[#F3F3F3] px-4 py-5">
                  <SystemUpdateAltIcon fontSize="small" />
                  <span className="pl-2">{t("sequential_sent_stock")}</span>
                </div>
                <div className="py-6 text-center text-4xl font-bold">
                  {rawBalanceOf.loading ? (
                    <CircularProgress
                      size={25}
                      sx={{
                        color: "#000",
                      }}
                    />
                  ) : (
                    <span>{rawBalanceOf.value?.data.total}</span>
                  )}
                  <span className="ml-3 inline-block">
                    / {data.result.item_list[0].nft.supply}
                  </span>
                </div>
              </div>
              <div className="text-center">
                <CommonButton
                  variant="contained"
                  sx={{
                    bgcolor: "#000",
                    borderRadius: 2,
                    paddingY: "15px",
                    width: "376px",
                    "&:hover": {
                      bgcolor: "#000000d9",
                    },
                  }}
                  onClick={next}
                  disabled={rawBalanceOf.loading}
                >
                  {t("next")}
                </CommonButton>
              </div>
            </div>
          </div>
        ) : (
          <NoDataComponent />
        )
      }
    />
  )
}
