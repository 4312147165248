import React, { FC, PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { getYear } from "date-fns"

const Footer: FC<PropsWithChildren> = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleScrollTop = () => {
    window.scrollTo(0, 0)
  }

  const goToLink = (link: string) => {
    handleScrollTop()
    navigate(link)
  }

  return (
    <div className="flex flex-col items-center bg-black py-16 text-white">
      <div className="mb-12 mt-4 flex items-center gap-6 px-4 max-md:flex-col">
        <div className="md:mr-8">
          <div className="w-full cursor-pointer">
            <img
              className="w-[120px]"
              src="/images/chainpin-logo-w.svg"
              alt="logo-w"
              onClick={() => goToLink("/")}
            />
          </div>
        </div>
        <div>
          <div className="mb-4 flex w-full justify-between">
            <div className="flex max-md:flex-col max-md:text-center">
              <div
                className="link-hover cursor-pointer py-3 md:mr-8"
                onClick={() => goToLink("/account")}
              >
                {t("account")}
              </div>
              <div
                className="link-hover cursor-pointer py-3 md:mr-8"
                onClick={() => goToLink("/pin-generate")}
              >
                {t("pin_generate")}
              </div>
              <a
                className="link-hover cursor-pointer py-3 md:mr-8"
                href="https://lin.ee/3iPsqHs"
                target="_blank"
                rel="noreferrer noopener"
              >
                {t("contact")}
              </a>
              <div
                className="link-hover cursor-pointer py-3 md:mr-8"
                onClick={() => goToLink("/privacy-policy")}
              >
                {t("privacy_policy")}
              </div>
              <div
                className="link-hover cursor-pointer py-3 md:mr-8"
                onClick={() => goToLink("/term-of-service")}
              >
                {t("terms_and_conditions")}
              </div>
            </div>
          </div>
          <div className="mb-2 grid w-full gap-4 md:grid-cols-3">
            <a
              className="inline-block w-full cursor-pointer rounded"
              href={import.meta.env.VITE_DIVER_WALLET_ANDROID}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                className="object-contain"
                src="/images/wallet-android-download-icon-jp.svg"
                alt="android-download-icon"
              />
            </a>
            <a
              className="inline-block w-full cursor-pointer rounded"
              href={import.meta.env.VITE_DIVER_WALLET_IOS}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                className="object-contain"
                src="/images/wallet-ios-download-icon-jp.svg"
                alt="ios-download-icon"
              />
            </a>
            <a
              className="inline-block w-full cursor-pointer rounded"
              href={import.meta.env.VITE_DIVER_WALLET_URL}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                className="object-contain"
                src="/images/wallet-ex-download-icon-jp.svg"
                alt="ex-download-icon"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="my-4 h-[1px] w-[80%] bg-white"></div>
      <a href="https://world-scan-project.com/">
        <img
          className="my-8 w-[180px]"
          src="/images/wsp-logo.svg"
          alt="wsp-logo"
        />
      </a>
      <div className="text-[16px]">©{getYear(new Date())} CHAIN PIN</div>
    </div>
  )
}

export default Footer
