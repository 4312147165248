import React, { useState, useEffect } from "react"
import { PhotoLineIcon } from "@/components/common/CommonIcon"
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt"
import { CircularProgress, Tooltip, Zoom, useTheme } from "@mui/material"
import { CommonButton } from "@/components/common/CommonButton"
import { BaseResponseGetNFTItemsDto } from "@/core/api"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { QRCodeDownloadWrapper } from "@/pages/nftDetail/QRCodeDownloadWrapper"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { useAsync, useCopyToClipboard } from "react-use"
import { getBalanceOf } from "@/core/api/balanceOf"
import useDetailActions from "@/core/hook/useDetailActions"
import TimePeriodComponent from "@/components/common/TimePeriodComponent"

export type NFTDetailBasicProps = { data: BaseResponseGetNFTItemsDto }

export const NFTDetailBasic: React.FunctionComponent<NFTDetailBasicProps> = ({
  data: { nft, pin, collection },
}) => {
  const [, copyToClipboard] = useCopyToClipboard()
  const [copySuccess, setCopySuccess] = useState(false)
  const [used, setUsed] = useState<number | undefined>(0)
  const { t, i18n } = useTranslation()
  const { palette } = useTheme()
  const navigate = useNavigate()
  const { showExamplePopup } = useDetailActions()

  const rawBalanceOf = useAsync(async () => {
    const res = await getBalanceOf(nft.owner, nft.address, nft.tokenID)
    return res
  }, [nft])

  useEffect(() => {
    const fetchBalance = async () => {
      const res = await getBalanceOf(nft.owner, nft.address, nft.tokenID)
      setUsed(res?.data.total)
    }
    fetchBalance()

    const interval = setInterval(fetchBalance, 3000)
    return () => clearInterval(interval)
  }, [nft, rawBalanceOf])

  const markdown = `<a href="${pin?.pin_code}">
  <img
    src="https://diver-static-asset.diver.io/getnft.png"
    alt="get-nft"
  />
</a>`

  return (
    <div className="mb-8 mt-5 rounded-lg border-2 border-[#D1D1D1] p-4 md:p-0">
      <div className="flex h-fit flex-col rounded-lg pb-6 md:flex-row md:p-10">
        <div className="mb-10 w-full flex-0 rounded md:mb-0 md:w-1/2 lg:w-[400px]">
          <img
            src={nft.image}
            className="pointer-events-none aspect-square w-full rounded object-cover"
            alt={nft.name}
          />
        </div>
        <div className="flex min-h-[350px] w-full flex-col md:pl-10">
          <div className="mb-[10px] flex items-center">
            <PhotoLineIcon />
            <div className="ml-1 text-sm">{t("image")}</div>
          </div>
          <div
            className="cursor-pointer text-sm font-semibold text-blue-700 underline decoration-black underline-offset-1"
            onClick={() => navigate(`/collection/${collection.collectionID}`)}
          >
            {collection.name}
          </div>
          <div className="pt-3 text-2xl font-semibold">{nft.name}</div>
          <div className="flex flex-1 items-center py-3">
            <div className="mx-auto w-full rounded border border-[#D1D1D1] lg:w-3/5">
              <div className="flex items-center rounded-t border-b border-[#D1D1D1] bg-[#F3F3F3] px-4 py-6">
                <SystemUpdateAltIcon fontSize="small" />
                <span className="pl-2">{t("sent_stock")}</span>
              </div>
              <div className="w-full py-10 text-center text-4xl font-semibold leading-[56px]">
                <div className="mb-1">
                  {rawBalanceOf.loading ? (
                    <CircularProgress
                      size={25}
                      sx={{
                        color: "black",
                      }}
                    />
                  ) : (
                    <span>{used}</span>
                  )}
                  <span className="ml-3 inline-block">/ {nft.supply}</span>
                </div>
                <CommonButton
                  color="primary"
                  variant="outlined"
                  className="relative w-full"
                  sx={{
                    color: "#000",
                    borderColor: "#000",
                    borderRadius: 2,
                    padding: "10px 20px",
                    width: "240px",
                    borderWidth: 2,
                    "&:hover": {
                      borderColor: "#000",
                      borderWidth: 2,
                      backgroundColor: "#00000009",
                    },
                  }}
                  onClick={() =>
                    window.open(
                      `${import.meta.env.VITE_SCAN_URL}/nft/${nft.address}/${
                        nft.tokenID
                      }`,
                      "_blank"
                    )
                  }
                >
                  {t("view_on_diver_scan")}
                </CommonButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pin && Object.keys(pin).length > 0 && (
        <>
          <div className="flex flex-col border-b border-[#D1D1D1] pb-6 md:flex-row md:p-10">
            <div className="mb-10 flex-1 md:mb-0">
              <iframe
                src={`https://maps.google.com/maps?&q=${pin.geo.address}&center=${pin.geo.lat},${pin.geo.lon}&language=${i18n.language}&output=embed`}
                width="100%"
                height="400"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                allowFullScreen
              />
            </div>
            <div className="flex w-full flex-col md:w-2/5 md:pl-6">
              <TimePeriodComponent pin={pin} />
              <div className="mb-2 flex">
                <span className="whitespace-nowrap">{`${t("address")}：`}</span>
                <span className="break-words">{`${pin.geo.address}`}</span>
              </div>
              <div className="mb-2">
                <span>{`${t("range")}：`}</span>
                {pin.geo.distance === 0 ? (
                  <span>{t("option_no_limit")}</span>
                ) : (
                  <span>{`${pin.geo.distance}m`}</span>
                )}
              </div>
              {pin.slogan && (
                <div>
                  <span>{`${t("slogan")}：`}</span>
                  <span>{pin.slogan}</span>
                </div>
              )}
            </div>
          </div>
          <div className="pt-6 md:p-10">
            <div className="mb-2 text-center text-2xl font-bold leading-7">
              {t("str_qrcode_promote_way")}
            </div>
            <div className="mb-4 text-center text-sm">
              {t("str_qrcode_promote_way_subtitle")}
            </div>
            <div className="mb-4 flex flex-col rounded-lg border border-[#D1D1D1] md:flex-row">
              <div className="relative flex w-full flex-0 flex-col items-center rounded-l-lg border-b border-[#D1D1D1] bg-[#F6F6F6] p-3 text-center md:aspect-square md:w-1/3 md:border-b-0 md:border-r">
                <HelpOutlineIcon
                  className="absolute right-3 cursor-pointer text-[#aeaeae]"
                  onClick={() => showExamplePopup("qrcode")}
                />
                <div className="mb-6 pt-3 font-bold md:mb-12">
                  {t("qrcode")}
                </div>
                <img
                  src="/images/get-qrcode-img.png"
                  alt="get-qrcode"
                  className="mx-auto w-1/3 object-contain md:aspect-square md:w-3/4"
                />
              </div>
              <div className="flex flex-1 items-center justify-center sm:mx-2 md:mx-4 lg:mx-[60px]">
                {pin && Object.keys(pin).length > 0 && (
                  <QRCodeDownloadWrapper pin={pin} fileName={nft.name} />
                )}
              </div>
            </div>
            <div className="flex flex-col rounded-lg border border-[#D1D1D1] md:flex-row">
              <div className="relative flex w-full flex-0 flex-col items-center rounded-l-lg border-b border-[#D1D1D1] bg-[#F6F6F6] p-3 text-center md:aspect-square md:w-1/3 md:border-b-0 md:border-r">
                <HelpOutlineIcon
                  className="absolute right-3 cursor-pointer text-[#aeaeae]"
                  onClick={() => showExamplePopup("button")}
                />
                <div className="mb-6 pt-3 font-bold md:mb-12">
                  {t("button")}
                </div>
                <img
                  src="/images/get-nft-img.png"
                  alt="get-nft"
                  className="mx-auto w-1/3 object-contain md:aspect-square md:w-3/4"
                />
              </div>
              <div className="flex flex-1 items-center justify-center sm:mx-2 md:mx-4 md:items-center lg:mx-[60px]">
                <div className="flex w-full flex-col items-center justify-center py-10">
                  <article className="prose prose-sm prose-stone w-3/4 md:w-5/6 lg:w-auto">
                    <pre>{markdown}</pre>
                  </article>
                  <Tooltip
                    TransitionComponent={Zoom}
                    placement="bottom"
                    disableFocusListener
                    title={
                      copySuccess ? t("copy_successfully") : t("str_copy_link")
                    }
                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          backgroundColor: copySuccess
                            ? palette.success.main
                            : "",
                        },
                      },
                    }}
                  >
                    <div
                      className="mt-8 flex w-56 flex-0 cursor-pointer items-center justify-center rounded-lg bg-black py-3 text-white transition-colors hover:bg-black/90 md:w-64 xl:h-12"
                      onClick={() => {
                        copyToClipboard(markdown)
                        setCopySuccess(true)
                      }}
                      onMouseLeave={() =>
                        setTimeout(() => setCopySuccess(false), 300)
                      }
                    >
                      <ContentCopyIcon fontSize="small" />
                      <span className="pl-2 font-medium">
                        {t("str_copy_code")}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
