import { DateRangePicker, LocalizationProvider } from "@mui/lab"
import { DateRangePickerProps } from "@mui/lab/DateRangePicker/DateRangePicker"
import { Box, FormHelperText, useTheme } from "@mui/material"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import React, { useState } from "react"
import { Control, Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import { CustomTextField } from "@/components/common/form/widget/CustomTextField"

export type CommonDateRangePickerProps = {
  errors: { [x: string]: any }
  control: Control<any>
  name: string
  needDays?: number
  onChange?: void
  maxDate?: Date
} & Omit<DateRangePickerProps<Date>, "onChange" | "renderInput" | "value">

export const CommonDateRangePicker: React.FunctionComponent<
  CommonDateRangePickerProps
> = ({ control, name, errors, onChange, maxDate, ...props }) => {
  const { t, i18n } = useTranslation()
  const { palette } = useTheme()
  const [open, setOpen] = useState("")
  const [focused, setFocused] = useState(false)
  const inputSx = {
    "& .MuiOutlinedInput-root": {
      input: {
        padding: "13px 50px 13px 20px",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "2px solid black",
    },
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              open={!!open}
              inputFormat={
                i18n.language === "en" ? "yyyy/MM/dd" : "yyyy年MM月dd日"
              }
              maxDate={maxDate}
              mask={i18n.language === "en" ? "____/__/__" : "____年__月__日"}
              onClose={() => setOpen("")}
              {...props}
              value={field.value}
              onChange={onChange || field.onChange}
              renderInput={(startProps, endProps) => (
                <div className="flex w-full items-center">
                  <Box
                    className={`relative w-full ${
                      focused ? "Mui-focused" : ""
                    }`}
                    sx={{
                      "&:hover, &.Mui-focused": {
                        ".MuiSvgIcon-root": {
                          color:
                            field.value && field.value[0]
                              ? "#000000"
                              : palette.text.secondary,
                        },
                      },
                    }}
                  >
                    <CustomTextField
                      {...startProps}
                      ref={undefined}
                      variant="outlined"
                      label=""
                      inputProps={{
                        ...startProps.inputProps,
                        placeholder: t("start_date"),
                        readOnly: true,
                      }}
                      className={`${
                        errors[name] && errors[name].message ? "Mui-error" : ""
                      }`}
                      sx={inputSx}
                      error={errors[name] && errors[name][0]}
                      onClick={() => setOpen(open === "start" ? "" : "start")}
                      onBlur={() => {
                        setFocused(false)
                        field.onBlur()
                      }}
                      onFocus={() => setFocused(true)}
                      fullWidth
                    />
                    <CalendarMonthIcon
                      fontSize="small"
                      className="absolute right-4 top-4 text-gray-400"
                    />
                  </Box>
                  <Box
                    className="mx-2 text-[24px]"
                    sx={{ color: palette.text.secondary }}
                  >
                    {"-"}
                  </Box>
                  <Box
                    className={`relative w-full ${
                      focused ? "Mui-focused" : ""
                    }`}
                    sx={{
                      "&:hover, &.Mui-focused": {
                        "& .MuiSvgIcon-root": {
                          color:
                            field.value && field.value[1]
                              ? "#000000"
                              : palette.text.secondary,
                        },
                      },
                    }}
                  >
                    <CustomTextField
                      {...endProps}
                      ref={undefined}
                      variant="outlined"
                      label=""
                      inputProps={{
                        ...endProps.inputProps,
                        placeholder: t("end_date"),
                        readOnly: true,
                      }}
                      className={`${
                        errors[name] && errors[name].message ? "Mui-error" : ""
                      }`}
                      sx={inputSx}
                      error={errors[name] && errors[name][1]}
                      onClick={() => setOpen(open === "end" ? "" : "end")}
                      onBlur={() => {
                        setFocused(false)
                        field.onBlur()
                      }}
                      onFocus={() => setFocused(true)}
                      fullWidth
                    />
                    <CalendarMonthIcon
                      fontSize="small"
                      className="absolute right-4 top-4 text-gray-400"
                    />
                  </Box>
                </div>
              )}
            />
          </LocalizationProvider>
          <div className="flex">
            <div className={"flex-1"}>
              {errors[name] && (
                <FormHelperText error sx={{ margin: "3px 14px" }}>
                  {errors[name] &&
                    (errors[name][0]?.message ||
                      errors[name][1]?.message ||
                      errors[name]?.message)}
                </FormHelperText>
              )}
            </div>
            <div className={"flex-1"}>
              {errors[name] && errors[name].type !== "ThreeMonths" && (
                <FormHelperText error sx={{ margin: "3px 14px" }}>
                  {errors[name] &&
                    (errors[name][0]?.message ||
                      errors[name][1]?.message ||
                      errors[name]?.message)}
                </FormHelperText>
              )}
            </div>
          </div>
        </>
      )}
    />
  )
}
