import React, { useState } from "react"
import { Box, Tooltip, useTheme, Zoom } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { PinNFTDto } from "@/core/api"
import { format, fromUnixTime } from "date-fns"
import { useTranslation } from "react-i18next"
import QRCode from "qrcode.react"
import FileCopyRoundedIcon from "@mui/icons-material/FileCopyRounded"
import { useCopyToClipboard } from "react-use"

export type QrCodePopupProps = { pin: PinNFTDto; close: () => void }

export const QrCodePopup: React.FunctionComponent<QrCodePopupProps> = ({
  pin,
  close,
}) => {
  const [, copyToClipboard] = useCopyToClipboard()
  const [copySuccess, setCopySuccess] = useState(false)
  const { t } = useTranslation()
  const { breakpoints, palette } = useTheme()

  return (
    <Box
      className="pb-14 pt-20"
      sx={{
        width: "95%",
        margin: "auto",
        [breakpoints.up("md")]: { width: 512 },
      }}
    >
      <CloseIcon
        className="absolute right-4 top-4 cursor-pointer hover:text-gray-400"
        fontSize="large"
        onClick={close}
      />
      <div className="mx-auto md:w-2/3">
        <QRCode
          size={256}
          style={{ margin: "auto" }}
          viewBox="0 0 256 256"
          value={pin.pin_code}
          level="H"
        />
        <div className="px-3 pt-5 font-medium">
          <div className="mb-2 w-full ">
            <div className="flex justify-between rounded-lg bg-[#EFF0F2] px-4 py-2">
              <span className="truncate pe-2 text-sm font-medium text-[#747474]">
                {pin.pin_code}
              </span>
              <Tooltip
                TransitionComponent={Zoom}
                placement="top"
                disableFocusListener
                title={
                  copySuccess ? t("copy_successfully") : t("str_copy_link")
                }
                PopperProps={{
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      backgroundColor: copySuccess ? palette.success.main : "",
                    },
                  },
                }}
              >
                <FileCopyRoundedIcon
                  className="cursor-pointer"
                  onClick={() => {
                    copyToClipboard(pin.pin_code)
                    setCopySuccess(true)
                  }}
                  onMouseLeave={() =>
                    setTimeout(() => setCopySuccess(false), 300)
                  }
                />
              </Tooltip>
            </div>
          </div>
          <div className="w-full break-words">
            <span>{`${t("period")}：`}</span>
            {pin.end_date_timestamp === 4102415999 ? (
              t("option_no_limit")
            ) : (
              <div className="inline-block">
                <span>{`${format(
                  fromUnixTime(pin.start_date_timestamp),
                  "yyyy-MM-dd"
                )}`}</span>
                <span className="mx-2">~</span>
                <span>{`${format(
                  fromUnixTime(pin.end_date_timestamp),
                  "yyyy-MM-dd"
                )}`}</span>
              </div>
            )}
          </div>
          <div className="flex w-full">
            <span className="flex-0">{`${t("address")}：`}</span>
            <span className="inline-block">{`${pin.geo.address}`}</span>
          </div>
          <div className="w-full break-words">
            <span>{`${t("range")}：`}</span>
            {pin.geo.distance === 0 ? (
              <span>{t("option_no_limit")}</span>
            ) : (
              <span>{`${pin.geo.distance}m`}</span>
            )}
          </div>
        </div>
      </div>
    </Box>
  )
}
