import { createSvgIcon } from "@mui/material"
import React from "react"

export const PhotoLineIcon = createSvgIcon(
  <>
    <path d="M20.4583 3.53994H3.54169C3.33892 3.53883 3.13795 3.57796 2.95041 3.65504C2.76287 3.73213 2.59248 3.84564 2.4491 3.98902C2.30572 4.1324 2.19221 4.30279 2.11512 4.49033C2.03804 4.67787 1.99892 4.87884 2.00002 5.0816V18.9232C1.99962 19.1258 2.03915 19.3265 2.11637 19.5138C2.19358 19.7012 2.30696 19.8714 2.45002 20.0149C2.74179 20.3008 3.13321 20.4621 3.54169 20.4649H20.4583C20.8668 20.4621 21.2582 20.3008 21.55 20.0149C21.693 19.8714 21.8064 19.7012 21.8836 19.5138C21.9608 19.3265 22.0004 19.1258 22 18.9232V5.0816C22.0011 4.87884 21.962 4.67787 21.8849 4.49033C21.8078 4.30279 21.6943 4.1324 21.5509 3.98902C21.4075 3.84564 21.2371 3.73213 21.0496 3.65504C20.862 3.57796 20.6611 3.53883 20.4583 3.53994ZM20.4583 5.0816V14.9566L17.9583 12.4566C17.8114 12.3184 17.6424 12.2057 17.4583 12.1232C17.0792 11.9677 16.6541 11.9677 16.275 12.1232C16.0909 12.2057 15.9219 12.3184 15.775 12.4566L13.8583 14.3732L9.62501 10.1482C9.3361 9.86073 8.9451 9.69932 8.53751 9.69932C8.12992 9.69932 7.73891 9.86073 7.45001 10.1482L3.54169 14.0566V5.0816H20.4583ZM3.54169 16.2316L8.54167 11.2316L16.2333 18.9232H3.54169V16.2316ZM20.4583 18.9232H18.4083L14.9417 15.4649L16.8667 13.5399L20.4583 17.1316V18.9232Z" />
    <path d="M16.925 8.72336C16.925 9.053 16.8273 9.37523 16.6441 9.64931C16.461 9.92339 16.2007 10.137 15.8961 10.2632C15.5916 10.3893 15.2565 10.4223 14.9332 10.358C14.6099 10.2937 14.3129 10.135 14.0798 9.90187C13.8467 9.66879 13.688 9.37182 13.6237 9.04851C13.5594 8.72521 13.5924 8.3901 13.7185 8.08556C13.8447 7.78102 14.0583 7.52072 14.3324 7.33759C14.6065 7.15445 14.9287 7.0567 15.2583 7.0567C15.7004 7.0567 16.1243 7.2323 16.4369 7.54486C16.7494 7.85742 16.925 8.28134 16.925 8.72336Z" />
  </>,
  "PhotoLineIcon"
)

export const PhotoFillIcon = createSvgIcon(
  <path d="M22 18.3336C22 19.2502 21.2501 20.0003 20.3335 20.0003H3.66651C2.74988 20.0003 2 19.2504 2 18.3336V5.66651C2 4.74988 2.74988 4 3.66651 4H20.3335C21.2501 4 22 4.74988 22 5.66651V18.3336ZM3.66651 5.33329C3.48949 5.33329 3.33344 5.48993 3.33344 5.66636V18.3335C3.33344 18.5105 3.48963 18.6665 3.66651 18.6665H20.3335C20.5099 18.6665 20.667 18.5105 20.667 18.3335V5.66636C20.667 5.48993 20.5099 5.33329 20.3335 5.33329H3.66651ZM6.6669 10.6666C5.56269 10.6666 4.6663 9.77081 4.6663 8.6669C4.6663 7.56298 5.56269 6.66674 6.6669 6.66674C7.77067 6.66674 8.66618 7.56298 8.66618 8.6669C8.66618 9.77081 7.77081 10.6666 6.6669 10.6666ZM19.3331 17.3337H4.6663V15.3332L7.9999 12.0005L9.6664 13.6666L14.9997 8.33383L19.3331 12.6672V17.3337Z" />,
  "PhotoFill"
)

export const DiverCryptoIcon = createSvgIcon(
  <>
    <path
      d="M14.766 6.68609H4.55222L2 1.72498H12.2137L14.766 6.68609Z"
      fill="#1853A0"
    />
    <path
      d="M19.7305 7.04049L17.1309 12.3559L11.9291 1.72498H17.1309L19.7305 7.04049Z"
      fill="#1A68B0"
    />
    <path
      d="M22 12.005L19.7229 17.3205H14.9078L19.6352 6.68951L22 12.005Z"
      fill="#1E7DC0"
    />
    <path
      d="M19.7305 17.3171L17.1777 22.2782H6.96454L8.95035 17.3171H19.7305Z"
      fill="#2291D0"
    />
    <path
      d="M9.44559 17.0809H9.44839L6.9673 22.2782H2L9.44559 6.68612L11.9291 11.8835L9.44559 17.0809Z"
      fill="#2CA6E0"
    />
  </>,
  "DiverCrypto"
)

export const DownloadIcon = createSvgIcon(
  <path d="M12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34784 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM16.7227 13.5078L12.5391 17.4102C12.3906 17.5469 12.1992 17.625 12 17.625C11.8008 17.625 11.6055 17.5469 11.4609 17.4102L7.27734 13.5078C7.10156 13.3438 7 13.1133 7 12.8711C7 12.3906 7.39063 12 7.87109 12H10.125V8.25C10.125 7.55859 10.6836 7 11.375 7H12.625C13.3164 7 13.875 7.55859 13.875 8.25V12H16.1289C16.6094 12 17 12.3906 17 12.8711C17 13.1133 16.8984 13.3438 16.7227 13.5078Z" />,
  "Download"
)

export const SingleNFTIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M15 15H19L22 22H2L5 15H9M13 8C13 8.26522 12.8946 8.51957 12.7071 8.70711C12.5196 8.89464 12.2652 9 12 9C11.7348 9 11.4804 8.89464 11.2929 8.70711C11.1054 8.51957 11 8.26522 11 8C11 7.73478 11.1054 7.48043 11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7C12.2652 7 12.5196 7.10536 12.7071 7.29289C12.8946 7.48043 13 7.73478 13 8ZM6 8C6 13 12 18 12 18C12 18 18 13 18 8C18 4.583 15.314 2 12 2C8.686 2 6 4.583 6 8Z"
      stroke="white"
      strokeWidth="2"
    />
  </svg>,
  "SingleNFT"
)
