import React, { FC, PropsWithChildren, useContext, useState } from "react"
import LanguageIcon from "@mui/icons-material/Language"
import { useLocation, useNavigate } from "react-router-dom"
import {
  SvgIcon,
  TextField,
  Drawer,
  useTheme,
  Tooltip,
  ClickAwayListener,
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import MenuIcon from "@mui/icons-material/Menu"
import CloseIcon from "@mui/icons-material/Close"
import { useTranslation } from "react-i18next"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { langMenus, menus } from "@/core/constant/menu"
import { useAccount, useDisconnect } from "wagmi"
import { ConnectWalletPopup } from "@/components/common/blockchain/ConnectWalletPopup"
import AlertContainer from "@/core/store/AlertContainer"
import LinkOffOutlinedIcon from "@mui/icons-material/LinkOffOutlined"
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined"
import { SearchContext } from "@/core/store/SearchContext"
import ClearIcon from "@mui/icons-material/Clear"
import { AvatarBlock } from "./common/blockchain/AvatarBlock"
import { CommonButton } from "./common/CommonButton"

const Header: FC<PropsWithChildren> = () => {
  const location = useLocation()
  const { palette } = useTheme()
  const { debounce, setDebounce } = useContext(SearchContext)
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false)
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { address } = useAccount()
  const { disconnect } = useDisconnect()
  const { openDialog, closeDialog } = AlertContainer.useContainer()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [blockOpen, setBlockOpen] = useState(false)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const walletDisconnect = () => {
    setBlockOpen(false)
    disconnect()
  }
  const walletConnect = () => {
    openDialog({
      children: <ConnectWalletPopup handleClose={closeDialog} />,
      close: closeDialog,
      sx: {
        "& .MuiPaper-root": {
          backgroundColor: "transparent",
          boxShadow: "none",
          margin: "0",
        },
        "& .MuiBackdrop-root": { backgroundColor: "rgba(0,0,0,.7)" },
      },
    })
  }

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang).catch()
    handleClose()
  }

  return (
    <div className="sticky top-0 z-50 flex h-[86px] flex-wrap items-center justify-between bg-[#f3f3f3] px-4 py-4 drop-shadow-lg">
      <div className="flex items-center whitespace-nowrap">
        <div className="mr-8 cursor-pointer" onClick={() => navigate("/")}>
          <img
            className="h-[28px]"
            src="/images/chainpin-logo.svg"
            alt="chainpin-logo"
          />
        </div>
        <div className="hidden flex-0 items-center lg:flex">
          {menus.map(menu => (
            <div
              key={menu.id}
              className={`menu-item relative mr-8 cursor-pointer ${
                location.pathname === menu.path ? "menu-active" : ""
              }`}
              onClick={() => navigate(menu.path)}
            >
              {t(menu.label)}
            </div>
          ))}
          <div
            className="menu-item mr-8 cursor-pointer"
            onClick={() => window.open("https://lin.ee/3iPsqHs", "_blank")}
          >
            {t("contact")}
          </div>
          <TextField
            type="search"
            value={debounce}
            onChange={event => {
              setDebounce(event.currentTarget.value)
            }}
            sx={{
              m: 1,
              width: "30ch",
              "& .MuiInputBase-root": {
                height: "38px",
                backgroundColor: "white",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                "& > fieldset": {
                  borderColor: "black",
                },
              },
              "input[type='search']::-webkit-search-cancel-button": {
                display: "none",
              },
            }}
            InputProps={{
              startAdornment: (
                <SearchIcon
                  sx={{
                    color: "#00000057",
                  }}
                />
              ),
              endAdornment: debounce && (
                <ClearIcon
                  onClick={() => setDebounce("")}
                  sx={{
                    width: "18x",
                    height: "18px",
                    cursor: "pointer",
                    "&:hover": {
                      color: "gray",
                    },
                  }}
                />
              ),
            }}
            placeholder={t("search_pin_items")}
          />
        </div>
      </div>
      <div className="hidden items-center lg:flex">
        {address ? (
          <ClickAwayListener onClickAway={() => setBlockOpen(false)}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
                sx: {
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: "white",
                    color: "#333333",
                    width: "300px",
                    padding: "0",
                    margin: "0",
                    borderRadius: "10px",
                  },
                },
              }}
              onClose={() => setBlockOpen(false)}
              onClick={() => setBlockOpen(!blockOpen)}
              open={blockOpen}
              placement="bottom"
              title={
                <>
                  <div className="flex items-center rounded-tl-[10px] rounded-tr-[10px] bg-black px-6 py-[14px]">
                    <AvatarBlock
                      size={6}
                      seed={address || ""}
                      className="mr-2 rounded-full"
                    />
                    <div className="text-[14px] font-medium leading-[14px] text-white">
                      DIVER Chain
                    </div>
                  </div>
                  <div className="px-8 py-4">
                    <CommonButton
                      variant="outlined"
                      onClick={walletDisconnect}
                      sx={{
                        width: "100%",
                        borderRadius: 2,
                        padding: 1.5,
                        color: "#000",
                        borderColor: "#000",
                        "&:hover": {
                          borderColor: "#000",
                          backgroundColor: "#00000009",
                        },
                      }}
                    >
                      <LinkOffOutlinedIcon
                        sx={{
                          fontSize: 20,
                          marginRight: 1.5,
                        }}
                      />
                      {t("disconnect_wallet")}
                    </CommonButton>
                  </div>
                </>
              }
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <div className="mr-2 flex cursor-pointer">
                <div className="flex-shrink-0">
                  <AvatarBlock
                    seed={address || ""}
                    className="mr-2 rounded-full"
                  />
                </div>
                <div className="flex-grow text-sm font-medium">
                  {address.slice(0, 7)}...{address.slice(-6)}
                  <div className="text-[#949494]">DIVER Chain</div>
                </div>
              </div>
            </Tooltip>
          </ClickAwayListener>
        ) : (
          <div
            className="inline-flex cursor-pointer items-center rounded-lg border bg-black px-4 py-2 font-semibold text-white transition-colors hover:bg-[#000000d9]"
            onClick={walletConnect}
          >
            <AccountBalanceWalletOutlinedIcon />
            <span className="ml-1">{t("connect_wallet")}</span>
          </div>
        )}
        <div className="ml-6 mr-4 h-[28px] w-[2px] bg-black" />
        <div className="inline-block cursor-pointer" onClick={handleClick}>
          <LanguageIcon />
        </div>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {langMenus.map(lang => (
            <MenuItem
              key={lang.translation}
              onClick={() => changeLanguage(lang.translation)}
              sx={{
                "&.MuiMenuItem-root": {
                  backgroundColor:
                    i18n.language === lang.translation
                      ? palette.grey["800"]
                      : "transparent",
                  color:
                    i18n.language === lang.translation
                      ? palette.grey["200"]
                      : "black",
                  "&:hover": {
                    backgroundColor:
                      i18n.language === lang.translation
                        ? palette.grey["800"]
                        : palette.grey["200"],
                  },
                },
              }}
            >
              {t(lang.name)}
            </MenuItem>
          ))}
        </Menu>
      </div>

      <div className="inline-flex h-full items-center lg:hidden">
        <MenuIcon onClick={() => setIsOpenMenu(true)} />
      </div>
      <Drawer
        anchor="right"
        open={isOpenMenu}
        onClose={() => setIsOpenMenu(false)}
      >
        <div className="h-full w-screen">
          <div className="px-5 py-4 text-right">
            <CloseIcon onClick={() => setIsOpenMenu(false)} />
          </div>

          <div className="bg-[#f3f3f3] px-5 py-8">
            <form
              className="pb-4"
              onSubmit={event => {
                event.stopPropagation()
                event.preventDefault()
                location.pathname !== "/account" && navigate("/account")
                setIsOpenMenu(false)
              }}
            >
              <TextField
                type="text"
                value={debounce}
                onChange={event => {
                  setDebounce(event.currentTarget.value)
                }}
                sx={{
                  my: 1,
                  width: "100%",
                  "& .MuiInputBase-root": {
                    height: "50px",
                    backgroundColor: "white",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#000",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <SearchIcon
                      sx={{
                        color: "#00000057",
                      }}
                    />
                  ),
                }}
                placeholder={t("search_pin_items")}
              />
              <button className="hidden" type="submit" />
            </form>
            <div className="grid grid-cols-2 gap-0 border border-r-0 border-gray-300">
              {langMenus.map(lang => (
                <div
                  className={`w-full border-r border-gray-300 py-3.5 text-center text-lg font-medium ${
                    i18n.language === lang.translation
                      ? "bg-black/40 text-white"
                      : ""
                  }`}
                  key={lang.name}
                  onClick={() => changeLanguage(lang.translation)}
                >
                  {t(lang.name)}
                </div>
              ))}
            </div>
          </div>
          {menus.map(menu => (
            <div
              className={`border-b border-gray-300 p-5 text-xl ${
                location.pathname === menu.path ? "bg-black text-white" : ""
              }`}
              key={menu.id}
              onClick={() => {
                navigate(menu.path)
                setIsOpenMenu(false)
              }}
            >
              {t(menu.label)}
            </div>
          ))}
          <div
            className="border-b border-gray-300 p-5 text-xl"
            onClick={() => window.open("https://lin.ee/3iPsqHs", "_blank")}
          >
            {t("contact")}
          </div>
          <div className="pt-12 text-center">
            <div
              className="mx-auto w-1/2 whitespace-nowrap rounded-lg bg-black py-2 text-lg text-white"
              onClick={address ? walletDisconnect : walletConnect}
            >
              <SvgIcon
                component={
                  address
                    ? LinkOffOutlinedIcon
                    : AccountBalanceWalletOutlinedIcon
                }
                fontSize="small"
              />
              <span className="pl-2">
                {t(address ? "disconnect_wallet" : "connect_wallet")}
              </span>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default Header
