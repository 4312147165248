import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { CommonButton } from "@/components/common/CommonButton"
import AddIcon from "@mui/icons-material/Add"

type Props = {
  active?: string
}

export default function NoDataComponent({ active }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = () => {
    active === "pin"
      ? navigate("/pin-generate")
      : window.open(import.meta.env.VITE_TRACOU_URL, "_blank")
  }

  return (
    <div className="flex flex-col items-center justify-center pb-12 pt-20">
      <img src="/images/no-collection.png" alt="nodata" className="mb-4" />
      <p className="mb-16 text-lg font-bold text-[#c4c4c4]">
        {t("str_no_data")}
      </p>
      <CommonButton
        variant="contained"
        color="info"
        onClick={handleClick}
        sx={{
          bgcolor: "#000",
          borderRadius: 2,
          paddingY: "15px",
          width: "340px",
          "&:hover": {
            bgcolor: "#000000d9",
          },
        }}
      >
        <AddIcon className="mr-2" />
        {t("str_lets_create")}
      </CommonButton>
    </div>
  )
}
