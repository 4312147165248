import React from "react"
import { NFTItemDto, PinNFTDto } from "@/core/api"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"
import { CommonButton } from "@/components/common/CommonButton"
import { PhotoLineIcon } from "@/components/common/CommonIcon"
import QrCodeIcon from "@mui/icons-material/QrCode"
import { getBalanceOf } from "@/core/api/balanceOf"
import { useAsync } from "react-use"
import useDetailActions from "@/core/hook/useDetailActions"

export type CollectionNFTWrapperProps = {
  nft: NFTItemDto
  pin: PinNFTDto
}

export const NFTCard: React.FunctionComponent<CollectionNFTWrapperProps> = ({
  nft,
  pin,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { showQrCode } = useDetailActions()

  const rawBalanceOf = useAsync(async () => {
    const res = await getBalanceOf(nft.owner, nft.address, nft.tokenID)
    return res
  }, [nft])

  return (
    <div
      className="flex w-full cursor-pointer flex-col overflow-hidden rounded-2xl border-2 border-[#D1D1D1] transition-all hover:border-black"
      onClick={() => navigate(`/nft/${nft.address}/${nft.tokenID}`)}
    >
      <div className="relative w-full px-3 pt-3">
        <img
          src={nft.image}
          className="pointer-events-none aspect-square w-full rounded-t-lg object-cover"
          alt={nft.name}
        />
        <Box
          className="absolute left-5 top-5 inline-flex items-center rounded px-[10px] py-1 text-sm text-white"
          sx={{ backgroundColor: "rgba(0,0,0,0.4)" }}
        >
          <PhotoLineIcon fontSize="small" sx={{ marginRight: 0.5 }} />
          {t("image")}
        </Box>
        {pin && (
          <Box
            className="absolute right-5 top-5 rounded border border-[#F3F3F3] bg-white p-1 transition-colors hover:border-black"
            onClick={event => showQrCode(pin, event)}
          >
            <QrCodeIcon sx={{ fontSize: 32 }} />
          </Box>
        )}
        <Box
          className="absolute bottom-3 right-5 rounded px-[10px] py-1 text-sm text-white"
          sx={{ backgroundColor: "rgba(0,0,0,0.4)" }}
        >
          {t("remaining", {
            total: nft.supply,
            used: !rawBalanceOf.loading
              ? Number(rawBalanceOf.value?.data.total)
              : "",
          })}
        </Box>
      </div>
      <div className="flex flex-1 flex-col p-3 pt-2">
        <Box
          className="mb-4 flex-1 overflow-hidden text-lg font-bold"
          sx={{ maxHeight: 96, wordBreak: "break-word" }}
        >
          <div className="line-clamp-2 h-full w-full">{nft.name}</div>
        </Box>
        <CommonButton
          variant="outlined"
          sx={{
            color: "#000",
            borderColor: "#000",
            borderRadius: 2,
            padding: "6px 12px",
            textWrap: "nowrap",
            width: "100%",
            "&:hover": {
              borderColor: "#000",
              backgroundColor: "#00000009",
            },
          }}
        >
          {t("view_detail")}
        </CommonButton>
      </div>
    </div>
  )
}
