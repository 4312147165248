const directByDeepLink = () => {
  const a = document.createElement("a")
  a.setAttribute(
    "href",
    `${import.meta.env.VITE_DEEP_LINK_URL}${location.pathname}`
  )
  a.click()
}

export const goToDiverWalletApp = () => {
  const userAgent = navigator.userAgent
  if (userAgent.includes("Line")) {
    directByDeepLink()
  } else {
    fetch(
      "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBdkG-IOnwHm5ORjyaod8bjy4n7zgin660",
      {
        method: "POST",
        body: JSON.stringify({
          dynamicLinkInfo: {
            domainUriPrefix: "https://diverwallet.page.link",
            link: `${import.meta.env.VITE_DEEP_LINK_URL}${location.pathname}`,
            androidInfo: {
              androidPackageName: "com.hyerdesign.diver.wallet",
            },
            iosInfo: {
              iosBundleId: "com.bigant.diver.limited.wallet",
              iosAppStoreId: "6444421722",
            },
          },
        }),
      }
    )
      .then(res => res.json())
      .then(res => {
        const a = document.createElement("a")
        a.setAttribute("href", res.shortLink)
        a.click()
      })
      .catch(err => {
        console.error(err)
        directByDeepLink()
      })
  }
}
