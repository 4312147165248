import React, { PropsWithChildren } from "react"

export type Props = PropsWithChildren

export const PageHeader: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <div className="mb-12 bg-gray-50 py-12 text-center md:mb-16 md:py-20">
      <h1 className="text-[24px] font-black uppercase leading-tight md:text-[32px]">
        {children}
      </h1>
    </div>
  )
}
