import { CommonButton } from "@/components/common/CommonButton"
import { chainLogoMapper } from "@/core/constant/chain"
import React from "react"
import { useTranslation } from "react-i18next"
import { useSwitchNetwork } from "wagmi"
import { Chain } from "@wagmi/core"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

export type SwitchChainPopupProps = { handleClose: () => void; chain: Chain }

export const SwitchChainPopup: React.FunctionComponent<
  SwitchChainPopupProps
> = ({ handleClose, chain }) => {
  const { t } = useTranslation()
  const allowedChainId = Number(import.meta.env.VITE_CHAIN_ID)
  const { switchNetwork } = useSwitchNetwork()

  return (
    <div className="flex h-[300px] w-[20vw] min-w-[300px] flex-col justify-center px-8 py-5">
      {switchNetwork && (
        <>
          <div className="text-primary-dark pt-4 text-center text-[18px] font-bold">
            {t("please_switch_diver")}
          </div>
          <div className="flex flex-1 items-center justify-between">
            <img
              className="mr-4 aspect-square h-auto w-fit min-w-[50px] flex-0"
              src={chainLogoMapper[chain.id]}
              alt={chain.name}
            />
            <div className="relative inline-flex flex-1 items-center">
              <div className="border-primary-main mr-7 flex-1 border-t-4 border-dashed" />
              <ChevronRightIcon
                className="text-primary-main absolute right-0 flex-0"
                fontSize="large"
              />
            </div>
            <img
              className="aspect-square h-auto w-fit min-w-[50px] flex-0"
              src={chainLogoMapper[allowedChainId]}
              alt={chain.name}
            />
          </div>
          <div className="pb-4 text-center">
            <CommonButton
              variant="contained"
              color="primary"
              onClick={() => {
                switchNetwork(allowedChainId)
                handleClose()
              }}
              sx={{
                fontSize: 14,
                minWidth: 140,
                padding: "10px 20px",
                lineHeight: 1.5,
              }}
            >
              {t("switch_network")}
            </CommonButton>
          </div>
        </>
      )}
    </div>
  )
}
