import React, { useEffect } from "react"
import { CommonButton } from "@/components/common/CommonButton"
import { useConnect } from "wagmi"
import { useTranslation } from "react-i18next"
import { Box, useTheme } from "@mui/material"
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined"
import { useDeviceDetect } from "@/core/hook/useDeviceDetect"
import AlertContainer, { MessageState } from "@/core/store/AlertContainer"
import { isAndroid, isIOS, isMobile, isTablet } from "react-device-detect"
import { goToDiverWalletApp } from "@/core/utils/goToDiverWalletApp"

export type ConnectWalletPopupProps = { handleClose: () => void }

export const DiverWalletConnector: React.FunctionComponent<
  ConnectWalletPopupProps
> = ({ handleClose }) => {
  const { pushAlert } = AlertContainer.useContainer()
  const { t } = useTranslation()
  const { connect, connectors } = useConnect({
    onError: (err: MessageState) =>
      pushAlert({ code: err.code, message: err.message }),
  })
  const { breakpoints } = useTheme()
  const { isDiverApp } = useDeviceDetect()

  const connectWallet = () => {
    const connector = isDiverApp
      ? connectors[0]
      : connectors.find(item => item.name === "DiverWallet")
    connector && connect({ connector })
    handleClose()
  }

  const goToInstallDiverWallet = () => {
    if (isIOS) {
      location.href = import.meta.env.VITE_DIVER_WALLET_IOS
    } else if (isAndroid) {
      location.href = import.meta.env.VITE_DIVER_WALLET_ANDROID
    } else {
      window.open(import.meta.env.VITE_DIVER_WALLET_URL, "_blank")
    }
  }

  const isInstalledWallet = () => {
    return window.ethereum?.isDiverWallet || isDiverApp
  }

  useEffect(() => {
    if (isDiverApp) {
      const connector = connectors.find(item => item.name === "DiverWallet")
      connector && connect({ connector })
    } else {
      if (isMobile) {
        goToDiverWalletApp()
      }
    }
  }, [])

  return (
    <Box
      className="flex flex-col items-center justify-center rounded-[20px] bg-white px-16 py-20 sm:p-[60px]"
      sx={{
        width: "95%",
        margin: "auto",
        [breakpoints.up("sm")]: { width: 540 },
      }}
    >
      <img
        className="mb-7 w-5/12 object-contain sm:mb-9 sm:w-1/3"
        src={
          isInstalledWallet()
            ? "/images/diverWallet.svg"
            : "/images/diverWallet_desaturated.svg"
        }
        alt="diver_wallet"
      />
      <div className="mb-8 w-full text-center">
        <CommonButton
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            height: 56,
            fontSize: 14,
            lineHeight: 1,
            fontWeight: 700,
            paddingX: 12,
            alignItems: "center",
            [breakpoints.up("sm")]: {
              fontSize: 18,
              height: 70,
              paddingX: 0,
              boxShadow: "0px 5px 5px 0px rgba(0, 26, 59, 0.2)",
            },
          }}
          onClick={connectWallet}
        >
          <AccountBalanceWalletOutlinedIcon
            sx={{
              fontSize: 20,
              marginRight: 1.5,
              [breakpoints.up("sm")]: {
                fontSize: 28,
                marginRight: "10px",
              },
            }}
          />
          <span className="inline-block whitespace-nowrap">
            {t("wallet_connecting")}
          </span>
        </CommonButton>
        <div className="mt-1.5 text-sm font-medium text-[#666666]">
          {t("wallet_connecting_text")}
        </div>
      </div>
      <div className="w-full text-center">
        <CommonButton
          className="w-full"
          variant="outlined"
          sx={{
            width: "100%",
            height: 56,
            fontSize: 14,
            lineHeight: 1,
            fontWeight: 700,
            alignItems: "center",
            paddingX: 12,
            backgroundColor: "#fff",
            [breakpoints.up("sm")]: {
              fontSize: 18,
              height: 70,
              paddingX: 0,
              boxShadow: "0px 5px 5px 0px rgba(0, 26, 59, 0.2)",
            },
          }}
          onClick={goToInstallDiverWallet}
        >
          <img
            src="/images/ic.svg"
            alt="ic"
            className="mr-[10px] w-[14px] sm:w-5"
          />
          <span className="inline-block whitespace-nowrap">
            {isMobile || isTablet
              ? t("wallet_connecting_download_rwd")
              : t("wallet_connecting_download_web")}
          </span>
        </CommonButton>
        <div className="mt-1.5 text-sm font-medium text-[#666666]">
          {t("wallet_connecting_download_text")}
        </div>
      </div>
    </Box>
  )
}
